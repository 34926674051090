import _ from 'lodash';

export function competitions (state) {
    return state.competitions
}

export function userCompetitions (state) {
    return state.userCompetitions
}

export function entities (state) {
    return state.entities
}

export function selectedCompetitions (state) {
    return state.selectedCompetitions
}

export function groupedCompetitions (state) {
    let grouped = _.groupBy(state.competitions, 'concorso');
    return _.mapValues(grouped, x => _.sortBy(x, ['anno'], ['desc']))
}

export function getSelectedCompetition (state) {
    return _.cloneDeep(_.find(state.selectedCompetitions, function (c, index) {
        c.indice = index
        return c.selected
    }))
}