import {_enrichPaths} from "./_helpers";
import _ from "lodash";

export function SET_PATHS (state, payload) {
    state.paths = payload
    _enrichPaths(state);
}

export function SET_USER_PATHS (state, payload) {
    state.userPaths = payload
}

export function UNSET_PATHS (state) {
    state.paths = []
    state.pathsByIdArgomento = {}
}

export function UNSET_USER_PATHS (state) {
    state.userPaths = []
}

export function SET_SELECTED_PATHS (state, payload) {
    state.selectedPaths = Array.isArray(payload)
        // Rimuoviamo completamente i sotto_percorsi da state.selectedPaths, verranno inseriti dal getter a partire da state.paths
        ? payload.map(x => _.omit(x, "sotto_percorsi"))
        : payload
}

export function UNSET_SELECTED_PATHS (state) {
    state.selectedPaths = []
}
