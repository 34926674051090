import qs from 'qs';
import * as ConcorsandoApi from "../../ConcorsandoApi";
import _ from 'lodash';
import {Errors} from "../../ConcorsandoWasm/Messages";
import ApiClient from "../../ConcorsandoApi/ApiClient";

export function getToken() {
    const defaultClient = ConcorsandoApi.ApiClient.instance;
    return _.get(defaultClient, "authentications.Baerer.apiKey");
}

export default class CustomClient {
    defaultHeaders = {}
    basePath = ""
    /**
     * Singleton getter
     * @returns {CustomClient}
     */
    static get instance() {
        if (!this.thisInstance) {
            this.thisInstance = new CustomClient();
        }
        return this.thisInstance;
    }

    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    _buildSegnalazioneUrl({id_user, id_domanda, rcode} = {}) {
        const token = getToken();

        const query = qs.stringify({
            method: "segnalaquiz",
            id_user,
            id_domanda,
            rcode,
            token,
            apikey: "4PSnD11lZ4LblUIToPlhNw"
        })

        return this.basePath + "/?" + query;
    }

    /**
     * @typedef SegnalazioneOptions
     * @property {string | number} id_user
     * @property {string | number} id_domanda
     * @property {number} rcode - da 0 a 6
     */

    /**
     *
     * @param {SegnalazioneOptions} options
     * @returns {Promise<Response>}
     */
    async sendSegnalazione(options = {}) {
        if (!Object.prototype.hasOwnProperty.call(options, "id_user")
            || !Object.prototype.hasOwnProperty.call(options, "id_domanda")
            || !Object.prototype.hasOwnProperty.call(options, "rcode")) {
            throw {code: Errors.MISSING_REQUIRED_PARAM}
        }
        const req_url = this._buildSegnalazioneUrl(options);

        const res = await fetch(
            req_url,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    ...this.defaultHeaders
                }
            }
        )
        if (!res.ok) {
            throw {code: Errors.REMOTE_REQUEST_FAILED}
        }
        return res;
    }

    /**
     * Store api
     * @returns {string}
     * @private
     */

    _buildStoreUrl() {
        return this.basePath + '/ms_concorso/concorso';
    }

    getStore(opts, callback) {
        opts = opts || {};
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ['Baerer'];
        let contentTypes = [];
        let accepts = [];
        let returnType = [Object];

        return this.apiClient.callApi(
            '/ms_concorso/store', 'POST',
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, callback
        );
    }

    /**
     * Banner api
     * @returns {string}
     * @private
     */

    _buildBannerUrl() {
        const token = getToken();

        const query = qs.stringify({
            method: "getBanners",
            token,
            apikey: "4PSnD11lZ4LblUIToPlhNw"
        })

        return this.basePath + "/?" + query;
    }

    async getBanners() {
        const req_url = this._buildBannerUrl();

        const res = await fetch(
            req_url,
            {
                mode: 'cors',
                method: 'GET',
                headers: {
                    ...this.defaultHeaders
                }
            }
        )
        if (!res.ok) {
            throw {code: Errors.REMOTE_REQUEST_FAILED}
        }

        const reader = res.body.getReader();

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        let chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;
        }

        // Step 4: concatenate chunks into single Uint8Array
        let chunksAll = new Uint8Array(receivedLength); // (4.1)
        let position = 0;
        for(let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
        }

        const newRespose = new Response(chunksAll)

        return newRespose.json();
    }
}
