const MobileStatus = {
    isMobileAgiPhone: {
        type: "isMobileAgiPhone",
        msg: "Vai sullo Store del tuo smartphone! \n Per il tuo dispositvo esiste un'App dedicata che puoi scaricare dall'App Store di Apple",
        redirect_url: "https://itunes.apple.com/it/app/concorsando.it/id543489205",
    },
    isMobileAgiHMS: {
        type: "isMobileAgiHMS",
        msg: "Vai sullo Store del tuo smartphone! \n Per il tuo dispositvo esiste un'App dedicata che puoi scaricare da AppGallery di Huawei",
        redirect_url: "https://appgallery.huawei.com/#/app/C101496801?sharePrepath=ag&locale=it_IT&source=appshare&subsource=C10149680",
    },
    isMobileAgiAndroid: {
        type: "isMobileAgiAndroid",
        msg: "Vai sullo Store del tuo smartphone! \n Per il tuo dispositvo esiste un'App dedicata che puoi scaricare dal Google Play store",
        redirect_url: "https://play.google.com/store/apps/details?id=com.app.concorsando&hl=it",
    }
}

/**
 *
 * @returns {{msg: string, type: string, redirect_url: string}}
 */
export function mobileCheck() {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
        return MobileStatus.isMobileAgiPhone;
    }

    if (navigator.userAgent.match(/(HMS)/)) {
        return MobileStatus.isMobileAgiHMS;
    }

    if (navigator.userAgent.match(/(Android)/)) {
        return MobileStatus.isMobileAgiAndroid;
    }
}
