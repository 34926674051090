/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ModalitaRisposta from './ModalitaRisposta';

/**
* The RispostaVO model module.
* @module model/RispostaVO
* @version 2.0.0
*/
export default class RispostaVO {
    /**
    * Constructs a new <code>RispostaVO</code>.
    * @alias module:model/RispostaVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>RispostaVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RispostaVO} obj Optional instance to populate.
    * @return {module:model/RispostaVO} The populated <code>RispostaVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RispostaVO();
                        
            
            if (data.hasOwnProperty('id_concorso')) {
                obj['id_concorso'] = ApiClient.convertToType(data['id_concorso'], 'String');
            }
            if (data.hasOwnProperty('id_domanda')) {
                obj['id_domanda'] = ApiClient.convertToType(data['id_domanda'], 'String');
            }
            if (data.hasOwnProperty('id_materia')) {
                obj['id_materia'] = ApiClient.convertToType(data['id_materia'], 'String');
            }
            if (data.hasOwnProperty('id_argomento')) {
                obj['id_argomento'] = ApiClient.convertToType(data['id_argomento'], 'String');
            }
            if (data.hasOwnProperty('id_sotto_percorso')) {
                obj['id_sotto_percorso'] = ApiClient.convertToType(data['id_sotto_percorso'], 'String');
            }
            if (data.hasOwnProperty('id_simulazione')) {
                obj['id_simulazione'] = ApiClient.convertToType(data['id_simulazione'], 'String');
            }
            if (data.hasOwnProperty('preferiti')) {
                obj['preferiti'] = ApiClient.convertToType(data['preferiti'], 'Boolean');
            }
            if (data.hasOwnProperty('livello_conoscenza')) {
                obj['livello_conoscenza'] = ApiClient.convertToType(data['livello_conoscenza'], 'Number');
            }
            if (data.hasOwnProperty('risposta_gettonata')) {
                obj['risposta_gettonata'] = ApiClient.convertToType(data['risposta_gettonata'], 'Number');
            }
            if (data.hasOwnProperty('risposta')) {
                obj['risposta'] = ApiClient.convertToType(data['risposta'], 'Number');
            }
            if (data.hasOwnProperty('modalita')) {
                obj['modalita'] = ModalitaRisposta.constructFromObject(data['modalita']);
            }
            if (data.hasOwnProperty('data_letta')) {
                obj['data_letta'] = ApiClient.convertToType(data['data_letta'], 'Date');
            }
            if (data.hasOwnProperty('data_ascoltata')) {
                obj['data_ascoltata'] = ApiClient.convertToType(data['data_ascoltata'], 'Date');
            }
            if (data.hasOwnProperty('data_eseguita')) {
                obj['data_eseguita'] = ApiClient.convertToType(data['data_eseguita'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {String} id_concorso
    */
    'id_concorso' = undefined;
    /**
    * @member {String} id_domanda
    */
    'id_domanda' = undefined;
    /**
    * @member {String} id_materia
    */
    'id_materia' = undefined;
    /**
    * @member {String} id_argomento
    */
    'id_argomento' = undefined;
    /**
    * @member {String} id_sotto_percorso
    */
    'id_sotto_percorso' = undefined;
    /**
    * @member {String} id_simulazione
    */
    'id_simulazione' = undefined;
    /**
    * @member {Boolean} preferiti
    */
    'preferiti' = undefined;
    /**
    * @member {Number} livello_conoscenza
    */
    'livello_conoscenza' = undefined;
    /**
    * @member {Number} risposta_gettonata
    */
    'risposta_gettonata' = undefined;
    /**
    * @member {Number} risposta
    */
    'risposta' = undefined;
    /**
    * @member {module:model/ModalitaRisposta} modalita
    */
    'modalita' = undefined;
    /**
    * @member {Date} data_letta
    */
    'data_letta' = undefined;
    /**
    * @member {Date} data_ascoltata
    */
    'data_ascoltata' = undefined;
    /**
    * @member {Date} data_eseguita
    */
    'data_eseguita' = undefined;




}
