import Vue from 'vue'
import createPersistedState from "vuex-persistedstate"
import Vuex from 'vuex'

import auth from './auth'
import profile from './profile'
import competition from './competition'
import path from './path'
import utility from './utility'
import _ from 'lodash';
import {_enrichPaths} from "./path/_helpers";

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */
export default new Vuex.Store({
    modules: {
        auth,
        profile,
        competition,
        path,
        utility
    },

    // enable strict mode (adds overhead!)
    // for dev.env mode only
    strict: process.env.DEV,
    plugins: [createPersistedState({
        paths: [
            'auth',
            'competition.selectedCompetitions',
            'utility.uptime',
            'utility.autoNext',
            'competition.competitions',
            'competition.entities',
            'path.paths',
            'path.selectedPaths'
        ],
        getState: (key, storage) => {
            const value = storage.getItem(key);

            try {
                const state = typeof value === "string" ? JSON.parse(value) : typeof value === "object" ? value : undefined;
                if (state !== undefined && typeof state === "object" && "path" in state) {
                    state.path = _enrichPaths(state.path);
                    return state;
                } else {
                    return state;
                }
            } catch(e) {}

            return undefined
        }
    })]

})
