import _ from 'lodash';

export function authentications (state) {
  return state.authentications
}

export function isAuthenticated (state) {
  return state.isAuthenticated
}

export function profile (state) {
  return state.profile
}

export function token_api (state) {
  return _.get(state, "authentications.token_api", null);
}

export function isEmailVerified (state) {
  return _.get(state, "profile.email_verificata", false)
}

export function emailComunicazioni (state) {
  return _.get(state, "profile.email_comunicazioni", null)
}
