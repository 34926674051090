export const Errors = Object.freeze({
  INDEXEDDB_NOT_AVAILABLE: "INDEXEDDB_NOT_AVAILABLE",
  INDEXEDDB_NOT_ENOUGH_SPACE: "INDEXEDDB_NOT_ENOUGH_SPACE",
  INDEXEDDB_GENERIC_ERROR: "INDEXEDDB_GENERIC_ERROR",
  WEBASSEMBLY_NOT_AVAILABLE: "WEBASSEMBLY_NOT_AVAILABLE",
  CONCORSO_NON_TROVATO: "CONCORSO_NON_TROVATO",
  ID_CONCORSO_NON_COINCIDE: "ID_CONCORSO_NON_COINCIDE",
  MISSING_REQUIRED_PARAM: "MISSING_REQUIRED_PARAM",
  REMOTE_REQUEST_FAILED: "REMOTE_REQUEST_FAILED",
  INDEX_OUT_OF_BOUND: "INDEX_OUT_OF_BOUND",
  DOMANDA_NON_TROVATA: "DOMANDA_NON_TROVATA",
  IMPOSSIBILE_SELEZIONARE_RISPOSTA: "IMPOSSIBILE_SELEZIONARE_RISPOSTA",
  ESERCITAZIONE_NON_INIZIALIZZATA: "ESERCITAZIONE_NON_INIZIALIZZATA",
  RATE_LIMIT_EXCEEDED: "RATE_LIMIT_EXCEEDED",
  TIPO_ESERCITAZIONE_ERRATO: "TIPO_ESERCITAZIONE_ERRATO", // stai provando a fare un'operazione per un tipo di esercitazione sbagliato, esempio concludiProva su un ESERCITAZIONE
});

export const Warnings = Object.freeze({
  INDEXEDDB_LOW_SPACE: "INDEXEDDB_LOW_SPACE",
})
