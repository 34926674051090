import {getCookie, setCookie} from "./utils";

const AFFILIATE_COOKIE_NAME="affopqcode";
const AFFILIATE_EXPIRY_DAYS=30;
export const AFFILIATE_CODE_PARAM_NAME="code";

export default class AffiliateManager {
    static get instance() {
        if (!this.affiliateManagerInstance) {
            this.affiliateManagerInstance = new AffiliateManager();
        }
        return this.affiliateManagerInstance;
    }

    saveAffiliateString(s) {
        setCookie(AFFILIATE_COOKIE_NAME, s, AFFILIATE_EXPIRY_DAYS);
    }

    getAffiliateString() {
        return getCookie(AFFILIATE_COOKIE_NAME);
    }

    clearAffiliateString() {
        setCookie(AFFILIATE_COOKIE_NAME, "", -1);
    }
}
