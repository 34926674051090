export function SET_AUTHENTICATIONS (state, payload) {
  state.authentications = payload
}

export function UNSET_AUTHENTICATIONS (state) {
  state.authentications = {}
}

export function SET_IS_AUTHENTICATED (state, payload) {
  state.isAuthenticated = payload
}

export function UNSET_IS_AUTHENTICATED (state) {
  state.isAuthenticated = false
}

export function SET_PROFILE (state, payload) {
  state.profile = payload
}

export function UNSET_PROFILE (state) {
  state.profile = {}
}
