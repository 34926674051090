/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import EnteVO from './EnteVO';

/**
* The ConcorsoVO model module.
* @module model/ConcorsoVO
* @version 2.0.0
*/
export default class ConcorsoVO {
    /**
    * Constructs a new <code>ConcorsoVO</code>.
    * @alias module:model/ConcorsoVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ConcorsoVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ConcorsoVO} obj Optional instance to populate.
    * @return {module:model/ConcorsoVO} The populated <code>ConcorsoVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConcorsoVO();
                        
            
            if (data.hasOwnProperty('id_concorso')) {
                obj['id_concorso'] = ApiClient.convertToType(data['id_concorso'], 'String');
            }
            if (data.hasOwnProperty('concorso')) {
                obj['concorso'] = ApiClient.convertToType(data['concorso'], 'String');
            }
            if (data.hasOwnProperty('ente')) {
                obj['ente'] = EnteVO.constructFromObject(data['ente']);
            }
            if (data.hasOwnProperty('anno')) {
                obj['anno'] = ApiClient.convertToType(data['anno'], 'String');
            }
            if (data.hasOwnProperty('data_ultimo_aggiornamento')) {
                obj['data_ultimo_aggiornamento'] = ApiClient.convertToType(data['data_ultimo_aggiornamento'], 'Date');
            }
            if (data.hasOwnProperty('stato')) {
                obj['stato'] = ApiClient.convertToType(data['stato'], 'Number');
            }
            if (data.hasOwnProperty('url_domande')) {
                obj['url_domande'] = ApiClient.convertToType(data['url_domande'], 'String');
            }
            if (data.hasOwnProperty('url_immagini')) {
                obj['url_immagini'] = ApiClient.convertToType(data['url_immagini'], 'String');
            }
            if (data.hasOwnProperty('dimensione_zip')) {
                obj['dimensione_zip'] = ApiClient.convertToType(data['dimensione_zip'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} id_concorso
    */
    'id_concorso' = undefined;
    /**
    * @member {String} concorso
    */
    'concorso' = undefined;
    /**
    * @member {module:model/EnteVO} ente
    */
    'ente' = undefined;
    /**
    * @member {String} anno
    */
    'anno' = undefined;
    /**
    * @member {Date} data_ultimo_aggiornamento
    */
    'data_ultimo_aggiornamento' = undefined;
    /**
    * @member {Number} stato
    */
    'stato' = undefined;
    /**
    * @member {String} url_domande
    */
    'url_domande' = undefined;
    /**
    * @member {String} url_immagini
    */
    'url_immagini' = undefined;
    /**
    * @member {Number} dimensione_zip
    */
    'dimensione_zip' = undefined;




}
