export default function () {
  return {
    profile: {},
    authentications: {
      token_api: '',
      social: '',
      tokenSocial: ''
    },
    isAuthenticated: false
  }
}
