import * as ConcorsandoApi from "../../lib/ConcorsandoApi";
import { AUTH_ACTIONS_DISPATCH } from "../auth/actions-enum";
import {UTILITY_MUTATIONS} from "./mutations-enum";
import {COMPETITION_GETTERS, COMPETITION_GETTERS_GLOBAL} from "@/store/competition/getters-enum";
import {PATH_GETTERS_GLOBAL} from "@/store/path/getters-enum";
import WrappedWorker from "@/lib/ConcorsandoWasm/WrappedWorker";
import PercorsiWrappedWorker from "@/lib/ConcorsandoWasm/PercorsiWrappedWorker";
import * as _ from "lodash";

export function getUptime({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtilityApi();
        apiInstance.getUptime(function (error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 200) {
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        })
    })
}

export function clearUptime({ commit }) {
    commit(UTILITY_MUTATIONS.UNSET_UPTIME)
}

export function setAutoNext({commit}, payload) {
    commit(UTILITY_MUTATIONS.SET_VALUE, {value: payload, key: "autoNext"})
}

/**
 *
 * @param commit
 * @param state
 * @param rootState
 * @param rootGetters
 * @param {"CONCORSO" | "PERCORSO"} type
 * @param {any} data
 */
export async function clearUserCompetitions({commit, state}, {type, data} = {}) {
    let user_concorso_ids, worker, onFinally;
    if (type === "PERCORSO") {
        if (state.percorsiCleared) {
            return;
        }
        user_concorso_ids = Array.isArray(data) ? data.map(x => x.id_argomento) : undefined;
        worker = PercorsiWrappedWorker.instance;
        onFinally = () => commit(UTILITY_MUTATIONS.SET_VALUE, {value: true, key: "percorsiCleared"})
    }
    else if (type === "CONCORSO") {
        if (state.concorsiCleared) {
            return;
        }
        user_concorso_ids = Array.isArray(data) ? data.map(x => x.id_concorso) : undefined;
        worker = WrappedWorker.instance;
        onFinally = () => commit(UTILITY_MUTATIONS.SET_VALUE, {value: true, key: "concorsiCleared"})
    }
    else {
        return;
    }

    try {
        if (Array.isArray(user_concorso_ids)) {
            await worker.clearConcorsi(user_concorso_ids);
        }
    }
    finally {
        onFinally();
    }
}
