import _ from "lodash";

export const UTILITY_ACTIONS = Object.freeze({
    GET_UPTIME: "getUptime",
    CLEAR_UPTIME: "clearUptime",
    SET_AUTO_NEXT: "setAutoNext",
    CLEAR_USER_COMPETITIONS: "clearUserCompetitions",
});

export const UTILITY_ACTIONS_DISPATCH = _.mapValues(UTILITY_ACTIONS, v => `utility/${v}`);
