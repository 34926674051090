import MyWorker from 'worker-loader?name=js/wasm-module.[hash].js!./worker'
import _ from 'lodash';
import {clearDb} from "./DBOperations";
import StatsManager from "./StatsManager";
import {Errors} from "./Messages";
import {CONCORSO_OR_PERCORSO, CONSTANTS} from "@/lib/ConcorsandoWasm/Constants";

// Source: https://codeburst.io/promises-for-the-web-worker-9311b7831733

const resolves = {}
const rejects = {}
let globalMsgId = 0
// Activate calculation in the worker, returning a promise
function sendMsg(type, args, worker){
  const msgId = globalMsgId++
  const msg = {
    id: msgId,
    args,
    type,
  }
  return new Promise(function (resolve, reject) {
    // save callbacks for later
    resolves[msgId] = resolve
    rejects[msgId] = reject
    worker.postMessage(msg)
  }) 
}
// Handle incoming calculation result
function handleMsg(msg) {
  if (process.env.NODE_ENV === "development") {
    console.debug('handleMsg')
    console.debug(msg)
  }
  const {id, error: err, result} = msg.data
  if ('error' in msg.data) {
    // error condition
    const reject = rejects[id]
    if (reject) {
        if (err) {
          reject(err)
        } else {
          reject('Got nothing')
        }
    }
  }
  else {
    const resolve = resolves[id]
    if (resolve) {
      try {
        const parsed = JSON.parse(result);
        resolve(parsed)
      } catch (e) {
        resolve(result)
      }
    }
  }
  
  // purge used callbacks
  delete resolves[id]
  delete rejects[id]
}
// Wrapper class
class WrappedWorker {
  /**
   * Wrapper per il WebWorker che utilizza al suo interno il modulo wasm.
   * 
   * Per utilizzarlo prendere l'istanza con
   * 
   * ```
   * let worker = WrappedWorker.instance
   * ```
   * 
   * # Metodi Gestione DB
   * 
   * getAllIds() ritorna l'elenco di tutti gli id salvati in database
   * getAllMeta() ritorna i metadati di tutti i concorsi salvati in db
   * addConcorso({id_concorso, data_ultimo_aggiornamento}, concorso) aggiunge un concorso al database
   * delConcorso(id_concorso) rimuove un concorso dal database
   * 
   * # Gestione Questionari
   * 
   * WORK IN PROGRESS
   */
  constructor(storeName = CONSTANTS.CONCORSI_STORE, concorsoType = CONCORSO_OR_PERCORSO.CONCORSO, worker) {
    if (worker) {
      this.worker = worker
    }
    else {
      this.initWorker();
      this.bindWorkerOnMessage();
    }
    this.initialized = false
    this.concorso = false 
    this.storeName = storeName;
    this.concorso_type = concorsoType;
  }

  initWorker() {
    this.worker = new MyWorker();
  }

  bindWorkerOnMessage() {
    this.worker.onmessage = handleMsg;
  }

  // DEBUG
  async identity(x) {
    return sendMsg("identity", [x], this.worker)
  }

  static get instance() {
    if (this.workerInstance) {
      return this.workerInstance
    }
    else {
      this.workerInstance = new WrappedWorker()
      return (this.workerInstance)
    }
  }

  /* METODI UTILITY CHE VARIANO FRA BANCHE DATI E MATERIE */

  /**
   * Calcola l'id usato nelle statistiche a partire dall'id ritornato dal wasm
   * @param {string | number} origId
   * @returns {string}
   */
  getStatsId(origId) {
    return String(origId);
  }

  /**
   * Calcola l'id usato nel wasm a partire da quello delle statistiche
   * @param {string} statsId
   * @returns {string}
   */
  getWasmId(statsId) {
    return statsId;
  }

  /* PUBLIC METHODS */

  /**
   * Inizializza un concorso
   * @param {string} id_concorso 
   */
  async initConcorso(id_concorso) {
    await this._ensureInitialized()
    const res = await sendMsg("initConcorso", [this.storeName, id_concorso], this.worker)
    this.concorso = id_concorso
    return res
  }

  async initEsercitazione(id_concorso) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    return await sendMsg("moduleInitEsercitazione", [], this.worker)
  }

  /**
   * Aggiunge un concorso al database. Sovrascrive se già esiste. In questo caso
   * vengono resettate tutte le statistiche presenti in locale, quindi bisognerà
   * scaricarle nuovamente.
   * @param {{id_concorso: String, data_ultimo_aggiornamento: String}} concorso
   * @param {Uint8Array} data
   */
  async addConcorso(concorso, data) {
    /* Carichiamo il modulo wasm per assicurarci di averlo disponibile. 
     * Attualmente non serve, ma in futuro bisognerà calcolare dei metadati
     * per il concorso e per farlo bisognerà che il modulo wasm sia caricato.
     */
    await this._ensureInitialized();

    const {id_concorso, data_ultimo_aggiornamento} = concorso;
    const ts = Date.parse(data_ultimo_aggiornamento);
    let c_date;
    if (!isNaN(ts)) {
      c_date = new Date(ts);
    }

    let res = await sendMsg("addConcorso", [id_concorso, data, c_date, this.concorso_type], this.worker);
    this.concorso = id_concorso;
    return res;
  }

  /**
   * Rimuove un concorso
   * @param {string} id_concorso 
   */
  async delConcorso(id_concorso) {
    return sendMsg("delConcorso", [id_concorso, this.concorso_type], this.worker);
  }

  /**
   * Rimuove un concorso
   * @param {string[]} user_concorso_ids
   */
  async clearConcorsi(user_concorso_ids) {
    return sendMsg("clearConcorsi", [user_concorso_ids, this.concorso_type], this.worker);
  }

  async clearAll() {
    return clearDb()
  }

  /**
   * Ritorna i metadati di tutti i concorsi in db
   * @returns {Promise<ConcorsoMeta[]>}
   */
  async getAllMeta() {
    return sendMsg("getAllMeta", [this.concorso_type], this.worker);
  }

  /**
   * Ritorna gli id di tutti i concorsi in db
   */
  async getAllIds() {
    return sendMsg("getAllKeys", [this.concorso_type], this.worker);
  }

  /**
   * Ritorna i metadati di un concorso
   * @param {string} id_concorso
   * @returns {Promise<ConcorsoMeta>}
   */
  async getMeta(id_concorso) {
    return sendMsg("getMeta", [id_concorso, this.concorso_type], this.worker);
  }

  /**
   * Ritorna una stima dello spazio disponibile rimanente
   * @returns {Promise<{quota: any, warning?: string}>}
   */
  async getQuota() {
    return sendMsg("getQuota", [], this.worker);
  }

  async getArgomenti(id_concorso) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    return this._moduleGetArgomenti();
  }

  async getMaterie(id_concorso) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    const materie = await this._moduleGetMaterie();
    return _.sortBy(materie, ["materia_uff"])
  }

  async getByArgomento(id_concorso, id_argomento) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    return this._moduleGetByArgomento(id_argomento);
  }

  async getByMateria(id_concorso, id_materia) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    return this._moduleGetByMateria(id_materia);
  }

  async getDomande(id_concorso, query) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    return this._moduleGetDomande(query);
  }

  async getAllStats(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso) {
    return sendMsg("getAllStats", [id_concorso, storeName], this.worker);
  }

  async updateConcorsoAssegnate(id_concorso) {
    return sendMsg('updateConcorsoAssegnate', [id_concorso], this.worker);
  }

  /**
   * @typedef {object} StampaQuery
   * @param {number | number[]} livelliConoscenza
   */
  /**
   * Stampa le domande risultanti dall'ultimo filtro effettuato in StatsManager, con in più la possibilità
   * di filtrare ulteriormente per livello conoscenza.
   * @param {string} id_concorso
   * @param {StampaQuery} query
   * @returns {Promise<Object.<String, Array>>}
   */
  async getDomandeStampa(id_concorso, query = {}) {

    /* NOTA: poiché questo metodo non può essere chiamato su un percorso, non mettiamo StatsManager.instance in
        una variabile di classe. Anche perché questo creerebbe una dipendenza circolare fra WrappedWorker e StatsManager
        che potrebbe causare problemi con il sistema di DependencyInjection che abbiamo utilizzato per queste componenti.
     */
    const statsManager = StatsManager.instance;
    if (statsManager.id_concorso !== id_concorso) {
      throw {error: {code: Errors.ID_CONCORSO_NON_COINCIDE}}
    }
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }
    const domandePromise = this._moduleGetDomandeStampa();

    const allStats = await this.getAllStats(undefined, id_concorso);

    const domande = await domandePromise;

    const gettonate = {}
    allStats
      .filter(x => x.has_risposta === 1 && x.risposta_gettonata)
      .forEach(x => gettonate[String(x.id_domanda)] = String(x.risposta_gettonata));

    const arrayRisposteGettonate = await this._moduleGetRisposteGettonate(gettonate);

    domande
      .filter(x => !x.risposta && gettonate[x.id_domanda])
      .forEach(x => {
        let gettonata = _.find(arrayRisposteGettonate, {id_domanda: String(x.id_domanda)});
        if (gettonata) {
          x.risposta = gettonata.risposta;
        }
      })

    let livelliConoscenza = _.get(query, "livelliConoscenza", []);
    livelliConoscenza = Array.isArray(livelliConoscenza) ? livelliConoscenza : [livelliConoscenza];

    function matchLivello(livello) {
      if (livelliConoscenza.length > 0) {
        return livelliConoscenza.includes(livello);
      }
      else {
        return true;
      }
    }

    const domande_sorted = _.sortBy(
      domande.filter(x =>
        statsManager.searchResultLevelsMap[x.id_domanda] !== undefined
        && matchLivello(statsManager.searchResultLevelsMap[x.id_domanda])
      ),
      ["domanda"]
    );

    return _.groupBy(domande_sorted, 'id_materia');
  }

  /**
   *
   * @param {string} id_concorso
   * @param {string | string[]} search - un array di stringhe
   * @param {boolean} mode_all
   * @param {boolean} filter_by_spiegazione - se true, ritorna solo le domande con spiegazione
   * @param {debug} boolean - se true, ritorna anche il contenuto delle domande
   * @returns {Promise<void>}
   */
  async searchDomande(id_concorso, search, mode_all, filter_by_spiegazione = false, {debug = false} = {}) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }

    let query_a = Array.isArray(search) ? search : [search];

    if (debug) {
      return this._moduleSearchDomande(query_a, !!mode_all, filter_by_spiegazione);
    }
    return this._moduleSearchDomande(query_a, !!mode_all, filter_by_spiegazione)
      .then(res => res.map(x => _.pick(x, ["id", "indice_uff", "domanda"])));
  }

  async searchDomandeByIds(id_concorso, ids) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }

    let ids_a = Array.isArray(ids) ? ids : [];

    return this._moduleSearchDomandeByIds(ids_a)
      .then(res => res.map(x => _.pick(x, ["id", "indice_uff", "domanda"])))
  }

  async getDomandeQuiz(id_concorso, ids = []) {
    if (this.concorso !== id_concorso) {
      await this.initConcorso(id_concorso);
    }

    let ids_a = Array.isArray(ids) ? ids : [ids];

    return this._moduleGetDomandeQuiz(ids_a);
  }

  async getHash(input) {
    return this._moduleComputeHash(input);
  }
  
  /* PRIVATE METHODS, low level access to the worker */
  
  _moduleLoadWasm() {
    return sendMsg("moduleLoadWasm", [], this.worker)
  }

  async _ensureInitialized() {
    if (this.initialized) {
      return true
    }
    else {
      const res = await this._moduleLoadWasm()
      this.initialized = true
      return res
    }
  }

  /**
   * Inizializza un concorso nel modulo wasm
   * @private
   * @param {Uint8Array} data 
   * @returns {Promise}
   */
  async _moduleInitConcorso(data) {
    await this._ensureInitialized()
    const res = await sendMsg("moduleInitConcorso", [data], this.worker)
    this.concorso = true
    return res
  }

  /**
   * @private
   * @returns {Promise}
   */
  async _moduleGetAll() {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetAll", [], this.worker)
  }

  /**
   * @private
   * @returns {Promise}
   */
  _moduleGetFirst() {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetFirst", [], this.worker)
  }

  /**
   * @private
   * @returns {Promise}
   */
  _moduleGetMaterie() {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetMaterie", [], this.worker)
  }

  /**
   * @private
   * @returns {Promise}
   */
  _moduleGetArgomenti() {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetArgomenti", [], this.worker)
  }

  /**
   * Ottieni l'elenco delle domande per un determinato argomento
   * @private
   * @param {string} argomento id dell'argomento
   * @returns {Promise}
   */
  _moduleGetByArgomento(argomento) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetByArgomento", [argomento], this.worker)
  }

  /**
   * Ottieni l'elenco delle domande per una determinata materia
   * @private
   * @param {string} materia id della materia
   * @returns {Promise}
   */
  _moduleGetByMateria(materia) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetByMateria", [materia], this.worker)
  }

  _moduleGetDomande(query) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetDomande", [query], this.worker)
  }

  _moduleGetDomandeStampa() {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetDomandeStampa", [], this.worker)
  }

  _moduleSearchDomande(search, mode, filter_by_spiegazione) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleSearchDomande", [search, mode, filter_by_spiegazione], this.worker)
  }

  _moduleSearchDomandeByIds(ids) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleSearchDomandeByIds", [ids], this.worker)
  }

  /**
   *
   * @param {Object.<String,String>} g_map - la mappa che associa a ogni id_domanda l'id della risposta gettonata
   * @returns {Promise<unknown>}
   * @private
   */
  _moduleGetRisposteGettonate(g_map) {
    if(!this.concorso){
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetRisposteGettonate", [g_map], this.worker)
  }

  async _moduleComputeHash(input) {
    await this._ensureInitialized();
    return sendMsg("moduleComputeHash", [input], this.worker)
  }

  /**
   * @param {String[]} domande_ids
   * @returns {*|Promise<any>|Promise}
   * @private
   */
  _moduleGetDomandeQuiz(domande_ids) {
    if (!this.concorso) {
      throw {err: "CONCORSO_NOT_INITIALIZED"}
    }
    return sendMsg("moduleGetDomandeQuiz", [domande_ids], this.worker)
  }
}
export default WrappedWorker
