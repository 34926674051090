import * as ConcorsandoApi from "../../lib/ConcorsandoApi";
import { PATH_MUTATIONS } from "./mutations-enum";
import { AUTH_ACTIONS_DISPATCH } from "../auth/actions-enum";
import _ from "lodash";
import {UTILITY_ACTIONS_DISPATCH} from "@/store/utility/actions-enum";
import firebase from "firebase";

export function getPaths ({ dispatch, commit }, payload) {
  return new Promise((resolve, reject) => {
      const apiInstance = new ConcorsandoApi.PercorsoApi();
      apiInstance.getPercorsi(payload, function(error, data, response) {
          if (error) {
              dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                  .catch(() => reject(error))
          } else {
              const resBodyArray = Array.isArray(response.body) ? response.body : [];
              const paths = _.filter(resBodyArray, item => !_.isEmpty(item));
              commit(PATH_MUTATIONS.SET_PATHS, paths)
              resolve(paths)
          }
      });
  })
}

export function getPath ({ dispatch, commit, getters }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.PercorsoApi();
        const idPercorso = payload.idPercorso
        const opts = payload.opts
        apiInstance.getPercorso(idPercorso, opts, function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                const responseBody = response.body;
                if (!_.isEmpty(responseBody)) {
                    commit(PATH_MUTATIONS.SET_SELECTED_PATHS, updateSelectedPaths(getters, responseBody))
                    resolve(responseBody)
                } else {
                    reject();
                }
            }
        })
    })
}

export function getUserPaths ({ dispatch, commit, getters }) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.PercorsoApi();
        apiInstance.getPercorsiByUtente((error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                const resBodyArray = Array.isArray(response.body) ? response.body : [];
                const paths = _.filter(resBodyArray, item => !_.isEmpty(item));
                commit(PATH_MUTATIONS.SET_USER_PATHS, paths)
                commit(PATH_MUTATIONS.SET_SELECTED_PATHS, setNewSelectedPaths(getters, paths))
                dispatch(UTILITY_ACTIONS_DISPATCH.CLEAR_USER_COMPETITIONS, {type: "PERCORSO", data: paths}, {root: true})
                    .finally(() => {
                        resolve(paths)
                    })
            }
        });
    })
}

function setNewSelectedPaths (getters, userPaths) {
    return getters.selectedPaths.map(sC => {
        const cM = _.find(userPaths, (uC) => uC.id_argomento === sC.id_argomento)
        if (cM !== undefined) {
            const nSC = _.cloneDeep(cM)
            nSC.indice = sC.indice
            if (sC.customTrial !== undefined)
                nSC.customTrial = sC.customTrial
            if (sC.filters !== undefined)
                nSC.filters = sC.filters
            if (sC.selected === true) {
                nSC.selected = sC.selected
            } else {
                nSC.selected = false
            }

            return nSC
        }
    }).filter(sC => !_.isEmpty(sC))
}

function updateSelectedPaths (getters, selectedPath) {
    return getters.selectedPaths.map(sC => {
        if (sC.id_argomento === selectedPath.id_argomento) {
            return {
                ...sC,
                ...selectedPath
            }
        } else {
            return sC;
        }
    })
}

export function updateSelectedPathByIndice ({getters, commit}, payload) {
    const selectedPaths = [...getters.selectedPaths];
    const newSelectedPath = _.cloneDeep(payload);
    // delete newSelectedPath.sotto_percorsi;
    selectedPaths[newSelectedPath.indice] = newSelectedPath;

    commit(PATH_MUTATIONS.SET_SELECTED_PATHS, selectedPaths);
}

export function deleteUserPath({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.PercorsoApi();
        apiInstance.cancellaPercorsoById(payload, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve(response.body)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function setSelectedPath ({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
        let selectedPaths = _.cloneDeep(getters.selectedPaths)
        const comp = _.find(getters.userPaths, x => x.id_argomento === payload);
        if (comp) {
            if (getters.selectedPaths !== undefined && getters.selectedPaths.length > 0) {
                _.forEach(selectedPaths, function (c) {
                    c.selected = false
                })
            } else {
                selectedPaths = []
            }
            let path = _.find(selectedPaths, function (c) {
                return c.id_argomento === payload
            })
            if (path === undefined) {
                path = _.cloneDeep(comp)
                path.selected = true
                selectedPaths.push(path)
            } else {
                path.selected = true
            }
            commit(PATH_MUTATIONS.SET_SELECTED_PATHS, selectedPaths)
            resolve(path)
        } else {
            reject()
        }
    })
}

export function clearPaths({ commit }) {
    commit(PATH_MUTATIONS.UNSET_USER_PATHS)
    commit(PATH_MUTATIONS.UNSET_PATHS)
    commit(PATH_MUTATIONS.UNSET_SELECTED_PATHS)
}
