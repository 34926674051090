import ApiClient from "../../ConcorsandoApi/ApiClient";
import {Errors} from "../../ConcorsandoWasm/Messages";

export default class BrevoClient {
    defaultHeaders = {}
    basePath = ""

    /**
     * Singleton getter
     * @returns {CustomClient}
     */
    static get instance() {
        if (!this.thisInstance) {
            this.thisInstance = new BrevoClient();
        }
        return this.thisInstance;
    }

    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    async postContacts(opts) {
        opts = opts || {};
        let postBody = {
            attributes: {
                PREMIUM: opts.premium
            },
            updateEnabled: true,
            email: opts.email,
            listIds: [
                4
            ],
            ext_id: opts.ext_id
        };

        const res = await fetch(this.basePath + '/contacts', {
            method: 'POST',
            headers: {
                'api-key': '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',
                'Content-Type': 'application/json',
                'Cookie': '__cf_bm=8Ioul.A90tWfOAX5HjU6ej6NNlgH_LAbgak1B8ErIgs-1704980007-1-AaF3H4XAJvfu35C5oI4AI9Bzq8r6q9gRnpgE3V1cyT5wHNvgYjHO7/w1CzKQS9y6Atf2etYYtmI0JK8Ie62OVHI='
            },
            // body: '{\n  "attributes": {\n    "PREMIUM": false\n  },\n  "updateEnabled": true,\n  "email": "pippo1@pluto.it",\n  "listIds": [\n    4\n  ],\n  "ext_id": "11"\n}',
            body: JSON.stringify(postBody)
        });

        if (!res.ok) {
            throw {code: Errors.REMOTE_REQUEST_FAILED}
        }
        return res;
    }
}
