export function SET_UPTIME (state, payload) {
    state.uptime = payload
}

export function UNSET_UPTIME (state) {
    state.uptime = undefined
}

export function SET_VALUE (state, payload = {}) {
    state[payload.key] = payload.value
}
