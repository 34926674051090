/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import PercorsoVO from '../model/PercorsoVO';

/**
* Percorso service.
* @module api/PercorsoApi
* @version 2.0.0
*/
export default class PercorsoApi {

    /**
    * Constructs a new PercorsoApi. 
    * @alias module:api/PercorsoApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the cancellaPercorsoById operation.
     * @callback module:api/PercorsoApi~cancellaPercorsoByIdCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che cancella un percorso associato all&#x27;utente loggato.
     * Cancella il percorso associato all&#x27;utente loggato a partire dall&#x27;id_argomento.
     * @param {module:api/PercorsoApi~cancellaPercorsoByIdCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaPercorsoById(idArgomento, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_argomento': idArgomento
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_concorso/percorso', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getPercorsi operation.
     * @callback module:api/PercorsoApi~getPercorsiCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PercorsoVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la lista dei percorsi.
     * Ritorna la lista dei percorsi.
     * @param {Object} opts Optional parameters
     * @param {module:api/PercorsoApi~getPercorsiCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PercorsoVO>}
     */
    getPercorsi(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'start': opts['start'],
        'size': opts['size'],
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PercorsoVO];

      return this.apiClient.callApi(
        '/ms_concorso/percorsi', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getPercorsiByUtente operation.
     * @callback module:api/PercorsoApi~getPercorsiByUtenteCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PercorsoVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la lista dei percorsi scaricati per l&#x27;utente loggato.
     * Ritorna la lista dei percorsi scaricati per l&#x27;utente loggato senza le domande.
     * @param {module:api/PercorsoApi~getPercorsiByUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PercorsoVO>}
     */
    getPercorsiByUtente(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PercorsoVO];

      return this.apiClient.callApi(
        '/ms_concorso/percorsi_utente', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getPercorso operation.
     * @callback module:api/PercorsoApi~getPercorsoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PercorsoVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna il percorso.
     * Ritorna il percorso in base all&#x27;id_argomento.
     * @param {Object} opts Optional parameters
     * @param {module:api/PercorsoApi~getPercorsoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PercorsoVO}
     */
    getPercorso(idArgomento, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_argomento': idArgomento,
        'sorgente': opts['sorgente']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PercorsoVO;

      return this.apiClient.callApi(
        '/ms_concorso/percorso', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
