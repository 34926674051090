/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The AutenticazioneVO model module.
* @module model/AutenticazioneVO
* @version 2.0.0
*/
export default class AutenticazioneVO {
    /**
    * Constructs a new <code>AutenticazioneVO</code>.
    * @alias module:model/AutenticazioneVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>AutenticazioneVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AutenticazioneVO} obj Optional instance to populate.
    * @return {module:model/AutenticazioneVO} The populated <code>AutenticazioneVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AutenticazioneVO();
                        
            
            if (data.hasOwnProperty('social')) {
                obj['social'] = ApiClient.convertToType(data['social'], 'String');
            }
            if (data.hasOwnProperty('token_social')) {
                obj['token_social'] = ApiClient.convertToType(data['token_social'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('dispositivo')) {
                obj['dispositivo'] = ApiClient.convertToType(data['dispositivo'], 'String');
            }
            if (data.hasOwnProperty('sistema_operativo')) {
                obj['sistema_operativo'] = ApiClient.convertToType(data['sistema_operativo'], 'String');
            }
            if (data.hasOwnProperty('versione_app')) {
                obj['versione_app'] = ApiClient.convertToType(data['versione_app'], 'String');
            }
        }
        return obj;
    }

    /**
    * facebook, google, apple, concorsando
    * @member {String} social
    */
    'social' = undefined;
    /**
    * @member {String} token_social
    */
    'token_social' = undefined;
    /**
    * @member {String} username
    */
    'username' = undefined;
    /**
    * @member {String} password
    */
    'password' = undefined;
    /**
    * @member {String} dispositivo
    */
    'dispositivo' = undefined;
    /**
    * @member {String} sistema_operativo
    */
    'sistema_operativo' = undefined;
    /**
    * @member {String} versione_app
    */
    'versione_app' = undefined;




}
