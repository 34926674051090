import {Errors} from "../../ConcorsandoWasm/Messages";

export default class BandiClient {
    defaultHeaders = {}
    basePath = ""
    /**
     * Singleton getter
     * @returns {BandiClient}
     */
    static get instance() {
        if (!this.thisInstance) {
            this.thisInstance = new BandiClient();
        }
        return this.thisInstance;
    }

    /**
     * Bandi in scadenza api
     * @returns {string}
     * @private
     */

    _buildBandiUrl() {
        return this.basePath;
    }

    async getBandi() {
        const req_url = this._buildBandiUrl();

        const res = await fetch(
            req_url,
            {
                mode: 'cors',
                method: 'GET',
                headers: {
                    ...this.defaultHeaders
                }
            }
        )
        if (!res.ok) {
            throw {code: Errors.REMOTE_REQUEST_FAILED}
        }

        const reader = res.body.getReader();

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        let chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;
        }

        // Step 4: concatenate chunks into single Uint8Array
        let chunksAll = new Uint8Array(receivedLength); // (4.1)
        let position = 0;
        for(let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
        }

        const newRespose = new Response(chunksAll)

        return newRespose.json();
    }
}
