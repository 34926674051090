import {Errors} from "./Messages";
import {deleteDB, openDB} from "idb";
import {CONSTANTS, DBIndexesEnum, LivelliConoscenzaEnum} from "./Constants";
import _ from 'lodash';
import {getCursorBySync} from "../utils";

// Rappresenta una migrazione in corso. Ogni volta che viene richiesta l'apertura del db, prima di procedere attendiamo
// questa promessa.
let dbMigrationPromise = Promise.resolve();

export const getConcorsiDbPromise = (function () {
  "use strict";

  // check for support
  if (!('indexedDB' in self)) {
    return Promise.reject({error: {code: Errors.INDEXEDDB_NOT_AVAILABLE}})
  }

  let migrateDb = false;
  // Aspettiamo l'eventuale dbMigrationPromise esistente prima di aprire il db
  return dbMigrationPromise
    .then(() => openDB(CONSTANTS.CONCORSI_DB, 9, {
      upgrade(db, oldVersion, newVersion, tx) {
        /* # CHANGELOG
         *
         * ## VERSION 9
         *  - Utilizza id_domanda anziché indice_uff per l'ordinamento ufficiale
         *
         * ## VERSION 8
         *  - Gestione Materie, aggiunti store:
         *    - PERCORSI_STORE
         *    - PERCORSI_META
         *    - PERCORSI_STATISTICHE_STORE
         *
         * ## VERSION 7
         *  - STATISTICHE_STORE:
         *    - aggiunto indice BY_CONCORSO_HAS_RISPOSTA
         *
         * ## VERSION 6
         *  - STATISTICHE_STORE:
         *    - aggiunto indice BY_CONCORSO_SINCRONIZZATA
         *
         * ## VERSION 5
         *  - STATISTICHE_STORE:
         *    - aggiunto indice BY_CONCORSO_INDICE
         *
         * ## VERSION 4
         *  - STATISTICHE_STORE:
         *    - rimosso indice "by_concorso_materia"
         *    - aggiunto indice BY_CONCORSO_MATERIA_LIVELLO
         *
         * ## VERSION 3
         *  - STATISTICHE_STORE:
         *    - aggiunto indice "by_concorso_materia"
         *
         * ## VERSION 2
         *  - aggiunto store: STATISTICHE_STORE
         *  - STATISTICHE_STORE:
         *    - aggiunti indici: BY_CONCORSO, BY_DOMANDA
         *
         * ## VERSION 1
         *  - aggiunti store: CONCORSI_STORE, CONCORSI_META
         */
        if (oldVersion < 1) {
          /* CONCORSI_STORE has the shape ConcorsiData[] where
          * ```
          * interface ConcorsiData {
          *  [CONSTANTS.CONCORSI_KEY]: string, // primary key
          *  [CONSTANTS.CONCORSI_DATA_KEY]: Uint8Array,
          * }
          * ```
          */
          db.createObjectStore(CONSTANTS.CONCORSI_STORE, {keyPath: CONSTANTS.CONCORSI_KEY})
          /* CONCORSI_META has the shape ConcorsiMeta[] where
          * ```
          * interface ConcorsiMeta {
          *  [CONSTANTS.CONCORSI_KEY]: string, // primary key
          *  data_ultimo_aggiornamento: Date,
          *  n_domande: number,
          *  n_domande_by_materia: {[id_materia: string]: number}
          * }
          */
          db.createObjectStore(CONSTANTS.CONCORSI_META, {keyPath: CONSTANTS.CONCORSI_KEY})
        }
        if (oldVersion < 2) {
          /* STATISTICHE_STORE has the shape Array<StatsDomanda> (see `worker.js`)
           */
          const statisticheStore = db.createObjectStore(CONSTANTS.STATISTICHE_STORE, {keyPath: "id_domanda"});
          statisticheStore.createIndex(DBIndexesEnum.BY_CONCORSO, "id_concorso", {unique: false});
          statisticheStore.createIndex(DBIndexesEnum.BY_DOMANDA, ["id_concorso", "id_domanda"], {unique: true})
        }
        if (oldVersion === 3) {
          const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
          objectStore.deleteIndex("by_concorso_materia");
        }
        if (oldVersion < 4) {
          const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
          objectStore.createIndex(
            DBIndexesEnum.BY_CONCORSO_MATERIA_LIVELLO,
            ["id_concorso", "id_materia", "livello_conoscenza"],
            {unique: false}
          );
        }
        if (oldVersion < 5) {
          const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
          objectStore.createIndex(
            DBIndexesEnum.BY_CONCORSO_INDICE,
            ["id_concorso", "indice_uff"],
            {unique: false}
          );
        }
        if (oldVersion < 6) {
          migrateDb = true;
          const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
          objectStore.createIndex(
            DBIndexesEnum.BY_CONCORSO_SINCRONIZZATA,
            ["id_concorso", "sincronizzata"],
            {unique: false}
          );
        }
        if (oldVersion < 7) {
          const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
          objectStore.createIndex(
              DBIndexesEnum.BY_CONCORSO_HAS_RISPOSTA_INDICE,
              ["id_concorso", "has_risposta", "indice_uff"],
              {unique: false}
          );
        }
        if (oldVersion < 8) {
          /* CONCORSI_STORE has the shape ConcorsiData[] where
          * ```
          * interface ConcorsiData {
          *  [CONSTANTS.CONCORSI_KEY]: string, // primary key
          *  [CONSTANTS.CONCORSI_DATA_KEY]: Uint8Array,
          * }
          * ```
          */
          db.createObjectStore(CONSTANTS.PERCORSI_STORE, {keyPath: CONSTANTS.PERCORSI_KEY})
          /* CONCORSI_META has the shape ConcorsiMeta[] where
          * ```
          * interface ConcorsiMeta {
          *  [CONSTANTS.CONCORSI_KEY]: string, // primary key
          *  data_ultimo_aggiornamento: Date,
          *  n_domande: number,
          *  n_domande_by_materia: {[id_materia: string]: number}
          * }
          */
          db.createObjectStore(CONSTANTS.PERCORSI_META, {keyPath: CONSTANTS.PERCORSI_KEY})
          /* PERCORSI_STATISTICHE_STORE has the shape Array<StatsDomanda> (see `worker.js`) and is isomorphic to STATISTICHE_STORE
           * with the following indexex
           */
          const statisticheStore = db.createObjectStore(CONSTANTS.PERCORSI_STATISTICHE_STORE, {keyPath: "id_domanda"});
          statisticheStore.createIndex(DBIndexesEnum.BY_CONCORSO, CONSTANTS.PERCORSI_KEY, {unique: false});
          statisticheStore.createIndex(DBIndexesEnum.BY_DOMANDA, [CONSTANTS.PERCORSI_KEY, "id_domanda"], {unique: true})
          statisticheStore.createIndex(
              DBIndexesEnum.BY_CONCORSO_MATERIA_LIVELLO,
              [CONSTANTS.PERCORSI_KEY, "id_sotto_percorso", "livello_conoscenza"],
              {unique: false}
          );
          // Omitted: DBIndexesEnum.BY_CONCORSO_INDICE
          statisticheStore.createIndex(
              DBIndexesEnum.BY_CONCORSO_SINCRONIZZATA,
              [CONSTANTS.PERCORSI_KEY, "sincronizzata"],
              {unique: false}
          );
          statisticheStore.createIndex(
              DBIndexesEnum.BY_CONCORSO_HAS_RISPOSTA_INDICE,
              [CONSTANTS.PERCORSI_KEY, "has_risposta", "indice_uff"],
              {unique: false}
          );
        }
        const objectStore = tx.objectStore(CONSTANTS.STATISTICHE_STORE);
        objectStore.createIndex(
            DBIndexesEnum.BY_CONCORSO_HAS_RISPOSTA_INDICE_NEW,
            ["id_concorso", "has_risposta", "id_domanda"],
            {unique: false}
        );
        const percorsiStatisticheStore = tx.objectStore(CONSTANTS.PERCORSI_STATISTICHE_STORE);
        percorsiStatisticheStore.createIndex(
            DBIndexesEnum.BY_CONCORSO_HAS_RISPOSTA_INDICE_NEW,
            [CONSTANTS.PERCORSI_KEY, "has_risposta", "id_domanda"],
            {unique: false}
        );
      }
    }))
    // Un avolta aperto il db, facciamo partire la migrazione se serve e poi ritorniamo il db
    .then(db => {
      if (migrateDb) {
        dbMigrationPromise = updateSincronizzataValues(db, CONSTANTS.STATISTICHE_STORE)
          .catch((e) => {
            console.log("Migration failed");
            console.error(e);
          });
      }
      return dbMigrationPromise
        .then(() => db);
    })
});

/**
 * Aggiorna i valori di sincronizzata nel database. Se riceve una promessa in input
 * @param dbPromise
 * @param storeName
 * @returns {Promise<void>}
 */
export async function updateSincronizzataValues(dbPromise = undefined, storeName = CONSTANTS.STATISTICHE_STORE) {
  let db;
  if (dbPromise) {
    db = await dbPromise;
  } else {
    db = await getConcorsiDbPromise();
  }
  const tx = db.transaction([storeName], 'readwrite');

  try {
    console.log("[Aggiornamento db]: sincronizzata boolean -> integer")
    const store = tx.objectStore(storeName);
    const cursor = await store.openCursor();

    // Implementazione di matchStatsToSync per la versione precedente del db
    const matchStatsToSync = quiz => {
      const livello_conoscenza = _.get(quiz, "livello_conoscenza", LivelliConoscenzaEnum.NON_RISPOSTE);
      const sincronizzata = _.get(quiz, "sincronizzata", false);
      const eseguita = _.get(quiz, "data_eseguita");
      const isPreferitiUpdated = _.get(quiz, "isPreferitiUpdated", false);

      return !sincronizzata && eseguita && (
        livello_conoscenza !== LivelliConoscenzaEnum.NON_RISPOSTE || isPreferitiUpdated
      )
    };
    while (cursor) {
      const quizStats = cursor.value;
      if (quizStats.sincronizzata === true) {
        quizStats.sincronizzata = 1;
      }
      else if (matchStatsToSync(quizStats)) {
        quizStats.sincronizzata = 0;
      }
      else {
        quizStats.sincronizzata = undefined;
      }
      cursor.update(quizStats).catch(e => {
        console.log("Error updating stats");
        console.log(quizStats);
        console.error(e);
      });
      await cursor.continue();
    }
  }
  finally {
    if (!dbPromise) {
      db.close();
    }
  }
}

/**
 * @param {string} storeName lo store su cui operare
 * @param {string} id_concorso
 * @param {{id_materie: Array<string>}} query
 * @param {IDBTransaction} transaction - deve includere STATISTICHE_STORE in readwrite
 * @returns {Promise<void>}
 */
export async function removeStats(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, query = {}, {transaction} = {}) {
  /* Warning:
   * According to [cleong's reply to this answer](https://stackoverflow.com/a/18626511)
   * > Deleting records while the cursor is open is buggy on Safari. The cursor would become null even when there're additional matching records. It's more reliable to grab all the primary keys, then call `IDBObjectStore.delete()` on each of them
   *
   * It doesn't affect this implementation because we are not using cursors to clean th db.
   */

  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }

  try {
    const statsStore = tx.objectStore(storeName);

    let keys;
    if (query.id_materie && Array.isArray(query.id_materie) && query.id_materie.length > 0) {
      const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO_MATERIA_LIVELLO);
      keys = (await Promise.all(query.id_materie.map(id_materia =>
          ix_concorso.getAllKeys(IDBKeyRange.bound([id_concorso, id_materia, -99], [id_concorso, id_materia, 2]))
        )
      ))
        .flatMap(x => x);
    } else {
      const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO);
      keys = await ix_concorso.getAllKeys(id_concorso);
    }
    await Promise.all([
      ...keys.map(k => statsStore.delete(k))
    ]);

    // If no transaction was provided, we await for the conclusion of the one we created
    if (!transaction) {
      await tx.done;
    }
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function resetAssegnateMeta(
  metaStoreName = CONSTANTS.CONCORSI_META,
  id_concorso,
  query = {},
  {transaction} = {}
) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([metaStoreName], 'readwrite');
  }

  try {
    const metaStore = tx.objectStore(metaStoreName);
    const meta = await metaStore.get(id_concorso);

    const checkMateria = query.id_materie && Array.isArray(query.id_materie) && query.id_materie.length > 0;

    let newMeta;
    if (checkMateria) {
      newMeta = {...meta};
      query.id_materie.forEach(id_materia => {
        newMeta.n_domande_by_materia[id_materia] = meta.n_domande_by_materia_orig[id_materia]
        newMeta.n_domande_assegnate_by_materia[id_materia] = 0;
      })
      newMeta.n_domande_assegnate = _.sum(Object.values(newMeta.n_domande_assegnate_by_materia))
    }
    else {
      newMeta = {
        ...meta,
        n_domande_assegnate: 0,
        n_domande_by_materia: meta.n_domande_by_materia_orig,
        n_domande_assegnate_by_materia: _.mapValues(meta.n_domande_assegnate_by_materia, () => 0)
      }
    }
    await metaStore.put(newMeta);
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function resetStats(
  storeName = CONSTANTS.STATISTICHE_STORE,
  id_concorso,
  query = {},
  {
    transaction,
    materiaKey = CONSTANTS.CONCORSI_MATERIA_KEY,
    matchMateria = (arr, statsMateria) => arr.includes(statsMateria) // usato come matchMateria(query.materie, quizStats[materiaKey])
  } = {}
) {
  /* Warning:
 * According to [cleong's reply to this answer](https://stackoverflow.com/a/18626511)
 * > Deleting records while the cursor is open is buggy on Safari. The cursor would become null even when there're additional matching records. It's more reliable to grab all the primary keys, then call `IDBObjectStore.delete()` on each of them
 *
 * It doesn't affect this implementation because we are not using cursors to clean th db.
 */

  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }

  try {
    const statsStore = tx.objectStore(storeName);

    // SETUP PARAMETRI
    const defaultStats = {
      preferiti: 0,
      livello_conoscenza: LivelliConoscenzaEnum.NON_RISPOSTE,
      sincronizzata: undefined, // -> Rimosso perché le statistiche di default non le consideriamo neanche ai fini della sincronizzazione
    };

    const paramsToOmit = [
      "data_eseguita",
      "isPreferitiUpdated",
      "isLevelUpdated",
      "risposta_gettonata",
    ];

    const checkMateria = query.id_materie && Array.isArray(query.id_materie) && query.id_materie.length > 0;

    let newStats = [];
    let keys = [];
    let cursor = await getCursorBySync(tx, storeName, id_concorso, [0, 1]);

    // Scorriamo tutte le domande del db e prendiamoci quelle che matchano la query
    while (cursor) {
      /** @type {StatsDomanda} */
      let quizStats = cursor.value;
      if (!checkMateria || matchMateria(query.id_materie, quizStats[materiaKey])) {
      // if (!checkMateria || query.id_materie.includes(quizStats.id_materia)) {
        const newQuizStats = {..._.omit(quizStats, paramsToOmit), ...defaultStats};
        if (quizStats.risposta_gettonata) {
          newQuizStats.has_risposta = 0;
        }
        newStats.push(newQuizStats);
        keys.push(cursor.primaryKey);
      }
      cursor = await cursor.continue();
    }

    // Rimuoviamo tutte le domande che abbiamo preso
    await Promise.all([
      ...keys.map(k => statsStore.delete(k))
    ]);

    // Aggiungiamo le nuove domande
    await Promise.all(newStats.map(s => statsStore.add(s)));

    // If no transaction was provided, we await for the conclusion of the one we created
    if (!transaction) {
      await tx.done;
    }
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

/**
 *
 * @param {string} id_concorso
 * @param {function} matcher
 * @param {any} transaction
 * @param {function} getCursor
 * @returns {Promise<Array<StatsDomanda>>}
 */
export async function getQuizzes(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, matcher = () => false, {transaction, getCursor} = {}) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readonly');
  }

  try {
    let cursor;
    if (_.isFunction(getCursor)) {
      cursor = await getCursor(tx);
    }
    else {
      const statsStore = tx.objectStore(storeName);
      const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO);
      cursor = await ix_concorso.openCursor(IDBKeyRange.only(id_concorso));
    }

    let res = [];

    // Scorriamo tutte le domande del db e prendiamoci quelle che matchano la query
    while (cursor) {
      /** @type {StatsDomanda} */
      let quizStats = cursor.value;
      if (matcher(quizStats)) {
        res.push(quizStats);
      }
      cursor = await cursor.continue();
    }

    // If no transaction was provided, we await for the conclusion of the one we created
    if (!transaction) {
      await tx.done;
    }

    return res;
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function getAllQuizzes(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, {transaction} = {}) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }

  try {
    const statsStore = tx.objectStore(storeName);
    const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO);
    return await ix_concorso.getAll(IDBKeyRange.only(id_concorso));
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function getQuizzesByIds(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, ids = [], {transaction} = {}) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readonly');
  }

  try {
    const statsStore = tx.objectStore(storeName);
    return await Promise.all(ids.map(id => statsStore.get(id)));
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function forEachQuiz(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, matcher = () => false, cb = () => {
}, {transaction, getCursor} = {}) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }

  try {
    let cursor;
    if (_.isFunction(getCursor)) {
      cursor = await getCursor(tx);
    }
    else {
      const statsStore = tx.objectStore(storeName);
      const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO);
      cursor = await ix_concorso.openCursor(IDBKeyRange.only(id_concorso));
    }

    // Scorriamo tutte le domande del db e prendiamoci quelle che matchano la query
    while (cursor) {
      /** @type {StatsDomanda} */
      let quizStats = cursor.value;
      if (matcher(quizStats)) {
        cb(quizStats);
      }
      cursor = await cursor.continue();
    }

    // If no transaction was provided, we await for the conclusion of the one we created
    if (!transaction) {
      await tx.done;
    }
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

/**
 * This function merge a new array of stats into the existing statistics table. It works by getting all the stats out
 * of the database, updating them in memory and then pushing again to db.
 * @param {string} id_concorso
 * @param {Array<StatsDomanda>} newData
 * @param {Function} merger
 * @param {any} transaction
 * @returns {Promise<void>}
 */
export async function mergeStats(storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, newData, merger = (newQuiz, oldQuiz) => newQuiz, {transaction} = {}) {

  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }
  try {
    const ids = newData.map(x => x.id_domanda);

    // Prendo tutte le statistiche da aggiornare

    const stats = (await getQuizzesByIds(storeName, id_concorso, ids, {transaction: tx})).filter(x => x);
    /* DEPRECATED: invece di usare getQuizzes che cerca usando un cursore, prendiamo direttamente tutte le domande visto
     * che conosciamo gli id
     */
    // const stats = await getQuizzes(id_concorso, x => ids.includes(x.id_domanda), {transaction: tx});
    const idsToUpdate = stats.map(x => x.id_domanda);

    // Uso merger per aggiornare le statistiche
    const updatedStats = stats.map(q => merger(_.find(newData, {id_domanda: q.id_domanda}), q));

    // Prendo da newData le nuove statistiche (non già presenti nel db)
    const [_newStats, restStats] = _.partition(newData, x => idsToUpdate.includes(x.id_domanda));
    if (Array.isArray(restStats) && restStats.length > 0) {
      // Errore: ci sono state ritornate statistiche relative a quiz sconosciuti
      console.error("Il servizio ritorna quiz non presenti nel db")
    }

    // Rimetto le nuove statistiche nel db
    const statsStore = tx.objectStore(storeName);
    await Promise.all(
      updatedStats.map(x => statsStore.put(x))
    );
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

export async function putQuizzes(storeName = CONSTANTS.STATISTICHE_STORE, newData, {transaction} = {}) {
  let tx = transaction;
  let dbToClose = false;
  if (!transaction) {
    const db = await getConcorsiDbPromise();
    dbToClose = db;
    tx = db.transaction([storeName], 'readwrite');
  }

  try {
    const statsStore = tx.objectStore(storeName);

    await Promise.all(newData.map(s => statsStore.put(s)));
  }
  finally {
    if (dbToClose !== false) {
      dbToClose.close();
    }
  }
}

/**
 * Aggiorna i metadati nelle statistiche.
 *
 * NOTA: le occorrenze di CONSTANTS.* non sono parametrizzate perché questo metodo può essere chiamato solo
 * sui concorsi, non è possibile per una banca dati non avere le risposte esatte.
 * @param id_concorso
 * @returns {Promise<void>}
 */
export async function updateConcorsoAssegnate(id_concorso) {
  const db = await getConcorsiDbPromise();

  try {
    /**
     * @type {ConcorsoMeta}
     */
    const meta = await db.get(CONSTANTS.CONCORSI_META, id_concorso)

    // Se il concorso non ha domande assegnate, non dobbiamo fare niente
    if(meta.n_domande === meta.n_domande_con_risposta) {
      return;
    }

    const allStats = await getAllQuizzes(CONSTANTS.STATISTICHE_STORE, id_concorso);
    meta.n_domande_assegnate = allStats
      .filter(x => x.has_risposta === 1 && x.risposta_gettonata)
      .length;

    meta.n_domande_by_materia = _.chain(allStats)
      .filter(x => x.has_risposta === 1)
      .groupBy("id_materia")
      .mapValues(v => Array.isArray(v) && v.length)
      .value();

    meta.n_domande_assegnate_by_materia =
      _.chain(allStats)
        .filter(x => x.has_risposta === 1 && x.risposta_gettonata)
        .groupBy("id_materia")
        .mapValues(v => Array.isArray(v) && v.length)
        .value();

    await db.put(CONSTANTS.CONCORSI_META, meta);
  } finally {
    db.close()
  }
}

export async function clearDb() {
  return deleteDB(CONSTANTS.CONCORSI_DB)
}
