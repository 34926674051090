/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The SottoPercorsoVO model module.
* @module model/SottoPercorsoVO
* @version 2.0.0
*/
export default class SottoPercorsoVO {
    /**
    * Constructs a new <code>SottoPercorsoVO</code>.
    * @alias module:model/SottoPercorsoVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SottoPercorsoVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SottoPercorsoVO} obj Optional instance to populate.
    * @return {module:model/SottoPercorsoVO} The populated <code>SottoPercorsoVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SottoPercorsoVO();
                        
            
            if (data.hasOwnProperty('id_sotto_percorso')) {
                obj['id_sotto_percorso'] = ApiClient.convertToType(data['id_sotto_percorso'], 'String');
            }
            if (data.hasOwnProperty('nome')) {
                obj['nome'] = ApiClient.convertToType(data['nome'], 'String');
            }
            if (data.hasOwnProperty('sotto_percorsi')) {
                obj['sotto_percorsi'] = ApiClient.convertToType(data['sotto_percorsi'], {'String': SottoPercorsoVO});
            }
        }
        return obj;
    }

    /**
    * @member {String} id_sotto_percorso
    */
    'id_sotto_percorso' = undefined;
    /**
    * @member {String} nome
    */
    'nome' = undefined;
    /**
    * @member {Object.<String, module:model/SottoPercorsoVO>} sotto_percorsi
    */
    'sotto_percorsi' = undefined;




}
