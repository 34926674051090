export const CONSTANTS = Object.freeze({
  CONCORSI_DB: 'concorsi',
  CONCORSI_STORE: 'concorsi-store',
  CONCORSI_KEY: 'id_concorso',
  CONCORSI_MATERIA_KEY: "id_materia",
  CONCORSI_META: 'concorsi-meta',
  CONCORSI_DATA_KEY: 'data',
  CONCORSI_DEFAULT_SORT_KEY: 'indice_uff',
  STATISTICHE_STORE: 'statistiche-store',
  PERCORSI_STORE: 'percorsi-store',
  PERCORSI_META: 'percorsi-meta',
  PERCORSI_STATISTICHE_STORE: 'percorsi-statistiche-store',
  PERCORSI_KEY: 'id_argomento',
  PERCORSI_MATERIA_KEY: "id_sotto_percorso",
  PERCORSI_DEFAULT_SORT_KEY: 'id',
  STATISTICHE_PRIMARY_KEY: "id_domanda",
})

export const CONCORSO_OR_PERCORSO = Object.freeze({
  CONCORSO: "CONCORSO",
  PERCORSO: "PERCORSO",
})

export const DBIndexesEnum = Object.freeze({
  BY_CONCORSO: "by_concorso",
  BY_DOMANDA: "by_domanda",
  BY_CONCORSO_MATERIA_LIVELLO: "by_concorso_materia_livello",
  BY_CONCORSO_INDICE: "by_concorso_indice",
  BY_CONCORSO_SINCRONIZZATA: "by_concorso_sincronizzata",
  BY_CONCORSO_HAS_RISPOSTA_INDICE: "by_concorso_has_risposta_indice",
  BY_CONCORSO_HAS_RISPOSTA_INDICE_NEW: "by_concorso_has_risposta_indice_new",
})

export const QuizOrderEnum = Object.freeze({
  ALFABETICO: 'ALFABETICO',
  CASUALE: 'CASUALE',
  UFFICIALE: 'UFFICIALE',
})

export const AnswerOrderEnum = Object.freeze({
  CASUALE: 'CASUALE',
  ESATTE: 'ESATTE',
  UFFICIALE: 'UFFICIALE',
})

export const SearchModeEnum = Object.freeze({
  ANY: 'ANY',
  ALL: 'ALL',
})

/**
 * Enum dei livelli di conoscenza
 * @readonly
 * @enum {number}
 */
export const LivelliConoscenzaEnum = Object.freeze({
  NON_RISPOSTE: -99,
  NON_LE_SAI: -2,
  RIPETILE: -1,
  ANCORA_UN_PICCOLO_SFORZO: 0,
  LE_SAI: 1,
})

export const EsercitazioneEnum = Object.freeze({
  ESERCITAZIONE: "ESERCITAZIONE",
  PROVA_PERSONALIZZATA: "PROVA_PERSONALIZZATA",
  SIMULAZIONE: "SIMULAZIONE",
  RIVEDI_PROVA: "RIVEDI_PROVA",
  RIEPILOGO: "RIEPILOGO",
  ASSEGNA_RISPOSTE: "ASSEGNA_RISPOSTE",
})

export const ModalitaEsercitazioneEnum = Object.freeze({
  ESEGUITA: "ESEGUITA",
  LETTA: "LETTA",
  ASCOLTATA: "ASCOLTATA",
})

/**
 * Usato per sapere velocemente se un numero è un livello valido
 */
const AdmissibileLivelliConoscenzaEnum = {};
Object.values(LivelliConoscenzaEnum).forEach(v => {AdmissibileLivelliConoscenzaEnum[v] = true});
export {AdmissibileLivelliConoscenzaEnum};

export const API_MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZZ";

export const SOTTO_ARGOMENTI_MAX_DEPTH = 7;
