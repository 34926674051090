import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import * as ConcorsandoApi from "./lib/ConcorsandoApi"
import store from './store/index'
import ImagesClient from "./lib/ConcorsandoWasm/ImagesClient";
import CustomClient from "@/lib/CustomConcorsandoClient/api/CustomClient";
import BandiClient from "@/lib/CustomConcorsandoClient/api/BandiClient";
import RuntimeConfig from "@/helpers/RuntimeConfig";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import {setupCookieBot} from "@/setupCookieBot";
import BrevoClient from "./lib/CustomConcorsandoClient/api/BrevoClient";

const defaultClient = ConcorsandoApi.ApiClient.instance;
const imagesClient = ImagesClient.instance;
const customClient = CustomClient.instance;
const bandiClient = BandiClient.instance;
const runtimeConfig = RuntimeConfig.instance;
const defaultClientBrevo = BrevoClient.instance

if (process.env.VUE_APP_BACKEND_URL) {
  defaultClient.basePath = process.env.VUE_APP_BACKEND_URL
  imagesClient.basePath = process.env.VUE_APP_IMAGES_BASE_PATH
  customClient.basePath = process.env.VUE_APP_SEGNALAZIONE_BASE_PATH
  bandiClient.basePath = process.env.VUE_APP_BANDI_BASE_PATH
  runtimeConfig.appleRedirectUrl = process.env.VUE_APP_APPLE_REDIRECT_URL
  runtimeConfig.helloJSRedirectUrl = process.env.VUE_APP_HELLOJS_REDIRECT_URL
  runtimeConfig.paypalClientId = process.env.VUE_APP_PAYPAL_CLIENT_ID
  runtimeConfig.reCaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
  defaultClientBrevo.basePath = process.env.VUE_APP_BACKEND_BREVO_URL
}
else if (process.env.NODE_ENV === "development") {
  defaultClient.basePath = 'https://se-concorsando.it/concorsando.it:443/ws/api'
  imagesClient.basePath = 'https://se-concorsando.it/concorsando.it:443/files/q_pics'
  customClient.basePath = 'https://se-concorsando.it/concorsando.it:443/api/v4'
  bandiClient.basePath = 'https://se-concorsando.it/concorsando.it:443/blog/wp-json/eventon/events'
  runtimeConfig.appleRedirectUrl = "https://simulatoreweb.southengineering.it/simulatore/auth"
  runtimeConfig.helloJSRedirectUrl = "/simulatore/auth/"
  runtimeConfig.paypalClientId = "AUtkSY6FSE2hivwQxqLNKY31n9bbmocpIvZhNG07Su6vAVXomRxHNPsmKK1bIo2dinkbLz_Nrg0RlfEP"
  // Chiave di sviluppo dalla documentazione di google: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
  runtimeConfig.reCaptchaSiteKey = "6Ld5vRAaAAAAAHBh6MgT0EZR6gOP9Nz8zBEliVif"
  defaultClientBrevo.basePath = "https://api.brevo.com/v3"

}

if (process.env.VUE_APP_ORIGIN) {
  defaultClient.defaultHeaders['x-requested-with'] = process.env.VUE_APP_ORIGIN
  imagesClient.defaultHeaders['x-requested-with'] = process.env.VUE_APP_ORIGIN
  customClient.defaultHeaders['x-requested-with'] = process.env.VUE_APP_ORIGIN
  bandiClient.defaultHeaders['x-requested-with'] = process.env.VUE_APP_ORIGIN
} else if (process.env.NODE_ENV === "development") {
  defaultClient.defaultHeaders['x-requested-with'] = 'https://concorsando.it'
  imagesClient.defaultHeaders['x-requested-with'] = 'https://concorsando.it'
  customClient.defaultHeaders['x-requested-with'] = 'https://concorsando.it'
  bandiClient.defaultHeaders['x-requested-with'] = 'https://concorsando.it'
}

const tokenApi = store.getters['auth/token_api'];
if (tokenApi && tokenApi !== "") {
  const Baerer = defaultClient.authentications['Baerer'];
  Baerer.apiKey = tokenApi;
  Baerer.apiKeyPrefix = 'Bearer';
}

let firebaseConfig;
switch(process.env.VUE_APP_FIREBASE_CONFIG) {
  case "prod":
    firebaseConfig = {
      apiKey: "AIzaSyC1gzPeyWdFYi01ngmHOAfp2cojO61bwWk",
      authDomain: "concorsando-test.firebaseapp.com",
      databaseURL: "https://concorsando-test.firebaseio.com",
      projectId: "concorsando-test",
      storageBucket: "concorsando-test.appspot.com",
      messagingSenderId: "582664110079",
      appId: "1:582664110079:web:e0afd8877053be39aef1ca",
      measurementId: "G-WGGSNWQ0MN"
    };
    break;
  case "se-demo":
  default:
    firebaseConfig = {
      apiKey: "AIzaSyBVuI3eEK_-T4qZtB_DyA7dkj4zpIjfJyM",
      authDomain: "concsouthtest.firebaseapp.com",
      projectId: "concsouthtest",
      storageBucket: "concsouthtest.appspot.com",
      messagingSenderId: "703915151543",
      appId: "1:703915151543:web:62094dfd22eae65b98bd32",
      measurementId: "G-MQY3E46WC5",
    };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// By default, analytics is disabled
firebase.analytics().setAnalyticsCollectionEnabled(false);

Vue.use(Vuelidate)

Vue.config.productionTip = false

Vue.prototype.$analytics = firebase.analytics();

setupCookieBot({firebase});

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
