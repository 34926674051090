import _ from 'lodash'

export const PATH_ACTIONS = Object.freeze({
    GET_PATHS: "getPaths",
    GET_USER_PATHS: "getUserPaths",
    DELETE_USER_PATH: "deleteUserPath",
    SET_SELECTED_PATH: "setSelectedPath",
    GET_PATH: "getPath",
    CLEAR_PATHS: "clearPaths",
    UPDATE_SELECTED_PATH_BY_INDICE: "updateSelectedPathByIndice",
});

export const PATH_ACTIONS_DISPATCH = _.mapValues(PATH_ACTIONS, v => `path/${v}`);
