/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Sorgente from './Sorgente';

/**
* The EsitoSimulazioneVO model module.
* @module model/EsitoSimulazioneVO
* @version 2.0.0
*/
export default class EsitoSimulazioneVO {
    /**
    * Constructs a new <code>EsitoSimulazioneVO</code>.
    * @alias module:model/EsitoSimulazioneVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>EsitoSimulazioneVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/EsitoSimulazioneVO} obj Optional instance to populate.
    * @return {module:model/EsitoSimulazioneVO} The populated <code>EsitoSimulazioneVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EsitoSimulazioneVO();
                        
            
            if (data.hasOwnProperty('id_simulazione')) {
                obj['id_simulazione'] = ApiClient.convertToType(data['id_simulazione'], 'String');
            }
            if (data.hasOwnProperty('data_simulazione')) {
                obj['data_simulazione'] = ApiClient.convertToType(data['data_simulazione'], 'Date');
            }
            if (data.hasOwnProperty('numero_domande_previste')) {
                obj['numero_domande_previste'] = ApiClient.convertToType(data['numero_domande_previste'], 'Number');
            }
            if (data.hasOwnProperty('numero_risposte_corrette')) {
                obj['numero_risposte_corrette'] = ApiClient.convertToType(data['numero_risposte_corrette'], 'Number');
            }
            if (data.hasOwnProperty('numero_risposte_errate')) {
                obj['numero_risposte_errate'] = ApiClient.convertToType(data['numero_risposte_errate'], 'Number');
            }
            if (data.hasOwnProperty('sorgente')) {
                obj['sorgente'] = Sorgente.constructFromObject(data['sorgente']);
            }
            if (data.hasOwnProperty('consegnata')) {
                obj['consegnata'] = ApiClient.convertToType(data['consegnata'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} id_simulazione
    */
    'id_simulazione' = undefined;
    /**
    * @member {Date} data_simulazione
    */
    'data_simulazione' = undefined;
    /**
    * @member {Number} numero_domande_previste
    */
    'numero_domande_previste' = undefined;
    /**
    * @member {Number} numero_risposte_corrette
    */
    'numero_risposte_corrette' = undefined;
    /**
    * @member {Number} numero_risposte_errate
    */
    'numero_risposte_errate' = undefined;
    /**
    * @member {module:model/Sorgente} sorgente
    */
    'sorgente' = undefined;
    /**
    * @member {Boolean} consegnata
    */
    'consegnata' = undefined;




}
