import _ from 'lodash';
import WrappedWorker from './WrappedWorker';
import {AdmissibileLivelliConoscenzaEnum, CONSTANTS, DBIndexesEnum, LivelliConoscenzaEnum} from "./Constants";
import * as DBOperations from "./DBOperations";
import * as utils from '../utils';
import {
  formatDateForApi,
  getCursorBySync,
  matchStatsToSync,
  rispostaVOToStats,
  statsToRispostaVO,
  updateLivelloConoscenza,
  updateStatsAfterPush
} from '../utils';
import * as ConcorsandoApi from '../ConcorsandoApi';
import {Errors} from "./Messages";
import CustomReportisticaApi from "../CustomConcorsandoClient/api/CustomReportisticaApi";
import ReportisticaApi from "../ConcorsandoApi/api/ReportisticaApi";

/**
 * ## StatsManager
 *
 * Si occupa di effettuare il salvataggio e la modifica delle richieste nel database locale, e di sincronizzare
 * il database locale con quello remoto.
 *
 * Metodi:
 *  - getStatsByFilter
 *  - resetStatsRemote
 *  - syncStats
 *  - getCompletamento
 *
 * ---
 *
 * @example
 * ```
 * const statsManager = StatsManager.instance;
 *
 * const stats = await statsManager.getStatsByFilter(id_concorso, {search: ["pippo"]})
 * const non_le_sai = stats[LivelloConoscenzaEnum.NON_LE_SAI];
 * const all_stats = stats.tot;
 * const has_images_non_le_sai = stats.has_images[LivelloConoscenzaEnum.NON_LE_SAI];
 * const has_images_all = stats.has_images.tot;
 * ```
 */
export default class StatsManager {

  constructor(storeName = CONSTANTS.STATISTICHE_STORE, materiaKey = CONSTANTS.CONCORSI_MATERIA_KEY, metaStore = CONSTANTS.CONCORSI_META, workerInstance = WrappedWorker.instance) {
    this.searchResultLevelsMap = {};
    this.id_concorso = undefined; // Usato come check di coerenza con WrappedWorker
    this.backofficeApi = new ConcorsandoApi.BackofficeApi();
    this.customReportisticaApi = new CustomReportisticaApi();
    this.reportisticaApi = new ReportisticaApi();
    this.storeName = storeName;
    this.materiaKey = materiaKey;
    this.metaStore = metaStore;


    this.workerInstance = workerInstance;
  }

  /* METODI UTILITY CHE VARIANO FRA BANCHE DATI E MATERIE  */

  makeMatchMateria(id_materie) {
    let materie = Array.isArray(id_materie) ? id_materie : [];
    function matchMateria(id_materia) {
      if (materie.length > 0) {
        return materie.includes(id_materia);
      } else {
        return true;
      }
    }

    return matchMateria;
  }

  getStatsFromBE(id_concorso, initial_date) {
    return new Promise((resolve, reject) =>
      this.reportisticaApi.getRisposteConcorso(
        {
          modalita: "ESEGUITA",
          idConcorso: id_concorso,
          ...initial_date
        },
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        })
    );
  }

  async sendStatsToBE(body, incrementa_contatore) {
    return this.customReportisticaApi.risposteConcorsoAsync(body, incrementa_contatore)
  }

  async cancellaReportistica(id_concorso, id_materia) {
    const idMateriaArg = id_materia ? {idMateria: id_materia} : {}
    return new Promise((resolve, reject) => {
      this.backofficeApi.cancellaReportistica({
        idConcorso: id_concorso,
        ...idMateriaArg
      }, (err, data) => {
        if (err == null) {
          resolve(data);
        } else {
          reject(err);
        }
      })
    });
  }

  getCompletamentoByMateria(id_materie, by_materia, non_risposte, nonRisposteByMateria) {
    let res = {by_materia: {}};

    id_materie.forEach((id_materia, i) => {
      const tot = by_materia[id_materia] || 0;
      if (_.isFinite(tot) && tot > 0) {
        const risposte = tot - non_risposte[i]
        res.by_materia[id_materia] = utils.percentage(risposte, tot);
      }
    });
    return res;
  }

  getIdMaterie(by_materia) {
    return Object.keys(by_materia);
  }

  async postSyncStats(id_concorso) {
    // Facciamo questa azione solo se stiamo lavorando con i concorsi
    // Facendo il controllo qui evitiamo di dover fare override nelle classi che ereditano da questa
    if (this.storeName === CONSTANTS.STATISTICHE_STORE) {
      return this.workerInstance.updateConcorsoAssegnate(id_concorso)
    }
  }

  /**
   *
   * @returns {StatsDomanda}
   */
  statsMerger(newQuiz, oldQuiz, forceMerge = false) {
    const newDate = _.get(newQuiz, "data_eseguita");
    const oldDate = _.get(oldQuiz, "data_eseguita");
    /* Se la data del nuovo quiz è successiva a quella del vecchio quiz (se esiste), facciamo il merge
     * caso 1: newDate e oldDate esistono e newDate > oldDate -> merge
     * caso 2: newDate esiste e oldDate no -> merge
     * caso 3: teniamoci quello vecchio
     */

    // L'elenco dei campi da mantenere nelle statistiche locali. In pratica tutti quelli che non riguardano lo stato
    // di completamento

    const fieldsToKeep = [
      "id_concorso",
      "id_domanda",
      "id_materia",
      "id_argomento",
      "id_sotto_percorso",
      "indice_uff",
      "has_image",
      "has_risposta",
    ]

    const isOldQuizSyncronized = _.get(oldQuiz, "sincronizzata");
    /* Vince il quiz remoto se una delle seguenti è vera:
     *  - `forceMerge === true`: è stato chiesta una sincronizzazione forzata
     *  - `isOldQuizSyncronized === 1`: il quiz locale è già stato sincronizzato, quindi qualunque cosa ritorni il backend è più aggiornata
     *  - `isOldQuizSyncronized === undefined`: il quiz locale non è mai stato visto dall'utente
     *  - `newDate && (!oldDate || newDate > oldDate)`: la data del quiz remoto esiste ed è posteriore a quella del quiz locale (se quest'ultima è presente)
     */
    if (forceMerge || isOldQuizSyncronized === 1 || isOldQuizSyncronized === undefined || (newDate && (!oldDate || newDate > oldDate))) {
      const hasRisposta = newQuiz.has_risposta ? {has_risposta: newQuiz.has_risposta}: {};
      return {
        ...newQuiz,
        ..._.pick(oldQuiz, fieldsToKeep),
        ...hasRisposta
      }
    }
    // Ritorniamo il quiz locale, in questo caso ci assicuriamo che sincronizzata sia === 0, in modo che il quiz locale
    // venga inviato al BE nella fase successiva.
    return {
      ...oldQuiz,
      sincronizzata: 0,
    };
  }

  /**
   * @returns {number}
   */
  generateNonce() {
    this.nonce = +Date.now();
    return this.nonce;
  }

  getSortedStats(stats) {
    return _.sortBy(stats, x => {
      const y = Number(x.id_domanda);
      return isNaN(y) ? Infinity : y;
    })
  }

  /* FINE UTILITY */

  /**
   * @typedef {object} StatsQuery
   * @property {Array<String>} id_materie - id delle domande
   * @property {Array<String>} search - array di parole da cercare
   * @property {boolean} [preferiti] - seleziona se limitare la ricerca ai preferiti
   * @property {boolean} [commento] - seleziona se limitare la ricerca ai quiz con spiegazione
   * @property {'ANY' | 'ALL'} [search_mode] - modalità di ricerca (default: `'ANY'`)
   * @property {number} [ix_start] - inizio blocco (da `0` al totale di domande `- 1`)
   * @property {number} [ix_end] - fine blocco (da `0` al totale di domande `-1`)
   */

  /**
   * @typedef {Object} AggregatedStats
   *
   * Per ogni livello di conoscenza (`src/lib/ConcorsandoWasm/Constants/LivelliConoscenzaEnum`) ha una chiave numerica
   * con il numero di domande per quel livello di conoscenza.
   *
   * @property {number} tot
   * @property {number} preferiti
   * @property {Object<number | 'tot', boolean>} has_images
   */

  /**
   * Ricerca nel database locale le statistiche. Ritorna un oggetto `{[key]: val}` dove `key` è uno dei seguenti:
   *  - il livello conoscenza secondo l'enum `LivelliConoscenzaEnum` (in `src/lib/ConcorsandoWasm/Constants.js`)
   *  - `"preferiti"` - indica le domande contrassegnate
   *  - `"tot"` - il totale (ignorando i preferiti)
   *  - `"has_images"` - una mappa che accetta come chiave un livello conoscenza o `"tot"` e assume valore `true` se ha
   * mentre `val` è il numero di domande nel livello corrispondente o preferite.
   *
   * ---
   * @example
   *
   * ```
   * const stats = await getStatsByFilter("id_concorso", {}) // oggetto di tipo AggregatedStats
   * const non_le_sai = stats[LivelloConoscenzaEnum.NON_LE_SAI];
   * const all_stats = stats.tot;
   * const has_images_non_le_sai = stats.has_images[LivelloConoscenzaEnum.NON_LE_SAI];
   * const has_images_all = stats.has_images.tot;
   * ```
   *
   * @param {string} id_concorso
   * @param {StatsQuery} query
   * @returns {Promise<AggregatedStats>}
   */
  async getStatsByFilter(id_concorso, query = {}) {
    /* PLAN:
     *  1) get search results from webworker
     *  2) open a cursor for id_concorso, ordered by indice_uff
     *  3) select from the cursor the elements having the correct id_materia
     *  4) from these, get the ones going from index ix_start to ix_end
     *  5) from these, select them based on:
     *    5.1) preferiti
     *    5.2) key in search results from before
     */

    // Reset the search results
    this.searchResultLevelsMap = {};
    this.id_concorso = id_concorso;

    const nonce = this.generateNonce();

    await this.initializeConcorsoInWorker(id_concorso);

    // STEP 1: perform search by words with the webworker here, since the next steps are in a transaction
    let searchMap = {};
    let doSearchMatch = false;

    let searchRes = [];
    if ((Array.isArray(query.search) && query.search.length > 0) || !!query.commento) {
      searchRes = await this.workerInstance.searchDomande(id_concorso, Array.isArray(query.search) ? query.search : [], query.search_mode === "ALL", !!query.commento);
      if (Array.isArray(searchRes)) {
        searchRes.forEach(k => searchMap[this.workerInstance.getStatsId(k.id)] = true);
      }
      doSearchMatch = true;
    }

    // Define function to check that a row actually match
    const matchMateria = this.makeMatchMateria(query.id_materie);

    const db = await DBOperations.getConcorsiDbPromise();

    try {
      const ix_start = _.get(query, "ix_start", 0);
      const ix_end = _.get(query, "ix_end", Infinity);

      const tx = db.transaction(this.storeName, "readonly");

      // STEP 2: get the cursor
      const ix_concorso = tx.objectStore(this.storeName).index(DBIndexesEnum.BY_CONCORSO_HAS_RISPOSTA_INDICE_NEW);

      const allStatsFromDB = await ix_concorso.getAll(IDBKeyRange.bound([id_concorso, 1, ""], [id_concorso, 1, "\uffff"]))
      const allStats = this.getSortedStats(allStatsFromDB);

      /**
       * Una Map che contiene gli id delle domande da tenerci
       * @type {Object.<string, boolean>}
       */
      let filterResultMap = {};

      let run = ix_start <= ix_end,
        cur = 0, // Keeps track of the elements matching STEP 3
        i = 0,
        res = StatsManager.buildEmptyStats();


      // This object will contain the local level map
      const searchResultLevelsMap = {};
      while (run && cur <= ix_end && allStats[i]) {
        /** @type {StatsDomanda} */
        let quizStats = allStats[i];
        const id_materia = _.get(quizStats, this.materiaKey);
        // STEP 3: match by materia. Here we must increase cur
        if (matchMateria(id_materia)) {

          // STEP 4: match ix_start and ix_end. It is not necessary to check for cur <= ix_end since it is already
          //         checked for in the while.
          if (cur >= ix_start) {
            // STEP 5

            let match = true;
            if (doSearchMatch) {
              match = match && searchMap[quizStats[CONSTANTS.STATISTICHE_PRIMARY_KEY]];
            }
            if (query.preferiti) {
              match = match && quizStats.preferiti === 1;
            }

            if (match) {
              searchResultLevelsMap[quizStats.id_domanda] = quizStats.livello_conoscenza;
              filterResultMap[quizStats.id_domanda] = true;
              if (AdmissibileLivelliConoscenzaEnum[quizStats.livello_conoscenza]) {
                res[quizStats.livello_conoscenza] = res[quizStats.livello_conoscenza] + 1;

                if (quizStats.has_image && !res.has_images[quizStats.livello_conoscenza]) {
                  res.has_images[quizStats.livello_conoscenza] = true;
                }
              }
            }
          }
          cur++;

        }
        i++;
      }

      // Write to the variables only if this is the correct nonce
      if (nonce === this.nonce) {
        this.searchResult = doSearchMatch
          ? searchRes.filter(x => filterResultMap[this.workerInstance.getStatsId(x.id)])
          : undefined;
        this.searchResultLevelsMap = searchResultLevelsMap;
      }

      // Compute the overall has_images property
      res.tot = _.sum(Object.values(LivelliConoscenzaEnum).map(x => res[x]));
      res.has_images.tot = Object.values(res.has_images).some(x => x);

      return res;
    }
    catch (e) {
      if (nonce === this.nonce) {
        this.searchResultLevelsMap = {};
        this.searchResult = [];
        this.id_concorso = undefined;
      }
      throw e;
    }
    finally {
      db.close();
    }
  }

  /**
   * Assicura che l'istanza di worker stia lavorando sull'id selezionato.
   * @param id_concorso
   * @returns {Promise<void>}
   */
  async initializeConcorsoInWorker(id_concorso) {
    // Initialize concorso in workerInstance if id_concorso has changed
    if (this.workerInstance.concorso !== id_concorso) {
      await this.workerInstance.initConcorso(id_concorso);
    }
  }

  /**
   * @typedef {Object} StatsCompletamento
   * @property {number} [tot] - la percentuale di completamento totale
   * @property {Object.<string, number>} [by_materia] - una Map con le percentuali di completamento per le materie
   */
  /**
   *
   * @param {string} id_concorso
   * @returns {Promise<StatsCompletamento>}
   */
  async getCompletamento(id_concorso) {
    const db = await DBOperations.getConcorsiDbPromise();

    try {
      const meta = await db.get(this.metaStore, id_concorso);

      /**
       * @type {Object.<string, number>}
       */
      const tx = db.transaction(this.storeName, "readonly");
      const by_materia = _.get(meta, "n_domande_by_materia", {});
      const id_materie = this.getIdMaterie(by_materia);

      const allStats = await this.getAllStats(id_concorso);
      const nonRisposteByMateria = _.chain(allStats)
        .filter(x => x.has_risposta === 1 && x.livello_conoscenza === LivelliConoscenzaEnum.NON_RISPOSTE)
        .groupBy(this.materiaKey)
        .mapValues(x => x.length)
        .value();

      const non_risposte = id_materie.map(id_materia => nonRisposteByMateria[id_materia] || 0)

      await tx.done;

      if (!meta) {
        return {};
      }

      let res = this.getCompletamentoByMateria(id_materie, by_materia, non_risposte, nonRisposteByMateria);

      const totaleDomande = (meta.n_domande_assegnate || 0) + (meta.n_domande_con_risposta || 0);
      if (_.isFinite(totaleDomande) && totaleDomande > 0) {
        const risposte = totaleDomande - _.sum(non_risposte);
        res.tot = utils.percentage(risposte, totaleDomande);
      }

      return res;
    }
    finally {
      db.close();
    }
  }

  /**
   * Effettua rimozione in locale delle statistiche. Ritorna una promessa.
   *
   * Il metodo è sovrascritto nel caso dei percorsi per evitare di chiamare updateConcorsoAssegate.
   * @param {String} id_concorso
   * @param {{id_materie: Array<String>}} query
   * @param {IDBTransaction} [transaction] (enhanced by idb) if no transaction is passed, one will be created.
   * @returns {Promise<void>}
   */
  async resetStats(id_concorso, query = {}, {transaction} = {}) {
    await DBOperations.resetAssegnateMeta(this.metaStore, id_concorso, query, {transaction});
    return await DBOperations.resetStats(this.storeName, id_concorso, query, {transaction});
  }

  /**
   * Aggiorna il livello di conoscenza e lo stato di preferito di un quiz. Il livello di conoscenza può essere aggiornato
   * passando l'argomento livello_conoscenza (se si conosce il livello di destinazione) o un booleano correct_answer (in
   * questo caso prenderemo il livello_conoscenza attuale e calcoleremo quello nuovo).
   *
   * Per aggiornare i preferiti passare l'argomento omonimo.
   *
   * La data viene aggiornata solo se è stato richiesto un cambio di livello conoscenza, solo in quest'ultimo caso
   * l'aggiornamneto verrà contato ai fini del livello dell'utente.
   *
   * NOTA: per l'utilizzo attuale del database, i quiz con livello_conoscenza -99 **NON** non tracciati in remoto,
   *  in particolare non sono oggetto delle sincronizzazioni. Quindi se questo metodo viene usato per cambiare il livello
   *  di conoscenza in -99, non triggererebbe la rimozione del quiz dal dalle statistiche remote. Qualora si volesse
   *  eliminare un quiz anche in remoto bisogna usare i metodi di reset.
   *
   * @param id_concorso
   * @param id_domanda
   * @param livello_conoscenza
   * @param preferiti
   * @param correct_answer
   * @returns {Promise<void>}
   */
  async updateQuizStats(id_concorso, id_domanda, {livello_conoscenza, preferiti, correct_answer, sessionQuizzes} = {}) {
    // Mettiamo l'ora attuale in una variabile
    const data_eseguita = new Date();

    const db = await DBOperations.getConcorsiDbPromise();

    try {


      // Get the quiz
      /**
       * @type {StatsDomanda}
       */
      const quiz = await db.getFromIndex(this.storeName, DBIndexesEnum.BY_DOMANDA, [id_concorso, id_domanda]);
      if (quiz) {
        // Aggiorniamo lo stato di preferiti del quiz
        if (_.isBoolean(preferiti)) {
          quiz.preferiti = preferiti ? 1 : 0;
          quiz.isPreferitiUpdated = true;
        }

        let isLevelUpdated = false;

        // Aggiorniamo il livello di conoscenza
        if (_.isBoolean(correct_answer)) {
          quiz.livello_conoscenza = updateLivelloConoscenza(quiz.livello_conoscenza, correct_answer ? 'inc' : 'dec');
          isLevelUpdated = true;
        }
        else if (livello_conoscenza !== undefined && AdmissibileLivelliConoscenzaEnum[livello_conoscenza]) {
          quiz.livello_conoscenza = livello_conoscenza;
          isLevelUpdated = true;
        }

        // Settiamo come non sincronizzato
        quiz.sincronizzata = 0;

        // Se non è stato cambiato niente, ritorniamo
        if (!isLevelUpdated && !quiz.isPreferitiUpdated) {
          return
        }

        // Salviamo la data
        quiz.data_eseguita = data_eseguita;

        if (isLevelUpdated) {
          quiz.isLevelUpdated = true;
        }

        // Salviamo in db
        await db.put(this.storeName, quiz);

        this.sendUpdatesToRemote(id_concorso, {sessionQuizzes}).catch(e => console.error("Sincronizzazione in remoto fallita per", e))
      }
    } finally {
      db.close();
    }
  }

  async setRispostaGettonata(id_concorso, id_domanda, {risposta_gettonata, sessionQuizzes} = {}) {
    const db = await DBOperations.getConcorsiDbPromise();

    try {


      // Get the quiz
      /**
       * @type {StatsDomanda}
       */
      const quiz = await db.getFromIndex(this.storeName, DBIndexesEnum.BY_DOMANDA, [id_concorso, id_domanda]);
      if (quiz) {
        quiz.risposta_gettonata = parseInt(risposta_gettonata);
        quiz.has_risposta = 1;
        quiz.data_eseguita = new Date();
        quiz.sincronizzata = 0;
        // Utilizziamo isPreferitiUpdated per indicare che la risposta è stata modificata ma non nel livello conoscenza
        quiz.isPreferitiUpdated = true;

        // Salviamo in db
        await db.put(this.storeName, quiz);

        // Aggiorniamo i metadati in un altro thread
        await this.workerInstance.updateConcorsoAssegnate(id_concorso).catch((e) => {
          console.log("Errore nell'aggiornamento delle risposte assegnate");
          console.error(e);
        });

        // Inviamo l'aggiornamento in remoto
        this.sendUpdatesToRemote(id_concorso, {sessionQuizzes}).catch(e => console.error("Sincronizzazione in remoto fallita per", e))
      }
    } finally {
      db.close();
    }

  }

  /**
   * Prende dal database locale tutte le statistiche relative al concorso id_concorso che non sono state sincronizzate
   * e le invia in remoto. Questo metodo è fatto per essere chiamato ogni volta che viene data una risposta a una domanda
   * o viene aggiunta nei preferiti (cioé dopo aver chiamato `updateQuizStats`
   *
   * Le domande inviate in remoto sono:
   *
   *  - tutte quelle a cui l'utente ha dato almeno una risposta, con livello conoscenza diverso da NON_RISPOSTE
   *  - tutte quelle inserite nei preferiti, indipendentemente se sia stata data o meno una risposta
   *
   * @param {string} id_concorso
   * @param {function} matchUnsynchedParam
   * @param {string[]} sessionQuizzes
   * @returns {Promise<void>}
   */
  async sendUpdatesToRemote(id_concorso, {matchUnsynched: matchUnsynchedParam, sessionQuizzes} = {}) {
    const matchUnsynched = _.isFunction(matchUnsynchedParam)
      ? matchUnsynchedParam
      : matchStatsToSync;

    let statsToSync = await DBOperations.getQuizzes(this.storeName, id_concorso, matchUnsynched, {
      getCursor: tx => getCursorBySync(tx, this.storeName, id_concorso, 0)
    });
    if (Array.isArray(sessionQuizzes)) {
      statsToSync = statsToSync.filter(x => sessionQuizzes.includes(x.id_domanda))
    }


    let [dataWithIncrement, dataNoIncrement]= _.partition(statsToSync, x => _.get(x, "isLevelUpdated", false));

    const newDataWithIncrement = await (dataWithIncrement.length === 0
        ? Promise.resolve([])
        : this.sendStatsToBE(
          dataWithIncrement.map(x => statsToRispostaVO(x, y => this.workerInstance.getWasmId(y))),
          true
        )
          .then(() => dataWithIncrement.map(updateStatsAfterPush))
          .catch(() => [])
    );

    const newDataNoIncrement = await (dataNoIncrement.length === 0
        ? Promise.resolve([])
        : this.sendStatsToBE(
          dataNoIncrement.map(x => statsToRispostaVO(x, y => this.workerInstance.getWasmId(y))),
          false
        )
          .then(() => dataNoIncrement.map(updateStatsAfterPush))
          .catch(() => [])
    );

    if (newDataNoIncrement.length > 0 || newDataWithIncrement.length > 0) {
      await DBOperations.putQuizzes(this.storeName, [...newDataWithIncrement, ...newDataNoIncrement]);
    }
  }

  /**
   * Effettua rimozione in locale delle statistiche e, se `remote` è `true`, effettua anche rimozione in remoto.
   * Se la cancellazione dal db va in errore, rigetta con
   * {error: {code: Errors.INDEXEDDB_GENERIC_ERROR, origError: <Errore DB>}}
   * Se la richiesta al backend va in errore, rigetta con
   * {error: {code: Errors.REMOTE_REQUEST_FAILED, origError: <Errore API>}}
   * @param {String} id_concorso
   * @param {{id_materie: Array<String>}} query
   * @param {boolean} [remote] - seleziona se effettuare la rimozione anche in remoto
   * @returns {Promise<void>}
   */
  async resetStatsRemote(id_concorso, query = {}, {remote = false} = {}) {
    // Omit this call since we don't have a proper implementation
    try {
      await this.resetStats(id_concorso, query);
    }
    catch (e) {
      throw {
        error: {
          code: Errors.INDEXEDDB_GENERIC_ERROR,
          origError: e
        }
      }
    }

    const checkMaterie = query.id_materie && Array.isArray(query.id_materie) && query.id_materie.length > 0;
    if (remote) {
      let updatePromise;
      if (checkMaterie) {
        updatePromise = Promise.all(query.id_materie.map(id_materia =>
          this.cancellaReportistica(id_concorso, id_materia)
        ))
      }
      else {
        updatePromise = this.cancellaReportistica(id_concorso)
      }

      try {
        await updatePromise;
      }
      catch (e) {
        throw {
          error: {
            code: Errors.REMOTE_REQUEST_FAILED,
            origError: e
          }
        }
      }
    }
  }

  async getQuizStats(id_concorso, id_domanda) {
    const db = await DBOperations.getConcorsiDbPromise();

    try {


      // Get the quiz
      /**
       * @type {StatsDomanda}
       */
      return await db.getFromIndex(this.storeName, DBIndexesEnum.BY_DOMANDA, [id_concorso, id_domanda]);
    } finally {
      db.close();
    }
  }

  /**
   * Reexport the call to getAllStats from WrappedWorker
   * @param {string} id_concorso
   * @returns {Promise<Array<StatsDomanda>>}
   */
  async getAllStats(id_concorso) {
    return this.workerInstance.getAllStats(this.storeName, id_concorso);
  }

  /**
   * Prende in ingresso un riepilogo di simulazione come ritornato da GET /ms_reportistica/risposte_simulazione e ritorna
   * un RispostaRiepilogo[]. In pratica aggiunge id_materia alle risposte.
   * @param {string} id_concorso
   * @param {Array<RispostaVO>} riepilogo
   * @returns {Promise<RispostaRiepilogo[]>}
   */
  async enrichRiepilogo(id_concorso, riepilogo = []) {
    const db = await DBOperations.getConcorsiDbPromise();

    try {
      const domande = await this.workerInstance.getDomande(id_concorso);
      return await Promise.all(riepilogo
        .map(async (rispRiepilogo) => {
          const {id_domanda, id_simulazione, risposta} = rispRiepilogo;
          const rispostaObj = risposta != null ? {risposta: parseInt(risposta)} : {};
          const id_materia = (await this.getQuizStats(this.id_concorso, id_domanda)).id_materia;
          if (risposta) {
            const domanda = _.find(domande, {id: parseInt(id_domanda)});
            rispostaObj.isCorrectAnswer = _.get(domanda, "id_risposta_esatta") === String(risposta);
          }

          return {
            id_domanda: String(id_domanda),
            id_simulazione: String(id_simulazione),
            id_materia,
            ...rispostaObj
          };
        }));
    }
    finally {
      db.close();
    }
  }

  /**
   * Effettua la sincronizzazione iniziale delle statistiche. Se una delle richieste al backend va in errore,
   * rigetta con la risposta dell'API.
   * @param {String} id_concorso
   * @returns {Promise<void>}
   */
  async syncStats(id_concorso) {
    function matchEseguite(quiz) {
      const eseguita = _.get(quiz, "data_eseguita", false);
      return !!eseguita;
    }

    const concorsoInitPromise = this.initializeConcorsoInWorker(id_concorso);

    const statsToSync = await DBOperations.getQuizzes(this.storeName, id_concorso, matchStatsToSync, {
      getCursor: tx => getCursorBySync(tx, this.storeName, id_concorso, 0)
    });

    // Prendiamo la data da inviare al backend

    let initial_date = {};

    if (Array.isArray(statsToSync) && statsToSync.length > 0) {
      initial_date.data = formatDateForApi(_.chain(statsToSync)
        .sortBy(x => x.data_eseguita)
        .head()
        .get("data_eseguita")
        .value());
    }
    else {
      let lastQuiz;

      await DBOperations.forEachQuiz(this.storeName, id_concorso, matchEseguite, quiz => {
        const lastEseguita = _.get(lastQuiz, "data_eseguita");
        const curEseguita = quiz.data_eseguita;
        if (!lastEseguita || curEseguita > lastEseguita) {
          lastQuiz = quiz;
        }
      }, {
        getCursor: tx => getCursorBySync(tx, this.storeName, id_concorso, [0, 1])
      });

      if (lastQuiz) {
        initial_date.data = formatDateForApi(lastQuiz.data_eseguita)
      }
    }

    /**
     * @type {Array<RispostaVO>}
     */
    const beStats = await this.getStatsFromBE(id_concorso, initial_date)

    // Aspettiamo qui la promessa perché prima non ci serve
    await concorsoInitPromise;
    const defaultDate = new Date();
    /**
     * @type {Array<StatsDomanda>}
     */
    const newData = beStats
      .map(x => rispostaVOToStats(x, y => this.workerInstance.getStatsId(y)), defaultDate)
      .map(x => ({
        ...x,
        sincronizzata: 1
      }));

    await DBOperations.mergeStats(this.storeName, id_concorso, newData, (a, b) => this.statsMerger(a, b, false));

    await this.postSyncStats(id_concorso);

    await this.sendUpdatesToRemote(id_concorso);

  }

  /**
   * Forza sincronizzazione verso il backend
   * @param id_concorso
   * @param transaction
   * @returns {Promise<void>}
   */
  async forceSyncToRemote(id_concorso) {
    const allStats = await DBOperations.getQuizzes(this.storeName, id_concorso, () => true, {
      getCursor: tx => getCursorBySync(tx, this.storeName, id_concorso, [0, 1])
    });

    // Cancelliamo le statistiche in remoto
    await this.cancellaReportistica(id_concorso)

    const newData = await this.sendStatsToBE(
      allStats.map(x => statsToRispostaVO(x, y => this.workerInstance.getWasmId(y))),
      false
    )
      .then(() => allStats.map(updateStatsAfterPush));

    if (newData.length > 0) {
      await DBOperations.putQuizzes(this.storeName, newData);
    }
  }

  async forceSyncFromRemote(id_concorso) {
    /**
     * @type {Array<RispostaVO>}
     */
    const beStats = await this.getStatsFromBE(id_concorso, {})

    // Cancelliamo le statistiche in locale
    await this.resetStats(id_concorso);

    const defaultDate = new Date();
    /**
     * @type {Array<StatsDomanda>}
     */
    const newData = beStats
      .map(x => rispostaVOToStats(x, y => this.workerInstance.getStatsId(y)), defaultDate)
      .map(x => ({...x, sincronizzata: 1}));

    await DBOperations.mergeStats(this.storeName, id_concorso, newData, (a, b) => this.statsMerger(a, b, true));

    await this.postSyncStats(id_concorso);
  }

  /**
   * Singleton getter
   * @returns {StatsManager}
   */
  static get instance() {
    if (!this.statsManagerInstance) {
      this.statsManagerInstance = new StatsManager();
    }
    return this.statsManagerInstance;
  }

  /* UTILITY METHODS */
  /**
   * Returns an empty stats object
   */
  static buildEmptyStats() {
    return {
      [LivelliConoscenzaEnum.NON_RISPOSTE]: 0,
      [LivelliConoscenzaEnum.NON_LE_SAI]: 0,
      [LivelliConoscenzaEnum.RIPETILE]: 0,
      [LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO]: 0,
      [LivelliConoscenzaEnum.LE_SAI]: 0,
      has_images: {
        [LivelliConoscenzaEnum.NON_RISPOSTE]: false,
        [LivelliConoscenzaEnum.NON_LE_SAI]: false,
        [LivelliConoscenzaEnum.RIPETILE]: false,
        [LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO]: false,
        [LivelliConoscenzaEnum.LE_SAI]: false,
        tot: false,
      },
    };
  }
}
