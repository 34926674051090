import _ from 'lodash'

export const COMPETITION_ACTIONS = Object.freeze({
    GET_COMPETITIONS: "getCompetitions",
    GET_COMPETITION: "getCompetition",
    GET_USER_COMPETITIONS: "getUserCompetitions",
    DELETE_USER_COMPETITION: "deleteUserCompetition",
    GET_ENTITIES: "getEntities",
    CLEAR_COMPETITIONS_ENTITIES: "clearCompetitionsEntities",
    GET_ADDITIONAL_RESOURCES: "getAdditionalResources",
    SET_SELECTED_COMPETITION: "setSelectedCompetition",
    GET_SIMULATIONS: "getSimulations",
    GET_SIMULATION: "getSimulation",
    GET_RANKING: "getRanking",
    GET_SIMULATION_RESULTS: "getSimulationResults",
    GET_SIMULATION_ANSWERS: "getSimulationAnswers",
    GET_EXPLANATION: 'getExplanation'
});

export const COMPETITION_ACTIONS_DISPATCH = _.mapValues(COMPETITION_ACTIONS, v => `competition/${v}`);