import {
  API_MOMENT_FORMAT,
  CONSTANTS,
  DBIndexesEnum,
  LivelliConoscenzaEnum,
  SOTTO_ARGOMENTI_MAX_DEPTH
} from "./ConcorsandoWasm/Constants";
import RispostaVO from "./ConcorsandoApi/model/RispostaVO";
import _ from 'lodash';
import moment from 'moment';

/**
 * Returns the percentage n/total as an integer
 * @param {number} n
 * @param {number} total
 */
export function percentage(n, total) {
  return Math.floor(n * 100 / total);
}

/**
 * Quick implementation of a function that takes a promiseMaker and retries it for a certain number of times
 *
 * @param {function} promiseMaker - the function that build our promise
 * @param {number} remaining
 * @param {number} [delay] the number of milliseconds to wait before retrying
 * @param {function} [isRetryable] check whether the error is retryable
 */
export async function retryPromise(promiseMaker, remaining = 3, delay = 0, isRetryable = () => true) {
  try {
    return await promiseMaker()
  } catch (e) {
    let doRetry = true;
    try {
      doRetry = isRetryable(e)
    } catch (e) {}
    if (remaining > 0 && doRetry) {
      return delayPromise(Promise.resolve(), delay).then(() => retryPromise(promiseMaker, remaining - 1))
    }
    else {
      throw e
    }
  }
}

export async function delayPromise(p, millis, skipTimeout = false) {
  if (millis <= 0 && skipTimeout) {
    return p;
  }
  return new Promise(resolve => setTimeout(() => resolve(p), millis));
}

export function isRequestErrorRetryable(err) {
  try {
    return !!err.response.status;
  } catch(e) {
    return false;
  }
}

/**
 * Dato un numero, ritorna un livello conoscenza ammissibile. In particolare se <= -99 è -99, se <= -1 è -1, se >=2 è 2
 * @param {number} livello_conoscenza
 * @returns {number}
 */
function normalizeLivelloConoscenza(livello_conoscenza) {
  if (livello_conoscenza <= LivelliConoscenzaEnum.NON_RISPOSTE) {
    return LivelliConoscenzaEnum.NON_RISPOSTE;
  }
  else if (livello_conoscenza <= LivelliConoscenzaEnum.NON_LE_SAI) {
    return LivelliConoscenzaEnum.NON_LE_SAI;
  }
  else if (livello_conoscenza >= LivelliConoscenzaEnum.LE_SAI) {
    return LivelliConoscenzaEnum.LE_SAI;
  }

  return livello_conoscenza;
}

/**
 * Incrementa o decrementa il livello conoscenza.
 *
 * Per NON_RISPOSTE questo vuol dire passare a RIPETILE o NON_LE_SAI
 *
 * @param {number} livello_conoscenza
 * @param {'inc' | 'dec'} op - increase or decrese
 * @returns {number}
 */
export function updateLivelloConoscenza(livello_conoscenza, op) {
  if (livello_conoscenza === LivelliConoscenzaEnum.NON_RISPOSTE) {
    if (op === 'inc') {
      return LivelliConoscenzaEnum.RIPETILE;
    } else {
      return LivelliConoscenzaEnum.NON_LE_SAI;
    }
  }
  else {
    return normalizeLivelloConoscenza(
      livello_conoscenza + (op === 'inc' ? 1 : -1)
    )
  }
}

/**
 * @param {StatsDomanda} data
 * @returns {RispostaVO}
 */
export function statsToRispostaVO(data, idTransform = x => x) {
  const dataFromStats = (_.pick(data, [
    "id_concorso",
    "id_domanda",
    "id_materia",
    "id_argomento",
    "id_sotto_percorso",
    "livello_conoscenza",
    "data_eseguita",
    "risposta_gettonata"
  ]));

  dataFromStats.id_domanda = idTransform(dataFromStats.id_domanda);

  // Calcoliamo id_sotto_percorso corretto
  if(dataFromStats.id_sotto_percorso) {
    const ids = dataFromStats.id_sotto_percorso
      .split(";")
      .filter(x => x !== undefined && x !== "");
    if (ids.length > 0) {
      dataFromStats.id_sotto_percorso = ids[ids.length - 1];
    }
    else {
      dataFromStats.id_sotto_percorso = "";
    }
  }

  return RispostaVO.constructFromObject({
    ...dataFromStats,
    preferiti: _.get(data, "preferiti") === 1,
    modalita: [_.get(data, "modalita")],
  })
}

/**
 * @param {RispostaVO} data
 * @returns {StatsDomanda}
 */
export function rispostaVOToStats(data, idTransform = x => x, defaultDate) {
  const curStats = _.pick(
    data,
    [
      "id_concorso",
      "id_materia",
      "id_domanda",
      "id_argomento",
      "id_sotto_percorso",
      "data_eseguita",
      "risposta_gettonata",
    ]
  );

  curStats.id_domanda = idTransform(curStats.id_domanda);

  // Gestiamo il caso in cui il backend ritorni un livello conoscenza null
  let livelloConoscenzaArg = _.get(data, "livello_conoscenza", LivelliConoscenzaEnum.NON_RISPOSTE);
  if (livelloConoscenzaArg === null) {
    livelloConoscenzaArg = LivelliConoscenzaEnum.NON_RISPOSTE;
  }
  curStats.livello_conoscenza = normalizeLivelloConoscenza(livelloConoscenzaArg);
  curStats.preferiti = _.get(data, "preferiti", false) ? 1 : 0;
  curStats.modalita = _.head(_.get(data, "modalita", ["ESEGUITA"]));
  if (curStats.risposta_gettonata) {
    curStats.has_risposta = 1;
  }
  if (curStats.data_eseguita == null) {
    curStats.data_eseguita = defaultDate || new Date();
  }

  return curStats;
}

/**
 *
 * @param {RispostaVO} risp
 * @returns {RispostaRiepilogo}
 */
export function rispostaVOToRispostaRiepilogo(risp = {}) {
  const {id_domanda, id_simulazione, risposta} = risp;
  const id_simulazioneArg = id_simulazione != null ? {id_simulazione: String(id_simulazione)} : {};
  const rispostaArg = _.isNumber(risposta) ? {risposta} : {};
  return {
    id_domanda: String(id_domanda),
    ...id_simulazioneArg,
    ...rispostaArg
  }
}

export function rispostaRiepilogoToRispostaVO(risp = {}) {
  return _.omit(risp, ["isCorrectAnswer"])
}

/**
 * Calcola la distanza in secondi fra il tempo corrente e la stringa passata in input.
 * @param {String} timeStr
 * @returns {number}
 */
export function getDistanceWith(timeStr) {
  const t = moment(timeStr);
  const time_now = moment();
  if (moment.isMoment(t)) {
    return Math.abs(time_now.diff(t, "seconds", true))
  }
}

/**
 * Callback to update the quiz after the request succeeded
 * @param {StatsDomanda} quiz
 * @returns {StatsDomanda}
 */
export function updateStatsAfterPush(quiz) {
  const newQuiz = _.omit(quiz, ["isPreferitiUpdated", "isLevelUpdated"]);
  newQuiz.sincronizzata = 1;
  return newQuiz;
}

export function matchStatsToSync(quiz) {
  const livello_conoscenza = _.get(quiz, "livello_conoscenza", LivelliConoscenzaEnum.NON_RISPOSTE);
  const sincronizzata = _.get(quiz, "sincronizzata", 0);
  const eseguita = _.get(quiz, "data_eseguita");
  const isPreferitiUpdated = _.get(quiz, "isPreferitiUpdated", false);

  return !sincronizzata && eseguita && (
    livello_conoscenza !== LivelliConoscenzaEnum.NON_RISPOSTE || isPreferitiUpdated
  )
}

export function getCursorBySync(transaction, storeName = CONSTANTS.STATISTICHE_STORE, id_concorso, sincronizzata = 0) {
  const statsStore = transaction.objectStore(storeName);
  const ix_concorso = statsStore.index(DBIndexesEnum.BY_CONCORSO_SINCRONIZZATA);
  let range;
  if (Array.isArray(sincronizzata)) {
    range = IDBKeyRange.bound(...sincronizzata.map(x => [id_concorso, x]));
  }
  else {
    range = IDBKeyRange.only([id_concorso, sincronizzata])
  }
  return ix_concorso.openCursor(range);
}

function _formatDateForApi(date_s) {
  if (!date_s) {
    throw new Error("Empty date_s argument");
  }
  const d = moment(date_s);

  if (d.isValid()) {
    return d.format(API_MOMENT_FORMAT);
  }
  else {
    throw new Error("Invalid date");
  }
}

export function formatDateForApi(date_s) {
  try {
    return _formatDateForApi(date_s)
  } catch (e) {
    console.error("Errore durante la formattazione della data", e);
    return date_s;
  }
}

/* Logica per mantenere in sessione lo stato di check dell'ora */
let isTimeCheckedState = false;
export function isTimeChecked() {
  return isTimeCheckedState;
}
export function setTimeChecked(val = true) {
  isTimeCheckedState = val;
}

export function promisify(fn, ...args) {
  return new Promise((resolve, reject) => fn(...args, (err, res) => {
    if (err) {
      reject(err);
    }
    else {
      resolve(res);
    }
  }))
}

export function computeIdDomandaConcorso(d) {
  return String(d.id);
}

export function computeIdDomandaPercorso(d) {
  return d.id_argomento + "-" + String(d.id);
}

/**
 * Dato un id di tipo "<sub1>;<sub2>;<sub3>[;etc]" ritorna gli id dei sottopercorsi separati dalla virgola tranne la parte
 * terminale di id vuoti. Ad esempio
 *
 * ```
 * trimSottoPercorsoId("1;2;3;;;;") === "1;2;3"
 * ```
 * @param id
 */
export function trimSottoPercorsoId(id) {
  const regex = /^(.*?);*$/gm;
  return id.replace(regex, "$1");
}


/**
 * Trasforma l'id di una materia nel formato utilizzato dalle statistiche. Per i percorsi bisogna aggiungere eventuali
 * ; mancanti.
 * @param {string} id_sotto_percorso
 * @param {boolean} trim rimuove eventuali id vuoti alla fine
 * @param {number} maxDepth profondità massima a cui arrivare
 * @returns {*}
 */
export function sanitizeSottoPercorsoId(id_sotto_percorso, {trim = false, maxDepth = SOTTO_ARGOMENTI_MAX_DEPTH} = {}) {
  let resultId = id_sotto_percorso
  let sotto_percorsi = id_sotto_percorso.split(";");
  if (sotto_percorsi.length !== maxDepth) {
    let sotto_percorsi_full = new Array(maxDepth);
    _.fill(sotto_percorsi_full, "");
    sotto_percorsi.slice(0, maxDepth).forEach((x, i) => {
      sotto_percorsi_full[i] = x;
    })
    resultId = sotto_percorsi_full.join(";");
  }

  if (trim) {
    resultId = trimSottoPercorsoId(resultId)
  }

  return resultId;
}

/**
 * Controlla se id_sotto_percorso un sotto percorso di id
 * @param {string} id
 * @param {string} id_sotto_percorso
 * @returns {boolean}
 */
export function includesSottoPercorso(id, id_sotto_percorso) {
  let id_trimmed = sanitizeSottoPercorsoId(id, {trim: true});
  let id_len = id_trimmed.split(";").length;
  let id_sotto_percorso_trimmed = sanitizeSottoPercorsoId(id_sotto_percorso, {maxDepth: id_len});

  return id_trimmed === id_sotto_percorso_trimmed;
}

/**
 *
 * @param {string} key
 * @returns {string[]}
 * @private
 */
function _ensureAllLevels(key) {
  const stripped = sanitizeSottoPercorsoId(key, {trim: true});
  const levelIds = stripped.split(";");
  const joined = levelIds.map((_x, i) => levelIds.slice(0,i + 1).join(";"));
  return joined.map(j => sanitizeSottoPercorsoId(j))
}

/**
 * Dato un array di chiavi di sottoargomenti, aggiunge nell'array le chiavi di primo livello se non ci sono.
 * @param {string[]} keys
 * @return {string[]}
 */
export function ensureAllLevels(keys) {
  const allLevels = keys.flatMap(k => _ensureAllLevels(k));
  return _.uniq(_.union(keys, allLevels));
}

/**
 * @param {Object.<string, number>} data
 * @return {Object.<string, number>}
 */
export function getCumulativeSum(data) {
  const keysOrig = Object.keys(data);
  const keys = ensureAllLevels(keysOrig);

  const entries = Object.entries(data);
  const cumulative = keys.map(
    k =>
      ({
        [k]: _.chain(entries)
          .filter(([x, _y]) => includesSottoPercorso(k, x))
          .map(([_x, y]) => y)
          .sum()
          .value() || 0
      })
  );
  return Object.assign({}, ...cumulative)
}

const dataUriRegex = /data:([^;]+);base64,(.{100})/;

/**
 * Validate image
 */
export function validateImage(img) {
  const m = dataUriRegex.exec(img);
  if (m != null && detectMimeType(m[2])) {
    const image = new Image();
    return new Promise((resolve, reject) => {
      image.onload = () => resolve(img);
      image.onerror = () => reject();
      image.src = img;
    })
  }
  else {
    return Promise.reject(new Error(m == null ? "Wrong image encoding" : "Wrong image MIME TYPE"))
  }
}

export function getNumberBetween(num, rangeStart = 0, rangeEnd = 100, defaultValue = 0) {
  if (rangeStart > rangeEnd) {
    throw new Error("rangeStart should be < rangeEnd");
  }
  if (defaultValue === undefined) {
    defaultValue = rangeStart;
  }
  let val = parseInt(num);
  if (!isNaN(val)) {
    if (val < rangeStart) {
      return rangeStart;
    }
    else if (val > rangeEnd) {
      return rangeEnd;
    }
    else {
      return val;
    }
  }
  else {
    return defaultValue;
  }
}

export const imageSignatures = {
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg"
};

export function detectMimeType(b64) {
  for (let s in imageSignatures) {
    if (b64.indexOf(s) === 0) {
      return imageSignatures[s];
    }
  }
}

/* Cookie handling, source: https://www.w3schools.com/js/js_cookies.asp  */
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
