/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The MateriaSimulazioneVO model module.
* @module model/MateriaSimulazioneVO
* @version 2.0.0
*/
export default class MateriaSimulazioneVO {
    /**
    * Constructs a new <code>MateriaSimulazioneVO</code>.
    * @alias module:model/MateriaSimulazioneVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>MateriaSimulazioneVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MateriaSimulazioneVO} obj Optional instance to populate.
    * @return {module:model/MateriaSimulazioneVO} The populated <code>MateriaSimulazioneVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MateriaSimulazioneVO();
                        
            
            if (data.hasOwnProperty('id_materia')) {
                obj['id_materia'] = ApiClient.convertToType(data['id_materia'], 'String');
            }
            if (data.hasOwnProperty('materia')) {
                obj['materia'] = ApiClient.convertToType(data['materia'], 'String');
            }
            if (data.hasOwnProperty('numero')) {
                obj['numero'] = ApiClient.convertToType(data['numero'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} id_materia
    */
    'id_materia' = undefined;
    /**
    * @member {String} materia
    */
    'materia' = undefined;
    /**
    * @member {Number} numero
    */
    'numero' = undefined;




}
