/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The RisorseAggiuntiveConcorsoVO model module.
* @module model/RisorseAggiuntiveConcorsoVO
* @version 2.0.0
*/
export default class RisorseAggiuntiveConcorsoVO {
    /**
    * Constructs a new <code>RisorseAggiuntiveConcorsoVO</code>.
    * @alias module:model/RisorseAggiuntiveConcorsoVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>RisorseAggiuntiveConcorsoVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RisorseAggiuntiveConcorsoVO} obj Optional instance to populate.
    * @return {module:model/RisorseAggiuntiveConcorsoVO} The populated <code>RisorseAggiuntiveConcorsoVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RisorseAggiuntiveConcorsoVO();
                        
            
            if (data.hasOwnProperty('url_facebook')) {
                obj['url_facebook'] = ApiClient.convertToType(data['url_facebook'], 'String');
            }
            if (data.hasOwnProperty('url_corso_online')) {
                obj['url_corso_online'] = ApiClient.convertToType(data['url_corso_online'], 'String');
            }
            if (data.hasOwnProperty('url_corso_dal_vivo')) {
                obj['url_corso_dal_vivo'] = ApiClient.convertToType(data['url_corso_dal_vivo'], 'String');
            }
            if (data.hasOwnProperty('url_manuale')) {
                obj['url_manuale'] = ApiClient.convertToType(data['url_manuale'], 'String');
            }
            if (data.hasOwnProperty('url_bus_hotel')) {
                obj['url_bus_hotel'] = ApiClient.convertToType(data['url_bus_hotel'], 'String');
            }
            if (data.hasOwnProperty('url_news')) {
                obj['url_news'] = ApiClient.convertToType(data['url_news'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} url_facebook
    */
    'url_facebook' = undefined;
    /**
    * @member {String} url_corso_online
    */
    'url_corso_online' = undefined;
    /**
    * @member {String} url_corso_dal_vivo
    */
    'url_corso_dal_vivo' = undefined;
    /**
    * @member {String} url_manuale
    */
    'url_manuale' = undefined;
    /**
    * @member {String} url_bus_hotel
    */
    'url_bus_hotel' = undefined;
    /**
    * @member {String} url_news
    */
    'url_news' = undefined;




}
