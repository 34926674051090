import _ from "lodash";

export const AUTH_GETTERS = Object.freeze({
    AUTHENTICATIONS: "authentications",
    IS_AUTHENTICATED: "isAuthenticated",
    PROFILE: "profile",
    TOKEN_API: "token_api",
    IS_EMAIL_VERIFIED: "isEmailVerified",
    EMAIL_COMUNICAZIONI: "emailComunicazioni",
});

export const AUTH_GETTERS_DISPATCH = _.mapValues(AUTH_GETTERS, v => `auth/${v}`);
