/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MateriaSimulazioneVO from './MateriaSimulazioneVO';
import RisorseAggiuntiveConcorsoVO from './RisorseAggiuntiveConcorsoVO';

/**
* The SimulazioneVO model module.
* @module model/SimulazioneVO
* @version 2.0.0
*/
export default class SimulazioneVO {
    /**
    * Constructs a new <code>SimulazioneVO</code>.
    * @alias module:model/SimulazioneVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SimulazioneVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SimulazioneVO} obj Optional instance to populate.
    * @return {module:model/SimulazioneVO} The populated <code>SimulazioneVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SimulazioneVO();
                        
            
            if (data.hasOwnProperty('id_simulazione')) {
                obj['id_simulazione'] = ApiClient.convertToType(data['id_simulazione'], 'Number');
            }
            if (data.hasOwnProperty('attiva')) {
                obj['attiva'] = ApiClient.convertToType(data['attiva'], 'Boolean');
            }
            if (data.hasOwnProperty('data_inserimento')) {
                obj['data_inserimento'] = ApiClient.convertToType(data['data_inserimento'], 'Date');
            }
            if (data.hasOwnProperty('data_scadenza')) {
                obj['data_scadenza'] = ApiClient.convertToType(data['data_scadenza'], 'Date');
            }
            if (data.hasOwnProperty('id_concorso')) {
                obj['id_concorso'] = ApiClient.convertToType(data['id_concorso'], 'String');
            }
            if (data.hasOwnProperty('descrizione')) {
                obj['descrizione'] = ApiClient.convertToType(data['descrizione'], 'String');
            }
            if (data.hasOwnProperty('ente')) {
                obj['ente'] = ApiClient.convertToType(data['ente'], 'String');
            }
            if (data.hasOwnProperty('concorso')) {
                obj['concorso'] = ApiClient.convertToType(data['concorso'], 'String');
            }
            if (data.hasOwnProperty('anno')) {
                obj['anno'] = ApiClient.convertToType(data['anno'], 'String');
            }
            if (data.hasOwnProperty('durata')) {
                obj['durata'] = ApiClient.convertToType(data['durata'], 'String');
            }
            if (data.hasOwnProperty('numero_domande')) {
                obj['numero_domande'] = ApiClient.convertToType(data['numero_domande'], 'Number');
            }
            if (data.hasOwnProperty('numero_download')) {
                obj['numero_download'] = ApiClient.convertToType(data['numero_download'], 'Number');
            }
            if (data.hasOwnProperty('numero_esecuzioni')) {
                obj['numero_esecuzioni'] = ApiClient.convertToType(data['numero_esecuzioni'], 'Number');
            }
            if (data.hasOwnProperty('sbarramento')) {
                obj['sbarramento'] = ApiClient.convertToType(data['sbarramento'], 'Number');
            }
            if (data.hasOwnProperty('punteggio_risposte_corrette')) {
                obj['punteggio_risposte_corrette'] = ApiClient.convertToType(data['punteggio_risposte_corrette'], 'Number');
            }
            if (data.hasOwnProperty('punteggio_risposte_sbagliate')) {
                obj['punteggio_risposte_sbagliate'] = ApiClient.convertToType(data['punteggio_risposte_sbagliate'], 'Number');
            }
            if (data.hasOwnProperty('punteggio_non_risposte')) {
                obj['punteggio_non_risposte'] = ApiClient.convertToType(data['punteggio_non_risposte'], 'Number');
            }
            if (data.hasOwnProperty('risorse_aggiuntive_concorso')) {
                obj['risorse_aggiuntive_concorso'] = RisorseAggiuntiveConcorsoVO.constructFromObject(data['risorse_aggiuntive_concorso']);
            }
            if (data.hasOwnProperty('url_domande')) {
                obj['url_domande'] = ApiClient.convertToType(data['url_domande'], 'String');
            }
            if (data.hasOwnProperty('url_immagini')) {
                obj['url_immagini'] = ApiClient.convertToType(data['url_immagini'], 'String');
            }
            if (data.hasOwnProperty('materie_simulazione')) {
                obj['materie_simulazione'] = ApiClient.convertToType(data['materie_simulazione'], [MateriaSimulazioneVO]);
            }
        }
        return obj;
    }

    /**
    * @member {Number} id_simulazione
    */
    'id_simulazione' = undefined;
    /**
    * @member {Boolean} attiva
    */
    'attiva' = undefined;
    /**
    * @member {Date} data_inserimento
    */
    'data_inserimento' = undefined;
    /**
    * @member {Date} data_scadenza
    */
    'data_scadenza' = undefined;
    /**
    * @member {String} id_concorso
    */
    'id_concorso' = undefined;
    /**
    * @member {String} descrizione
    */
    'descrizione' = undefined;
    /**
    * @member {String} ente
    */
    'ente' = undefined;
    /**
    * @member {String} concorso
    */
    'concorso' = undefined;
    /**
    * @member {String} anno
    */
    'anno' = undefined;
    /**
    * @member {String} durata
    */
    'durata' = undefined;
    /**
    * @member {Number} numero_domande
    */
    'numero_domande' = undefined;
    /**
    * @member {Number} numero_download
    */
    'numero_download' = undefined;
    /**
    * @member {Number} numero_esecuzioni
    */
    'numero_esecuzioni' = undefined;
    /**
    * @member {Number} sbarramento
    */
    'sbarramento' = undefined;
    /**
    * @member {Number} punteggio_risposte_corrette
    */
    'punteggio_risposte_corrette' = undefined;
    /**
    * @member {Number} punteggio_risposte_sbagliate
    */
    'punteggio_risposte_sbagliate' = undefined;
    /**
    * @member {Number} punteggio_non_risposte
    */
    'punteggio_non_risposte' = undefined;
    /**
    * @member {module:model/RisorseAggiuntiveConcorsoVO} risorse_aggiuntive_concorso
    */
    'risorse_aggiuntive_concorso' = undefined;
    /**
    * @member {String} url_domande
    */
    'url_domande' = undefined;
    /**
    * @member {String} url_immagini
    */
    'url_immagini' = undefined;
    /**
    * @member {Array.<module:model/MateriaSimulazioneVO>} materie_simulazione
    */
    'materie_simulazione' = undefined;




}
