/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ModalitaRisposta.
* @enum {}
* @readonly
*/
export default class ModalitaRisposta {
        /**
         * value: "LETTA"
         * @const
         */
        LETTA = "LETTA";

        /**
         * value: "ASCOLTATA"
         * @const
         */
        ASCOLTATA = "ASCOLTATA";

        /**
         * value: "ESEGUITA"
         * @const
         */
        ESEGUITA = "ESEGUITA";


    /**
    * Returns a <code>ModalitaRisposta</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ModalitaRisposta} The enum <code>ModalitaRisposta</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}
