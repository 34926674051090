export class AppSingleton {
  static setInstance(instance) {
    this.appInstance = instance;
  }

  static get instance() {
    return this.appInstance;
  }
}

export default AppSingleton;