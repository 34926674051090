export function setupCookieBot({firebase}) {
    window.addEventListener('CookiebotOnConsentReady', () => {
        // eslint-disable-next-line no-undef
        if (Cookiebot && Cookiebot.consent.statistics) {
            console.debug("ENABLE FIREBASE");
            firebase.analytics().setAnalyticsCollectionEnabled(true);
            console.debug("ENABLE FIREBASE - DONE");
        }
        else {
            console.debug("DISABLE FIREBASE");
            firebase.analytics().setAnalyticsCollectionEnabled(false);
            console.debug("DISABLE FIREBASE - DONE");
        }
    })
}
