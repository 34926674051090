import MyWorker from 'worker-loader?name=js/wasm-module-2.[hash].js!./worker'
import WrappedWorker from './WrappedWorker';
import {CONCORSO_OR_PERCORSO, CONSTANTS} from "@/lib/ConcorsandoWasm/Constants";

export default class PercorsiWrappedWorker extends WrappedWorker {
  static get instance() {
    if (this.percorsiWorkerInstance) {
      return this.percorsiWorkerInstance
    }
    else {
      // Potremmo usare new WrappedWorker, usiamo PercorsiWrappedWorker per far sì che andando a ispezionare
      // l'istanza siamo subito in grado di capire con quale stiamo lavorando
      this.percorsiWorkerInstance = new PercorsiWrappedWorker(CONSTANTS.PERCORSI_STORE, CONCORSO_OR_PERCORSO.PERCORSO);
      return (this.percorsiWorkerInstance)
    }
  }

  /* OVERRIDES */

  initWorker() {
    this.worker = new MyWorker();
  }

  /**
   * Calcola l'id usato nelle statistiche a partire dall'id ritornato dal wasm
   *
   * Per i percorsi questo richiede di precedere l'id della domanda con l'id_argomento
   * @param {string | number} origId
   * @returns {string}
   */
  getStatsId(origId) {
    if (this.concorso) {
      return this.concorso + "-" + String(origId);
    }
  }


  /**
   * Calcola l'id usato nel wasm a partire da quello delle statistiche
   *
   * Per i percorsi questo richiede di splittare l'id domanda al carattere -
   * @param {string} statsId
   * @returns {string}
   */
  getWasmId(statsId) {
    const id = statsId.split("-")[1];
    if (id) {
      return id;
    }
    else {
      return statsId;
    }
  }

}
