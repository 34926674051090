import * as ConcorsandoApi from "../../lib/ConcorsandoApi";
import {AUTH_ACTIONS_DISPATCH} from "../auth/actions-enum";
import {AUTH_MUTATIONS_DISPATCH} from "@/store/auth/mutations-enum";
import {AUTH_GETTERS_DISPATCH} from "@/store/auth/getters-enum";
import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
import CustomUtenteApi from "../../lib/CustomConcorsandoClient/api/CustomUtenteApi";
import BrevoClient from "../../lib/CustomConcorsandoClient/api/BrevoClient";

export function updateUser ({ dispatch }, payload) {
  return new Promise((resolve, reject) => {
      const apiInstance = new ConcorsandoApi.UtenteApi();
      apiInstance.aggiornaUtente(payload, null, (error, data, response) => {
          if (error) {
              dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                  .catch(() => reject(error))
          } else {
              if (response.status === 204) {
                  resolve(response)
              } else {
                  reject(response)
              }
          }
      });
  })
}

export function sendVerifyEmail({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtenteApi();
        const email = payload.email; // String | email dell'utente a cui si vuole mandare la verifica.
        const idUtenteConcorsando = payload.id_utente_concorsando; // Number | Id utente concorsando

        apiInstance.inviaVerificaEmail(email, idUtenteConcorsando, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function deleteUser({ dispatch }) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtenteApi();
        apiInstance.cancellaUtente(null, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        });
    })
}

/**
 *
 * @param dispatch
 * @param commit
 * @param rootGetters
 * @param {boolean} [payload] se true, confronta l'userId in locale con quello ritornato dalla richiesta, se diverso ripulisce i dati
 * @returns {Promise<unknown>}
 */
export function getUser({ dispatch, commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtenteApi();
        apiInstance.getUtente(null, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 200) {
                    let userCleared = false;
                    if(payload) {
                        const profile = rootGetters[AUTH_GETTERS_DISPATCH.PROFILE]
                        if (profile !== undefined && profile !== null && profile.id_utente_concorsando !== undefined && profile.id_utente_concorsando !== response.body.id_utente_concorsando) {
                            dispatch(AUTH_ACTIONS_DISPATCH.CLEAR_USER_DATA, undefined, {root: true})
                            userCleared = true;
                        }
                    }
                    commit(AUTH_MUTATIONS_DISPATCH.SET_PROFILE, response.body, { root: true })
                    resolve({...response.body, userCleared});
                } else if (response.status === 204) {
                    reject(response)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function updateLevel ({ dispatch, commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
        if (payload !== undefined) {
            let profile = rootGetters[AUTH_GETTERS_DISPATCH.PROFILE]
            profile.livello = payload
            commit(AUTH_MUTATIONS_DISPATCH.SET_PROFILE, profile, { root: true })
            resolve()
        } else {
            reject()
        }
    })
}

export function getLevel({ dispatch }) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtenteApi();
        apiInstance.getLivello(function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 200) {
                    dispatch(PROFILE_ACTIONS.UPDATE_LEVEL, response.body)
                        .then(() => resolve(response.body))
                        .catch(e => reject(e));
                } else {
                    reject(response)
                }
            }
        })
    })
}

export function updatePassword({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.UtenteApi();
        apiInstance.cambiaPassword(payload.oldPassword, payload.newPassword, (error, data, response) => {
            if (error) {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        reject(401)
                    } else {
                        reject(error)
                    }
                } else {
                    reject(error)
                }
            } else {
                if (response.status === 204) {
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        })
    })
}

export function removeDevice({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new CustomUtenteApi();
        const id = payload.id;

        apiInstance.removeDevice(id, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        });
    })
}
