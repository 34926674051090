import _ from "lodash";

export function paths (state) {
    return state.paths
}

export function userPaths (state) {
    return state.userPaths
}

export function selectedPaths (state) {
    return state.selectedPaths.map(sP => {
        const pathIx = (state.pathsByIdArgomento || {})[sP.id_argomento];
        const meta = pathIx !== undefined ? state.paths[pathIx] : undefined;
        const sotto_percorsi = _.get(meta, "sotto_percorsi", {});
        return _.cloneDeep({
            ...sP,
            sotto_percorsi,
        })
    })
}

export function getSelectedPath (state) {
    return _.cloneDeep(_.find(state.selectedPaths, function (c, index) {
        c.indice = index
        return c.selected
    }))
}
