/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ClassificaVO from '../model/ClassificaVO';
import DettaglioRispostaGettonataVO from '../model/DettaglioRispostaGettonataVO';
import EsitoSimulazioneVO from '../model/EsitoSimulazioneVO';
import RispostaVO from '../model/RispostaVO';

const contentType = 'application/json'

/**
* Reportistica service.
* @module api/ReportisticaApi
* @version 2.0.0
*/
export default class ReportisticaApi {

    /**
    * Constructs a new ReportisticaApi. 
    * @alias module:api/ReportisticaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the esitiSimulazioni operation.
     * @callback module:api/ReportisticaApi~esitiSimulazioniCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Salva uno o più esiti di una lista di simulazioni.
     * Questo servizio prende in ingresso un blocco di esiti per una o più simulazioni date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database in modo da avere, per ogni simulazione-utente, non più di 5 record salvati. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
     * @param {module:api/ReportisticaApi~esitiSimulazioniCallback} callback The callback function, accepting three arguments: error, data, response
     */
    esitiSimulazioni(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_reportistica/esiti_simulazione', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the geDettaglioRisposteGettonate operation.
     * @callback module:api/ReportisticaApi~geDettaglioRisposteGettonateCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DettaglioRispostaGettonataVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Ritorna il dettaglio delle risposte date
     * Per dettaglio, intendiamo quante volte è stata data quella risposta, tra quelle possibili. Condizione necessaria è che sia passato almeno uno dei 3 campi in query
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~geDettaglioRisposteGettonateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DettaglioRispostaGettonataVO>}
     */
    geDettaglioRisposteGettonate(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_domanda': opts['idDomanda'],
        'id_concorso': opts['idConcorso'],
        'id_argomento': opts['idArgomento']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [DettaglioRispostaGettonataVO];

      return this.apiClient.callApi(
        '/ms_reportistica/dettaglio_risposte_gettonate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getClassifica operation.
     * @callback module:api/ReportisticaApi~getClassificaCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClassificaVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Ritorna le informazioni legate alla classifica
     * Questo servizio ritorna nell&#x27;oggetto ClassificaVO la posizione in classifica, il punteggio medio ed il numero di partecipanti
     * @param {module:api/ReportisticaApi~getClassificaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ClassificaVO}
     */
    getClassifica(idSimulazione, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_simulazione': idSimulazione
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ClassificaVO;

      return this.apiClient.callApi(
        '/ms_reportistica/classifica', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEsitiSimulazione operation.
     * @callback module:api/ReportisticaApi~getEsitiSimulazioneCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EsitoSimulazioneVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Restituisce gli ultimi 5 esiti di una simulazione.
     * Restituisce gli ultimi esiti della simulazione corrispondente all&#x27;id passato che è stata fatta dall&#x27;utente.
     * @param {module:api/ReportisticaApi~getEsitiSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EsitoSimulazioneVO>}
     */
    getEsitiSimulazione(idSimulazione, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_simulazione': idSimulazione
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [EsitoSimulazioneVO];

      return this.apiClient.callApi(
        '/ms_reportistica/esiti_simulazione', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRisposteConcorso operation.
     * @callback module:api/ReportisticaApi~getRisposteConcorsoCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/RispostaVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Restituisce le risposte date per uno o tutti i concorsi.
     * Se presente l&#x27;id_concorso, restituisce tutte le risposte date per quel concorso, altrimenti tutte le risposte date dall&#x27;utente. Stesso discorso per la modalita e la data.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~getRisposteConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/RispostaVO>}
     */
    getRisposteConcorso(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'modalita': opts['modalita'],
        'id_concorso': opts['idConcorso'],
        'data': opts['data']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [RispostaVO];

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_concorso', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRispostePercorso operation.
     * @callback module:api/ReportisticaApi~getRispostePercorsoCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/RispostaVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Restituisce le risposte date per uno o tutti i percorsi.
     * Se presente l&#x27;id_argomento, restituisce tutte le risposte date per il percorso associato a quell&#x27;id argomento, altrimenti tutte le risposte date dall&#x27;utente. Stesso discorso per la modalita e la data.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~getRispostePercorsoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/RispostaVO>}
     */
    getRispostePercorso(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'modalita': opts['modalita'],
        'id_argomento': opts['idArgomento'],
        'data': opts['data']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [RispostaVO];

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_percorso', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRisposteSimulazione operation.
     * @callback module:api/ReportisticaApi~getRisposteSimulazioneCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/RispostaVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Restituisce le risposte date per uno o tutte le simulazioni
     * Se presente l&#x27;id_simulazione, restituisce tutte le risposte date per la simulazione associata a quell&#x27;id, altrimenti tutte le risposte date dall&#x27;utente.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~getRisposteSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/RispostaVO>}
     */
    getRisposteSimulazione(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_simulazione': opts['idSimulazione']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [RispostaVO];

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_simulazione', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the risposteConcorso operation.
     * @callback module:api/ReportisticaApi~risposteConcorsoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Salva le risposte date per un concorso.
     * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~risposteConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    risposteConcorso(body, opts, callback) {
      opts = opts || {};
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
        'incrementa_contatore': opts['incrementaContatore']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_concorso', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the rispostePercorso operation.
     * @callback module:api/ReportisticaApi~rispostePercorsoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Salva le risposte date per un percorso.
     * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~rispostePercorsoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    rispostePercorso(body, opts, callback) {
      opts = opts || {};
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
        'incrementa_contatore': opts['incrementaContatore']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_percorso', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the risposteSimulazione operation.
     * @callback module:api/ReportisticaApi~risposteSimulazioneCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Salva le risposte date per una simulazione.
     * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
     * @param {Object} opts Optional parameters
     * @param {module:api/ReportisticaApi~risposteSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
     */
    risposteSimulazione(body, opts, callback) {
      opts = opts || {};
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
        'incrementa_contatore': opts['incrementaContatore']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_reportistica/risposte_simulazione', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
