/**
 * Contiene tutte le configurazioni dinamiche da condividere con il resto dell'applicazione. Per le configurazioni
 * statiche usare @/config.js
 */
export default class RuntimeConfig {
  appleRedirectUrl; // Url redirect di apple
  helloJSRedirectUrl; // Url redirect di hello.js (facebook e google)
  paypalClientId; // client-id di paypal
  reCaptchaSiteKey; // Chiave reCaptcha

  static get instance() {
    if (!this.instanceConfig) {
      this.instanceConfig = new RuntimeConfig();
    }
    return this.instanceConfig;
  }
}
