export const COMPETITION_MUTATIONS = Object.freeze({
    SET_COMPETITIONS: "SET_COMPETITIONS",
    UNSET_COMPETITIONS: "UNSET_COMPETITIONS",
    SET_USER_COMPETITIONS: "SET_USER_COMPETITIONS",
    UNSET_USER_COMPETITIONS: "UNSET_USER_COMPETITIONS",
    SET_ENTITIES: "SET_ENTITIES",
    UNSET_ENTITIES: "UNSET_ENTITIES",
    SET_SELECTED_COMPETITIONS: "SET_SELECTED_COMPETITIONS",
    UNSET_SELECTED_COMPETITIONS: "UNSET_SELECTED_COMPETITIONS"
});
