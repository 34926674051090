export const competitionDetailTabsEnums = {
    EXERCISE: "esercitazione",
    PERSONALIZED_TRIAL: "prova-personalizzata",
    CHALLENGE: "sfida"
}

export const routesEnums = {
    HOME: "Home",
    PROFILE: "Profile",
    COMPETITIONS: "Competitions",
    COMPETITION_DETAIL_WRAPPER: "CompetitionDetailWrapper",
    ADD_COMPETITION: "AddCompetition",
    EXERCISE: "Exercise",
    SUMMARY: "Summary",
    AUTH: "Auth",
    VERIFY_EMAIL: "VerifyEmail",
    SEND_EMAIL_VERIFICATION: "SendEmailVerification",
    RECOVERY_PASSWORD: "RecoveryPassword",
    SUBSCRIPTION: "Subscription",
    ADD_PATH: "AddPath",
    PATH: "Path",
    PATH_DETAIL_WRAPPER: "PathDetailWrapper",
    STORE: "Store",
    CALL: "Call",
    PROMO_PREMIUM: "PromoPremium",
    FALLBACK: "Fallback",
    DOWNLOAD_DATA: 'DownloadData'
}

export const themeEnums = {
    DARK: 'dark',
    LIGHT: 'light'
}

export const storageKeysEnum = {
    EMAIL: '34242694-4277-4e63-a6fd-7de2494bcad7'
}

export const Storage = {
    DOWNLOAD_DATA_ID_CONCORSO: 'DOWNLOAD_DATA_ID_CONCORSO',
    DOWNLOAD_DATA_ID_ARGOMENTO: 'DOWNLOAD_DATA_ID_ARGOMENTO'
}
