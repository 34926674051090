import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { routesEnums } from '@/helpers/enums'
import firebase from 'firebase/app';
import AffiliateManager from "../lib/AffiliateManager";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: routesEnums.HOME,
        component: () => import('../views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/profilo',
        name: routesEnums.PROFILE,
        component: () => import('../views/Profile.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/banche-dati',
        name: routesEnums.COMPETITIONS,
        component: () => import('../views/Competitions.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/banca-dati',
        name: routesEnums.COMPETITION_DETAIL_WRAPPER,
        component: () => import('../views/CompetitionDetailWrapper.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/aggiungi-banca-dati',
        name: routesEnums.ADD_COMPETITION,
        component: () => import('../views/AddCompetition.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/esercitazione/:type',
        name: routesEnums.EXERCISE,
        component: () => import('../views/Exercise.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/riepilogo',
        name: routesEnums.SUMMARY,
        component: () => import('../views/Summary.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/premium',
        name: routesEnums.SUBSCRIPTION,
        component: () => import('../views/Subscription.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/promo-premium',
        name: routesEnums.PROMO_PREMIUM,
        component: () => import('../views/PromoPremium.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/aggiungi-materia',
        name: routesEnums.ADD_PATH,
        component: () => import('../views/AddPath.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/materie',
        name: routesEnums.PATH,
        component: () => import('../views/Paths.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/materia',
        name: routesEnums.PATH_DETAIL_WRAPPER,
        component: () => import('../views/PathDetailWrapper.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/store',
        name: routesEnums.STORE,
        component: () => import('../views/Store.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/bandi-in-scadenza',
        name: routesEnums.CALL,
        component: () => import('../views/Calls.vue'),
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/download_data',
    name: routesEnums.DOWNLOAD_DATA,
    component: () => import('../views/GenericComponent.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/fallback',
    name: routesEnums.FALLBACK,
    component: () => import('../views/Fallback.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth',
    component: () => import('../layouts/Auth.vue'),
    children: [
      {
        path: '/',
        name: routesEnums.AUTH,
        component: () => import('../views/Auth.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'verifica-email',
        name: routesEnums.VERIFY_EMAIL,
        component: () => import('../views/VerifyEmail.vue'),
        meta: { requiresTokenSocial: true }
      },
      {
        path: 'check-email',
        name: routesEnums.SEND_EMAIL_VERIFICATION,
        component: () => import('../views/SendEmailVerification.vue'),
        meta: { requiresTokenApi: true }
      },
      {
        path: 'recupera-password',
        name: routesEnums.RECOVERY_PASSWORD,
        component: () => import('../views/RecoveryPassword.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    beforeEnter: (to, from, next) => {
      next({ name: routesEnums.HOME })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // Se richiesto, aggiungere il parametro base:
  // base: '/simulatore/',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters['auth/isAuthenticated']) {
      AffiliateManager.instance.clearAffiliateString();
      if (to.name !== routesEnums.AUTH) {
        if (to.path !== from.path || to.name === routesEnums.HOME) {
          if (from.name !== routesEnums.COMPETITION_DETAIL_WRAPPER && from.name !== routesEnums.EXERCISE && to.name === routesEnums.SUMMARY) {
            return next({ name: routesEnums.HOME })
          }
          return next()
        } else {
          return next(false)
        }
      }
    }
    next({ name: routesEnums.AUTH, query: { type: 'login' } })
  }
  else if (to.meta.requiresTokenSocial) {
    if (store.getters['auth/authentications'].tokenSocial !== null && store.getters['auth/authentications'].tokenSocial !== '') {
      if (to.name === routesEnums.VERIFY_EMAIL && store.getters['auth/isAuthenticated']) {
        next({ name: routesEnums.HOME })
      } else {
        next()
      }
    } else {
      next({ name: routesEnums.AUTH, query: { type: 'login' } })
    }
  }
  else if (to.meta.requiresTokenApi) {
    if (store.getters['auth/token_api'] == null || store.getters['auth/token_api'] === '') {
      next({ name: routesEnums.AUTH, query: { type: 'login' } })
    } else if (store.getters['auth/isAuthenticated']) {
      next({ name: routesEnums.HOME })
    } else if (to.name === routesEnums.SEND_EMAIL_VERIFICATION && (store.getters['auth/emailComunicazioni'] == null || store.getters['auth/emailComunicazioni'] === '')) {
      next({ name: routesEnums.AUTH, query: { type: 'login' } })
    } else {
      next()
    }
  }
  else {
    if (to.name === routesEnums.AUTH && store.getters['auth/isAuthenticated']) {
      next({ name: routesEnums.HOME })
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  firebase.analytics().logEvent('page_view', {type: 'internal'})
})

export default router
