import _ from "lodash";

export const AUTH_MUTATIONS = Object.freeze({
    SET_AUTHENTICATIONS: "SET_AUTHENTICATIONS",
    UNSET_AUTHENTICATIONS: "UNSET_AUTHENTICATIONS",
    SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
    UNSET_IS_AUTHENTICATED: "UNSET_IS_AUTHENTICATED",
    SET_PROFILE: "SET_PROFILE",
    UNSET_PROFILE: "UNSET_PROFILE"
});

export const AUTH_MUTATIONS_DISPATCH = _.mapValues(AUTH_MUTATIONS, v => `auth/${v}`);