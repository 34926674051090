/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The ClassificaVO model module.
* @module model/ClassificaVO
* @version 2.0.0
*/
export default class ClassificaVO {
    /**
    * Constructs a new <code>ClassificaVO</code>.
    * @alias module:model/ClassificaVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ClassificaVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ClassificaVO} obj Optional instance to populate.
    * @return {module:model/ClassificaVO} The populated <code>ClassificaVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClassificaVO();
                        
            
            if (data.hasOwnProperty('numero_partecipanti')) {
                obj['numero_partecipanti'] = ApiClient.convertToType(data['numero_partecipanti'], 'Number');
            }
            if (data.hasOwnProperty('posizione_classifica')) {
                obj['posizione_classifica'] = ApiClient.convertToType(data['posizione_classifica'], 'Number');
            }
            if (data.hasOwnProperty('punteggio_medio')) {
                obj['punteggio_medio'] = ApiClient.convertToType(data['punteggio_medio'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} numero_partecipanti
    */
    'numero_partecipanti' = undefined;
    /**
    * @member {Number} posizione_classifica
    */
    'posizione_classifica' = undefined;
    /**
    * @member {Number} punteggio_medio
    */
    'punteggio_medio' = undefined;




}
