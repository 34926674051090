import {Errors} from "./Messages";
import {retryPromise} from "../utils";
import queue from 'queue';
import {maxImageConcurrentRequests} from "@/config";
import JSZip from "jszip";
import mime from 'mime-types';

export default class ImagesClient {
  defaultHeaders = {}
  basePath = ""
  q

  constructor() {
    this._initQueue();
  }

  /**
   * Singleton getter
   * @returns {ImagesClient}
   */
  static get instance() {
    if (!this.thisInstance) {
      this.thisInstance = new ImagesClient();
    }
    return this.thisInstance;
  }

  _initQueue() {
    this.q = queue({autostart: true, concurrency: maxImageConcurrentRequests});
  }

  /**
   * @param {Uint8Array} f
   * @returns {Promise<void>}
   */
  async setupCache(f) {
    this.cache = await JSZip().loadAsync(f);
  }

  clear() {
    this.clearCache();
    this.resetQueue();
  }

  clearCache() {
    this.cache = undefined;
  }

  resetQueue() {
    this.q.end();
    this._initQueue();
  }

  /**
   * @param url_img
   * @returns {Promise<Blob>}
   */
  async getFromCache(url_img) {
    if (!this.cache) {
      return null;
    }
    try {
      const zip = this.cache;
      if (!zip) return null;
      const file = zip.file(url_img)
      if (!file) return null;
      const uarr = await file.async("uint8array");
      const mimeType = mime.lookup(file.name);
      if (!mimeType) {
        console.error("Impossibile decodificare mime type per l'immagine " + url_img);
        return null;
      }
      return new Blob([uarr.buffer], {type: mimeType});
    } catch (e) {
      console.error("Error getting image from cache: ", e)
      return null;
    }
  }

  /**
   * Ritorna l'immagine esattamente come ritornata da fetch
   * @param {string} url_img
   * @param {boolean} raw - default: false, if true returns the respose as returned by fetch
   * @returns {Promise<Response>}
   */
  async get(url_img, {raw = false} = {}) {
    if (!url_img) {
      throw {error: {code: Errors.MISSING_REQUIRED_PARAM, fields: ["url_img"]}}
    }

    let blob = await this.getFromCache(url_img)
    if (!blob) {
      const res = await this._makeRequest(url_img);
      if (raw) {
        return res;
      }
      blob = await res.blob();
    }

    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.readAsDataURL(blob);
      }
      catch (e) {
        reject(e)
      }
    })
  }

  _makeRequest(url_img) {
    return new Promise((resolve, reject) => {
      this.q.push(() =>
        retryPromise(() => this._fetchImage(url_img))
          .then(resolve)
          .catch(reject)
      )
    });
  }

  async _fetchImage(url_img) {
    let res = await fetch(
      this.basePath + String(url_img).replace(/^(?!\/)/, "/"),
      {
        mode: 'cors',
        headers: {
          ...this.defaultHeaders
        }
      }
    );
    if (!res.ok) {
      throw {code: Errors.REMOTE_REQUEST_FAILED}
    }
    return res
  }

  /**
   * Tries to download an array of urls. By default does 3 retries. Failed urls are returned in the property
   * `failed_urls`.
   *
   * **WARNING**: not tested
   *
   * @param {string[]} url_imgs
   * @param {number} maxRetries
   * @param {boolean} raw - passato a ImagesContext.get
   * @returns {Promise<{images: {url_img: *, data: *}[], failed_urls: *[]}>}
   */
  async getMany(url_imgs, {maxRetries = 3, raw = false} = {}) {
    if (!Array.isArray(url_imgs) || url_imgs.length === 0) {
      throw {error: {code: Errors.MISSING_REQUIRED_PARAM, fields: ["url_img"]}}
    }
    const results = await Promise.all(url_imgs.map(u =>
      retryPromise(() => this.get(u, {raw}), maxRetries)
        .then(x =>
          ({url_img: u, isError: false, data: x})
        )
        .catch(e =>
          ({url_img: u, isError: true, error: e})
        )
    ))
    const failed_urls = results.filter(x => x.isError).map(x => x.url_img);
    const images = results.filter(x => !x.isError).map(x => ({url_img: x.url_img, data:x.data}));
    return {
      images,
      failed_urls,
    }
  }
}
