/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import SimulazioneVO from '../model/SimulazioneVO';

/**
* Simulazione service.
* @module api/SimulazioneApi
* @version 2.0.0
*/
export default class SimulazioneApi {

    /**
    * Constructs a new SimulazioneApi. 
    * @alias module:api/SimulazioneApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the getSimulazione operation.
     * @callback module:api/SimulazioneApi~getSimulazioneCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SimulazioneVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la simulazione.
     * Ritorna la simulazione in base all&#x27;id_simulazione.
     * @param {Object} opts Optional parameters
     * @param {module:api/SimulazioneApi~getSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SimulazioneVO}
     */
    getSimulazione(idSimulazione, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_simulazione': idSimulazione,
        'attiva': opts['attiva']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SimulazioneVO;

      return this.apiClient.callApi(
        '/ms_concorso/simulazione', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSimulazioni operation.
     * @callback module:api/SimulazioneApi~getSimulazioniCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SimulazioneVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la lista delle simulazioni.
     * Ritorna la lista delle simulazioni senza le domande. Se non ci sono parametri in ingresso ritorna tutte le simulazioni.
     * @param {Object} opts Optional parameters
     * @param {module:api/SimulazioneApi~getSimulazioniCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/SimulazioneVO>}
     */
    getSimulazioni(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_concorso': opts['idConcorso'],
        'attiva': opts['attiva'],
        'start': opts['start'],
        'size': opts['size'],
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [SimulazioneVO];

      return this.apiClient.callApi(
        '/ms_concorso/simulazioni', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
