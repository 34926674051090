/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import UtenteVO from '../model/UtenteVO';

const contentType = 'application/json'

/**
* Backoffice service.
* @module api/BackofficeApi
* @version 2.0.0
*/
export default class BackofficeApi {

    /**
    * Constructs a new BackofficeApi. 
    * @alias module:api/BackofficeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the aggiornaUtente operation.
     * @callback module:api/BackofficeApi~aggiornaUtenteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la modifica delle informazioni dell&#x27;utente.
     * Vengono modificati i campi email_comunicazioni e flag privacy passati nella richiesta. Se l&#x27;accesso viene fatto con il token_api del back-office, viene aggiornato anche il livello. In questo caso, la condizione necessaria è che sia valorizzato il campo id_utente_concorsando nella query.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~aggiornaUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    aggiornaUtente(body, opts, callback) {
      opts = opts || {};
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/aggiorna_utente', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cancellaReportistica operation.
     * @callback module:api/BackofficeApi~cancellaReportisticaCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che cancella la reportistica associata all&#x27;utente loggato.
     * Questo metodo svuota i campi legati alle risposte_concorso e risposte percorso  senza cancellare il record dal database mentre cancella gli esiti delle simulazioni e le risposte date. Se viene effettuato l&#x27;accesso con il token_api del back-office, è necessario settare il campo id_utente_concorsando.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~cancellaReportisticaCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaReportistica(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando'],
        'id_concorso': opts['idConcorso'],
        'id_materia': opts['idMateria'],
        'id_argomento': opts['idArgomento'],
        'id_sotto_percorso': opts['idSottoPercorso']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/reportistica_utente', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cancellaUtente operation.
     * @callback module:api/BackofficeApi~cancellaUtenteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che effettua una cancellazione di primo livello di  un utente.
     * Questo metodo effettua la pseudonimizzazione di un utente. I dati che vengono modificati sono privacy, id_social ed email. Inoltre vengono invalidati tutti i token. Un task effettuerà la cancellazione di secondo livello che prevede l&#x27;eliminazione di tutta la reportistica, dei token e viene modificato il record in verifica_email in modo da non rendere più disponibile la verifica. Quando viene passato il token dell utente, sappiamo che è lui a chiederne la cancellazione. Nel caso in cui ci viene passato l&#x27;id_utente_concorsando, sappiamo che l&#x27;operazione è stata richiesta dal backoffice.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~cancellaUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaUtente(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/utente', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getUtente operation.
     * @callback module:api/BackofficeApi~getUtenteCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UtenteVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette di ricevere l&#x27;oggetto UtenteVO.
     * Viene ritornato l&#x27;utente relativo al token passato. Se viene passato il token_api del back-office, è necessario passare l&#x27;id_utente_concorsando in query.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~getUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UtenteVO>}
     */
    getUtente(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [UtenteVO];

      return this.apiClient.callApi(
        '/ms_utente/utente', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msCacheConcorsiFromCacheDelete operation.
     * @callback module:api/BackofficeApi~msCacheConcorsiFromCacheDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la cancellazione dalla cache dei concorsi.
     * Tale metodo permette la cancellazione dalla cache di tutte _le liste concorsi e simulazioni_, nel caso in cui vengano passati gli id, anche le informazioni relative ai _singoli_ concorsi e simulazioni su cache e AWS.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~msCacheConcorsiFromCacheDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msCacheConcorsiFromCacheDelete(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'concorsi': opts['concorsi']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_cache/concorsi_from_cache', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msCacheFlushAllCacheAndAwsDelete operation.
     * @callback module:api/BackofficeApi~msCacheFlushAllCacheAndAwsDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la cancellazione di tutta la cache e AWS.
     * Tale metodo permette il flush di tutta la cache e la cancellazione di tutti i file presenti su AWS.
     * @param {module:api/BackofficeApi~msCacheFlushAllCacheAndAwsDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msCacheFlushAllCacheAndAwsDelete(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_cache/flush_all_cache_and_aws', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msCacheFlushAllCacheDelete operation.
     * @callback module:api/BackofficeApi~msCacheFlushAllCacheDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la cancellazione di tutta la cache.
     * Tale metodo permette il flush di tutta la cache.
     * @param {module:api/BackofficeApi~msCacheFlushAllCacheDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msCacheFlushAllCacheDelete(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_cache/flush_all_cache', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msCachePercorsiFromCacheDelete operation.
     * @callback module:api/BackofficeApi~msCachePercorsiFromCacheDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la cancellazione dalla cache dei percorsi.
     * Tale metodo permette la cancellazione dalla cache di tutte _le liste_ percorsi e, nel caso in cui vengano passati gli id, anche le informazioni relative ai _singoli_ percorsi su cache e AWS.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~msCachePercorsiFromCacheDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msCachePercorsiFromCacheDelete(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'percorsi': opts['percorsi']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_cache/percorsi_from_cache', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msCacheSimulazioniFromCacheDelete operation.
     * @callback module:api/BackofficeApi~msCacheSimulazioniFromCacheDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la cancellazione dalla cache delle simulazioni.
     * Tale metodo permette la cancellazione dalla cache di tutte _le liste_ simulazioni e, nel caso in cui vengano passati gli id, anche le informazioni relative alle _singole_ simulazioni su cache e AWS.
     * @param {Object} opts Optional parameters
     * @param {module:api/BackofficeApi~msCacheSimulazioniFromCacheDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msCacheSimulazioniFromCacheDelete(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'simulazioni': opts['simulazioni']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_cache/simulazioni_from_cache', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the msUtenteDisattivaPremiumPost operation.
     * @callback module:api/BackofficeApi~msUtenteDisattivaPremiumPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Disattiva il premium agli utenti che lo hanno attivato nel range di date passate
     * Tutti gli utenti premium che hanno fatto il passaggio nel range di date passate vengono rimessi a demo, con contatore risposte pari a 510
     * @param {module:api/BackofficeApi~msUtenteDisattivaPremiumPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    msUtenteDisattivaPremiumPost(attivatiDa, attivatiA, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'attivati_da': attivatiDa,
        'attivati_a': attivatiA
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/disattiva_premium', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the putConfigReloadConcorso operation.
     * @callback module:api/BackofficeApi~putConfigReloadConcorsoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Permette di effettuare il reload del file di configurazione relativo al CONCORSO
     * Il metodo forza la rilettura del file di configurazione e ritorna sempre 204.
     * @param {module:api/BackofficeApi~putConfigReloadConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    putConfigReloadConcorso(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_concorso/config_reload', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the putConfigReloadGateway operation.
     * @callback module:api/BackofficeApi~putConfigReloadGatewayCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Permette di effettuare il reload del file di configurazione relativo al GATEWAY
     * Il metodo forza la rilettura del file di configurazione e ritorna sempre 204.
     * @param {module:api/BackofficeApi~putConfigReloadGatewayCallback} callback The callback function, accepting three arguments: error, data, response
     */
    putConfigReloadGateway(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/config_reload', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the putConfigReloadUtente operation.
     * @callback module:api/BackofficeApi~putConfigReloadUtenteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Permette di effettuare il reload del file di configurazione relativo a UTENTE
     * Il metodo forza la rilettura del file di configurazione e ritorna sempre 204.
     * @param {module:api/BackofficeApi~putConfigReloadUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    putConfigReloadUtente(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/config_reload', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
