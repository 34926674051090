import UtenteApi from "@/lib/ConcorsandoApi/api/UtenteApi";

export default class CustomUtenteApi extends UtenteApi {

    getPromoUser(opts, callback) {
        opts = opts || {};
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ['Baerer'];
        let contentTypes = [];
        let accepts = [];
        let returnType = [Object];

        return this.apiClient.callApi(
            '/ms_utente/getpromouser', 'POST',
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, callback
        );
    }

    getDeviceUser(opts, callback) {
        opts = opts || {};
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ['Baerer'];
        let contentTypes = [];
        let accepts = [];
        let returnType = [Object];

        return this.apiClient.callApi(
            '/ms_utente/dispositivi_utente', 'GET',
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, callback
        );
    }
    removeDevice(id, callback) {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
            id_dispostivo: id,
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ['Baerer'];
        let contentTypes = [];
        let accepts = [];
        let returnType = [Object];

        return this.apiClient.callApi(
            '/ms_utente/disabilita_dispostivo', 'POST',
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, callback
        );
    }
}
