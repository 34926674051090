import {routesEnums} from "./helpers/enums";

export const systemTimeDelay = 60
export const maxImageConcurrentRequests = 10
export const appleIdStaticConfig = {
    clientId: 'com.app.concorsando',
    scope: 'email',
    state: 'apple-web',
    usePopup: true,
}

export const autoNextSimulazioneDelay = 500;
export const autoNextEsercitazioneDelay = 750;

export const mobileAllowedRoutes = [
    routesEnums.RECOVERY_PASSWORD,
    //routesEnums.AUTH,
    routesEnums.VERIFY_EMAIL,
    routesEnums.SEND_EMAIL_VERIFICATION,
];

export const UrlGoToFallback = {
    AUTH: '/auth',
    DOWNLOAD_DATA: '/download_data'
}
export const mobileIosAndAndroidAllowedRoutes = [
    routesEnums.FALLBACK,
    ...mobileAllowedRoutes
];

