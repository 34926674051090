<template>
    <div id="app-body" class="body" style="min-height: 100vh;">
        <!-- Modali -->
        <div id="modalRedirect" class="modal" tabindex="-1" role="dialog" aria-labelledby="modalRedirectLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">
                            {{ $t('components.socialLogin.modale.errore.titolo') }}</h5>
                    </div>
                    <div id="modal-redirect-body" class="modal-body">
                        <p id="modal-redirect-message-body"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal"
                                @click="redirectStore()">{{ $t('generale.ok') }}
                        </button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <div id="app" v-if="initialized && !mobileStatus" v-cloak>
            <router-view/>
        </div>

    </div>
</template>

<script>
import AppSingleton from './helpers/AppSingleton';
import 'bootstrap';
import $ from "jquery";
import {routesEnums, themeEnums} from "@/helpers/enums";
import {mobileCheck} from "@/lib/mobileCheck";
import {
  mobileAllowedRoutes,
  mobileIosAndAndroidAllowedRoutes,
  MobileRedirectedRoutes,
  UrlGoToFallback,
  VerifyRoutes
} from "./config";
import firebase from 'firebase/app';
import {Storage} from "./helpers/enums";

export default {
    name: "App",
    data() {
        return {
            initialized: false,
            mobileStatus: undefined,
        }
    },
    mounted() {
        console.log('init')
        this.verifyTLSVersion();
        this.setDefaultTheme();
        AppSingleton.setInstance(this);

        // Call onRouterReady only when the route is loaded
        const unwatch = this.$watch(
            () => this.$route,
            (route) => {
                if (route.name != null) {
                    this.onRouterReady(() => unwatch());
                }
            }
        )
        if (this.$route.name != null) {
            this.onRouterReady(() => unwatch());
        }
    },
    methods: {
        onRouterReady(done = () => {
        }) {
            const isMobile = mobileCheck()
            const isMobileAllowed = isMobile?.type !== 'isMobileAgiHMS' ?  mobileIosAndAndroidAllowedRoutes.includes(this.$route.name) :  mobileAllowedRoutes.includes(this.$route.name);
            const isFallback = this.verifyFallback();
            //gestione rotte autorizzate per fallback mobile
           if(isMobile && isMobile?.type !== 'isMobileAgiHMS' && isFallback){
             this.goToFallback(isMobile, done)
           } else if (isMobile && !isMobileAllowed) {
                this.mobileStatus = isMobile;
                $('#modalRedirect').modal({backdrop: 'static', keyboard: false})
                $('#modal-redirect-message-body').html(isMobile.msg)
            } else {
              this.verifyDeepLink();
              this.init(isMobile, done)
            }
        },
        init(isMobile, done){
          if (!this.initialized) {
            this.initialized = true
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
            recaptchaScript.async = true
            document.head.appendChild(recaptchaScript)
          }
          if (!isMobile) {
            done();
          }
        },
        verifyDeepLink() {
          switch (this.$route.path) {
            case UrlGoToFallback.AUTH:
              break;
            case UrlGoToFallback.DOWNLOAD_DATA:
              if (this.$route?.query?.id_concorso) {
                sessionStorage.setItem(Storage.DOWNLOAD_DATA_ID_CONCORSO, this.$route?.query?.id_concorso)
              }
              if (this.$route?.query?.id_argomento) {
                sessionStorage.setItem(Storage.DOWNLOAD_DATA_ID_ARGOMENTO, this.$route?.query?.id_argomento)
              }
              this.$router.push({name: routesEnums.HOME})
              break;
          }
         },
        goToFallback(isMobile, done) {
          if (this.$route.name !== routesEnums.FALLBACK) {
            this.$router.push({name: routesEnums.FALLBACK, params: {link: location.href}})
          }
          this.init(isMobile, done)
        },
        verifyFallback(){
          let isFallback = false;
          switch (this.$route.path) {
            case UrlGoToFallback.AUTH:
              isFallback = this.$route?.query?.code && this.$route?.query?.type && this.$route?.query?.urifefid;
              break;
            case UrlGoToFallback.DOWNLOAD_DATA:
              isFallback = this.$route?.query?.id_concorso || this.$route?.query?.id_argomento;
              break;
          }
          return isFallback
        },
        setDefaultTheme() {
            if (localStorage.getItem('theme') === themeEnums.DARK) {
                $('html').addClass('theme-dark');
                $('body').addClass('theme-dark');
            } else {
                $('html').addClass('theme-light');
                $('body').addClass('theme-light');
            }
        },
        redirectStore() {
            const redirect_url = this.mobileStatus && this.mobileStatus.redirect_url;
            if (redirect_url) {
                window.location.replace(redirect_url);
            }
        },
        verifyTLSVersion() {
            fetch('https://www.howsmyssl.com/a/check')
                .then(response => response.text())
                .then(body => {
                    let json;
                    try {
                        json = JSON.parse(body)
                    } catch (e) {
                        return console.error(json);
                    }

                    if (json && json.tls_version) {
                        firebase.analytics().logEvent('web_tls_version', {tls_version: json.tls_version.toUpperCase()
                        })
                        window.fbq('trackCustom', 'web_tls_version', {tls_version: json.tls_version.toUpperCase()
                        })
                    }

                })
                .catch(error => {
                    console.error('Error verifying TLS version:', error);
                });
        },
    }
}
</script>

<style lang="scss">
@import 'assets/css/bootstrap.scss';
</style>
<style lang="css">
@import 'assets/css/icons.min.css';
</style>
<style lang="scss">
@import 'assets/css/app.scss';
@import 'assets/css/style.scss';
</style>
