export function _enrichPaths(state) {
    try {
        const paths = Array.isArray(state.paths) ? state.paths : [];
        const pathsByIdArgomento = {};
        paths.forEach((path, ix) => {
            const idArgomento = path.id_argomento;
            if (idArgomento) {
                pathsByIdArgomento[idArgomento] = ix;
            }
        })
        state.pathsByIdArgomento = pathsByIdArgomento;
    } catch (e) {}
    return state;
}
