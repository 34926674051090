/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The DettaglioRispostaGettonataVO model module.
* @module model/DettaglioRispostaGettonataVO
* @version 2.0.0
*/
export default class DettaglioRispostaGettonataVO {
    /**
    * Constructs a new <code>DettaglioRispostaGettonataVO</code>.
    * @alias module:model/DettaglioRispostaGettonataVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>DettaglioRispostaGettonataVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DettaglioRispostaGettonataVO} obj Optional instance to populate.
    * @return {module:model/DettaglioRispostaGettonataVO} The populated <code>DettaglioRispostaGettonataVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DettaglioRispostaGettonataVO();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('id_domanda')) {
                obj['id_domanda'] = ApiClient.convertToType(data['id_domanda'], 'Number');
            }
            if (data.hasOwnProperty('id_concorso')) {
                obj['id_concorso'] = ApiClient.convertToType(data['id_concorso'], 'String');
            }
            if (data.hasOwnProperty('id_argomento')) {
                obj['id_argomento'] = ApiClient.convertToType(data['id_argomento'], 'String');
            }
            if (data.hasOwnProperty('risposta1')) {
                obj['risposta1'] = ApiClient.convertToType(data['risposta1'], 'String');
            }
            if (data.hasOwnProperty('risposta2')) {
                obj['risposta2'] = ApiClient.convertToType(data['risposta2'], 'String');
            }
            if (data.hasOwnProperty('risposta3')) {
                obj['risposta3'] = ApiClient.convertToType(data['risposta3'], 'String');
            }
            if (data.hasOwnProperty('risposta4')) {
                obj['risposta4'] = ApiClient.convertToType(data['risposta4'], 'String');
            }
            if (data.hasOwnProperty('risposta5')) {
                obj['risposta5'] = ApiClient.convertToType(data['risposta5'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {Number} id_domanda
    */
    'id_domanda' = undefined;
    /**
    * @member {String} id_concorso
    */
    'id_concorso' = undefined;
    /**
    * @member {String} id_argomento
    */
    'id_argomento' = undefined;
    /**
    * @member {String} risposta1
    */
    'risposta1' = undefined;
    /**
    * @member {String} risposta2
    */
    'risposta2' = undefined;
    /**
    * @member {String} risposta3
    */
    'risposta3' = undefined;
    /**
    * @member {String} risposta4
    */
    'risposta4' = undefined;
    /**
    * @member {String} risposta5
    */
    'risposta5' = undefined;




}
