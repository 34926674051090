import ApiClient from "../../ConcorsandoApi/ApiClient";

const contentType = 'application/json'

export default class CustomReportisticaApi {
  /**
   * Constructs a new ReportisticaApi.
   * @alias module:api/ReportisticaApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the risposteConcorso operation.
   * @callback module:api/ReportisticaApi~risposteConcorsoCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Salva le risposte date per un concorso.
   * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
   * @param {module:api/ReportisticaApi~risposteConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
   */
  risposteConcorso(body, incrementa_contatore = true, callback) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
      incrementa_contatore
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['Baerer'];
    let contentTypes = [contentType];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/ms_reportistica/risposte_concorso', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  async risposteConcorsoAsync(body, incrementa_contatore) {
    return new Promise((resolve, reject) => {
      this.risposteConcorso(body, incrementa_contatore, (error, data, response) => {
        if (error) {
          reject(error);
        }
        else {
          resolve(response);
        }
      })
    })
  }

  /**
   * Salva le risposte date per una simulazione.
   * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
   * @param {Object} opts Optional parameters
   * @param {module:api/ReportisticaApi~risposteSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
   */
  risposteSimulazione(body, incrementa_contatore = true, callback) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
      incrementa_contatore,
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['Baerer'];
    let contentTypes = [contentType];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/ms_reportistica/risposte_simulazione', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  async risposteSimulazioneAsync(body, incrementa_contatore) {
    return new Promise((resolve, reject) => {
      this.risposteSimulazione(body, incrementa_contatore, (error, data, response) => {
        if (error) {
          reject(error);
        }
        else {
          resolve(response);
        }
      })
    })
  }

  /**
   * Salva le risposte date per una simulazione.
   * Questo servizio prende in ingresso un blocco di risposte date da un cliente e le inserisce in una coda. Questa gestisce il salvataggio delle informazioni sul database. Il livello dell&#x27;utente viene aggiornato *automaticamente*.
   * @param {Object} opts Optional parameters
   * @param {module:api/ReportisticaApi~risposteSimulazioneCallback} callback The callback function, accepting three arguments: error, data, response
   */
  rispostePercorso(body, incrementa_contatore = true, callback) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
      incrementa_contatore,
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['Baerer'];
    let contentTypes = [contentType];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/ms_reportistica/risposte_percorso', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  async rispostePercorsoAsync(body, incrementa_contatore) {
    return new Promise((resolve, reject) => {
      this.rispostePercorso(body, incrementa_contatore, (error, data, response) => {
        if (error) {
          reject(error);
        }
        else {
          resolve(response);
        }
      })
    })
  }
}
