import * as ConcorsandoApi from "../../lib/ConcorsandoApi";
import { COMPETITION_MUTATIONS } from "./mutations-enum";
import { AUTH_ACTIONS_DISPATCH } from "../auth/actions-enum";
import _ from "lodash";
import {UTILITY_ACTIONS_DISPATCH} from "@/store/utility/actions-enum";
import firebase from "firebase";

export function getCompetitions ({ dispatch, commit }, payload) {
  return new Promise((resolve, reject) => {
      const apiInstance = new ConcorsandoApi.ConcorsoApi();
      apiInstance.getConcorsi(payload, function(error, data, response) {
          if (error) {
              dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                  .catch(() => reject(error))
          } else {
              const resBodyArray = Array.isArray(response.body) ? response.body : [];
              const competitions = _.filter(resBodyArray, item => !_.isEmpty(item));
              commit(COMPETITION_MUTATIONS.SET_COMPETITIONS, competitions)
              resolve(competitions)
          }
      });
  })
}

export function getCompetition({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.ConcorsoApi();
        const idConcorso = payload.idConcorso // String | id del concorso per il quale effettuare la ricerca
        const opts = payload.opts
        apiInstance.getConcorso(idConcorso, opts, function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        })
    })
}

export function getUserCompetitions({ dispatch, commit, getters }) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.ConcorsoApi();
        apiInstance.getConcorsiByUtente(function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                const resBodyArray = Array.isArray(response.body) ? response.body : [];
                const userCompetitions = _.filter(resBodyArray, item => !_.isEmpty(item));
                commit(COMPETITION_MUTATIONS.SET_USER_COMPETITIONS, userCompetitions)
                commit(COMPETITION_MUTATIONS.SET_SELECTED_COMPETITIONS, setNewSelectedCompetitions(getters, userCompetitions))
                dispatch(UTILITY_ACTIONS_DISPATCH.CLEAR_USER_COMPETITIONS, {type: "CONCORSO", data: userCompetitions}, {root: true})
                    .finally(() => {
                        resolve(userCompetitions)
                    })
            }
        });
    })
}

function setNewSelectedCompetitions (getters, userCompetitions) {
    return getters.selectedCompetitions.map(sC => {
        const cM = _.find(userCompetitions, (uC) => uC.id_concorso === sC.id_concorso)
        if (cM !== undefined) {
            const nSC = _.cloneDeep(cM)
            nSC.indice = sC.indice
            if (sC.customTrial !== undefined)
                nSC.customTrial = sC.customTrial
            if (sC.filters !== undefined)
                nSC.filters = sC.filters
            if (sC.selected === true) {
                nSC.selected = sC.selected
            } else {
                nSC.selected = false
            }
            return nSC
        }
    }).filter(sC => !_.isEmpty(sC))
}

export function deleteUserCompetition({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.ConcorsoApi();
        apiInstance.cancellaConcorsoById(payload, function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve(response.body)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function getEntities ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.ConcorsoApi();
        apiInstance.getEnti(payload, function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                const resBodyArray = Array.isArray(response.body) ? response.body : [];
                const entities = _.filter(resBodyArray, item => !_.isEmpty(item));
                commit(COMPETITION_MUTATIONS.SET_ENTITIES, entities)
                resolve(entities)
            }
        });
    })
}

export function getAdditionalResources ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        const apiInstance = new ConcorsandoApi.ConcorsoApi();
        apiInstance.getRisorseAggiuntiveConcorso(payload, function(error, data, response) {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function clearCompetitionsEntities({ commit }) {
    commit(COMPETITION_MUTATIONS.UNSET_COMPETITIONS)
    commit(COMPETITION_MUTATIONS.UNSET_ENTITIES)
    commit(COMPETITION_MUTATIONS.UNSET_USER_COMPETITIONS)
    commit(COMPETITION_MUTATIONS.UNSET_SELECTED_COMPETITIONS)
}

export function setSelectedCompetition ({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
        let selectedCompetitions = _.cloneDeep(getters.selectedCompetitions)
        const comp = _.find(getters.userCompetitions, x => x.id_concorso === payload);
        if (comp) {
            if (getters.selectedCompetitions !== undefined && getters.selectedCompetitions.length > 0) {
                _.forEach(selectedCompetitions, function (c) {
                    c.selected = false
                })
            } else {
                selectedCompetitions = []
            }
            let competition = _.find(selectedCompetitions, function (c) {
                return c.id_concorso === payload
            })
            if (competition === undefined) {
                competition = comp
                competition.selected = true
                selectedCompetitions.push(competition)
            } else {
                competition.selected = true
            }
            commit(COMPETITION_MUTATIONS.SET_SELECTED_COMPETITIONS, selectedCompetitions)
            resolve(competition)
        } else {
            reject()
        }
    })
}

export function getSimulations ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.SimulazioneApi();
        let opts = {
            'idConcorso': payload.idConcorso, // String | id del concorso per il quale effettuare la ricerca
            'attiva': true // Boolean | se a true, ritorna solo le simulazioni non scadute ed attive (enabled a true)
        };
        apiInstance.getSimulazioni(opts, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function getSimulation ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.SimulazioneApi();
        let idSimulazione = payload.idSimulazione; // String | id della simulazione per la quale effettuare la ricerca
        let opts = {
            'attiva': payload.attiva // Boolean | se a true, ritorna solo le simulazioni non scadute ed attive (enabled a true)
        };
        apiInstance.getSimulazione(idSimulazione, opts, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function getRanking ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.ReportisticaApi();

        apiInstance.getClassifica(payload, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function getSimulationResults ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.ReportisticaApi();

        apiInstance.getEsitiSimulazione(payload, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function getSimulationAnswers ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.ReportisticaApi();
        let opts = {
            'idSimulazione': payload // String | Id della simulazione
        }
        apiInstance.getRisposteSimulazione(opts, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.body)
            }
        });
    })
}

export function getExplanation ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.ConcorsoApi();
        apiInstance.getSpiegazione(payload, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                resolve(response.text)
            }
        });
    })
}




