/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AutenticazioneVO from './AutenticazioneVO';

/**
* The UtenteVO model module.
* @module model/UtenteVO
* @version 2.0.0
*/
export default class UtenteVO {
    /**
    * Constructs a new <code>UtenteVO</code>.
    * @alias module:model/UtenteVO
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>UtenteVO</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UtenteVO} obj Optional instance to populate.
    * @return {module:model/UtenteVO} The populated <code>UtenteVO</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UtenteVO();
                        
            
            if (data.hasOwnProperty('id_utente_concorsando')) {
                obj['id_utente_concorsando'] = ApiClient.convertToType(data['id_utente_concorsando'], 'Number');
            }
            if (data.hasOwnProperty('email_comunicazioni')) {
                obj['email_comunicazioni'] = ApiClient.convertToType(data['email_comunicazioni'], 'String');
            }
            if (data.hasOwnProperty('token_api')) {
                obj['token_api'] = ApiClient.convertToType(data['token_api'], 'String');
            }
            if (data.hasOwnProperty('email_social')) {
                obj['email_social'] = ApiClient.convertToType(data['email_social'], 'String');
            }
            if (data.hasOwnProperty('flag_privacy')) {
                obj['flag_privacy'] = ApiClient.convertToType(data['flag_privacy'], 'Number');
            }
            if (data.hasOwnProperty('livello')) {
                obj['livello'] = ApiClient.convertToType(data['livello'], 'Number');
            }
            if (data.hasOwnProperty('data_registrazione')) {
                obj['data_registrazione'] = ApiClient.convertToType(data['data_registrazione'], 'Date');
            }
            if (data.hasOwnProperty('data_passaggio_premium')) {
                obj['data_passaggio_premium'] = ApiClient.convertToType(data['data_passaggio_premium'], 'Date');
            }
            if (data.hasOwnProperty('email_verificata')) {
                obj['email_verificata'] = ApiClient.convertToType(data['email_verificata'], 'Boolean');
            }
            if (data.hasOwnProperty('autenticazioneVO')) {
                obj['autenticazioneVO'] = AutenticazioneVO.constructFromObject(data['autenticazioneVO']);
            }
        }
        return obj;
    }

    /**
    * @member {Number} id_utente_concorsando
    */
    'id_utente_concorsando' = undefined;
    /**
    * @member {String} email_comunicazioni
    */
    'email_comunicazioni' = undefined;
    /**
    * @member {String} token_api
    */
    'token_api' = undefined;
    /**
    * @member {String} email_social
    */
    'email_social' = undefined;
    /**
    * @member {Number} flag_privacy
    */
    'flag_privacy' = undefined;
    /**
    * @member {Number} livello
    */
    'livello' = undefined;
    /**
    * @member {Date} data_registrazione
    */
    'data_registrazione' = undefined;
    /**
    * @member {Date} data_passaggio_premium
    */
    'data_passaggio_premium' = undefined;
    /**
    * @member {Boolean} email_verificata
    */
    'email_verificata' = undefined;
    /**
    * @member {module:model/AutenticazioneVO} autenticazioneVO
    */
    'autenticazioneVO' = undefined;




}
