import * as ConcorsandoApi from "../../lib/ConcorsandoApi";
import _ from 'lodash'
import AppSingleton from "../../helpers/AppSingleton";
import {AUTH_MUTATIONS} from "./mutations-enum";
import {AUTH_ACTIONS, AUTH_ACTIONS_DISPATCH} from "./actions-enum";
import {COMPETITION_ACTIONS_DISPATCH} from "../competition/actions-enum";
import {PATH_ACTIONS_DISPATCH} from "../path/actions-enum";
import Worker from "@/lib/ConcorsandoWasm/WrappedWorker";
import {UTILITY_ACTIONS_DISPATCH} from "@/store/utility/actions-enum";
import {AUTH_GETTERS_DISPATCH} from "@/store/auth/getters-enum";
import AffiliateManager, {AFFILIATE_CODE_PARAM_NAME} from "../../lib/AffiliateManager";

const worker = Worker.instance

export function signup ({ dispatch, commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
        const profile = rootGetters[AUTH_GETTERS_DISPATCH.PROFILE]
        const apiInstance = new ConcorsandoApi.UtenteApi()
        const body = new ConcorsandoApi.UtenteVO()
        const authVO = new ConcorsandoApi.AutenticazioneVO()

        body.email_comunicazioni = payload.emailComunicazioni
        body.flag_privacy = payload.flag_privacy
        const affiliate_code = AffiliateManager.instance.getAffiliateString();
        if (affiliate_code && affiliate_code !== "") {
            body[AFFILIATE_CODE_PARAM_NAME] = affiliate_code;
        }

        authVO.dispositivo = "web"
        authVO.sistemaOperativo = window.navigator.appVersion
        authVO.versioneApp = process.env.VUE_APP_VERSION
        authVO.social = payload.social

        if (payload.isSignup !== undefined && payload.isSignup) {
            authVO.username = payload.username
            authVO.password = payload.password
        } else {
            authVO.token_social = payload.tokenSocial
        }

        body.autenticazioneVO = authVO
        apiInstance.registrazione(body, (error, data, response) => {
            if (error) {
                reject(error)
            } else {
                if (response.status === 200) {
                    AffiliateManager.instance.clearAffiliateString();
                    if (profile !== undefined && profile !== null && profile.id_utente_concorsando !== undefined && profile.id_utente_concorsando !== response.body.id_utente_concorsando) {
                        dispatch(AUTH_ACTIONS.CLEAR_AUTH, { clearUserData: true });
                    }
                    _setupAuthentication(commit, response.body, response.body.autenticazioneVO.social, response.body.autenticazioneVO.token_social)
                    resolve(response)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function logout ({ dispatch }, payload) {
    let clearUserData = true
    if (payload !== undefined && payload !== null) {
        if (
            (payload.errorCode !== undefined && payload.errorCode !== null && (payload.errorCode === 401 || payload.errorCode === 403))
            || (payload.clearUserData !== undefined && payload.clearUserData !== null && !payload.clearUserData)
        ) {
            clearUserData = false
        }
    }
    const apiInstance = new ConcorsandoApi.UtenteApi();
    apiInstance.logout(() => {});
    return dispatch(AUTH_ACTIONS.CLEAR_AUTH, { clearUserData: clearUserData });
}

export function login({ dispatch, commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
        const profile = rootGetters[AUTH_GETTERS_DISPATCH.PROFILE]
        const apiInstance = new ConcorsandoApi.UtenteApi();
        const bodyRequest = new ConcorsandoApi.AutenticazioneVO()

        if (payload.isLogin !== undefined && payload.isLogin) {
            bodyRequest.username = payload.username
            bodyRequest.password = payload.password
        } else {
            bodyRequest.token_social = payload.tokenSocial
        }

        bodyRequest.dispositivo = "web"
        bodyRequest.sistemaOperativo = window.navigator.appVersion
        bodyRequest.versioneApp = process.env.VUE_APP_VERSION
        bodyRequest.social = payload.social
        apiInstance.login(bodyRequest, (error, data, response) => {
            if (error) {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        _setupAuthentication(commit, response, payload.social, (payload.isLogin) ? response.social : bodyRequest.token_social)
                        resolve(response.body)
                        return
                    }
                }
                reject(error)
            } else {
                if (response.status === 200) {
                    if (profile !== undefined && profile !== null && profile.id_utente_concorsando !== undefined && profile.id_utente_concorsando !== response.body.id_utente_concorsando) {
                        dispatch(AUTH_ACTIONS.CLEAR_AUTH, { clearUserData: true });
                    }
                    _setupAuthentication(commit, response.body, payload.social, (payload.isLogin) ? response.body.social : bodyRequest.token_social)
                    resolve(response.body)
                } else {
                    reject(response)
                }
            }
        });
    })
}

export function updateAuthenticationStatus({ commit, state }) {
    const profile = state.profile;
    const social = _.get(profile, "autenticazioneVO.social");
    const tokenSocial = _.get(profile, "autenticazioneVO.token_social");
    _setupAuthentication(commit, profile, social, tokenSocial);
}

function _setupAuthentication(commit, profile, social, tokenSocial) {
    if (profile.token_api !== undefined && profile.token_api !== null) {
        commit(AUTH_MUTATIONS.SET_PROFILE, _.cloneDeep(profile))
        commit(AUTH_MUTATIONS.SET_IS_AUTHENTICATED, !!profile.email_verificata)
        const Baerer = ConcorsandoApi.ApiClient.instance.authentications['Baerer'];
        Baerer.apiKey = profile.token_api;
        Baerer.apiKeyPrefix = 'Bearer';
    }
    commit(AUTH_MUTATIONS.SET_AUTHENTICATIONS, {
        token_api: (profile.token_api !== undefined) ? profile.token_api : null,
        social: social,
        tokenSocial: tokenSocial
    })
}

export function clearAuth({ dispatch, commit }, payload) {
    commit(AUTH_MUTATIONS.UNSET_IS_AUTHENTICATED)
    const Baerer = ConcorsandoApi.ApiClient.instance.authentications['Baerer'];
    Baerer.apiKey = ''
    Baerer.apiKeyPrefix = '';
    commit(AUTH_MUTATIONS.UNSET_AUTHENTICATIONS)
    if (payload.clearUserData) {
        dispatch(AUTH_ACTIONS.CLEAR_USER_DATA);
    }
    localStorage.removeItem('hello')
}

export function clearUserData({dispatch, commit}) {
    commit(AUTH_MUTATIONS.UNSET_PROFILE)
    dispatch(UTILITY_ACTIONS_DISPATCH.CLEAR_UPTIME, null, {root: true})
    dispatch(COMPETITION_ACTIONS_DISPATCH.CLEAR_COMPETITIONS_ENTITIES, null, { root: true })
    dispatch(PATH_ACTIONS_DISPATCH.CLEAR_PATHS, null, { root: true })
    worker.clearAll()
}

export function verifyErrorType ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        if (payload.response !== undefined) {
            if (payload.response.status === 401 || payload.response.status === 403) {
                dispatch(AUTH_ACTIONS.LOGOUT, { errorCode: payload.response.status })
                    .finally(AppSingleton.instance.$router.push({ name: 'Auth', query: { type: 'login' }}))
            } else {
                reject()
            }
        } else {
            reject()
        }
    })
}

export function getRestorePassword ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.UtenteApi();
        apiInstance.inviaRichiestaRecuperoPassword(payload, (error, data, response) => {
            if (error) {
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        reject(404)
                    } else {
                        reject()
                    }
                } else {
                    reject()
                }
            } else {
                if (response.status === 204) {
                    resolve()
                } else {
                    reject()
                }
            }
        });
    })
}

export function sendRestorePassword ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        let apiInstance = new ConcorsandoApi.UtenteApi();
        let token = payload.token; // String | il token generato per poter effettuare il cambio della password
        let password = payload.password; // String | la nuova password da utilizzare

        apiInstance.cambiaPasswordDaRichiesta(token, password, (error, data, response) => {
            if (error) {
                dispatch(AUTH_ACTIONS_DISPATCH.VERIFY_ERROR_TYPE, error, { root: true })
                    .catch(() => reject(error))
            } else {
                if (response.status === 204) {
                    resolve()
                } else {
                    reject()
                }
            }
        });
    })
}
