/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AutenticazioneVO from '../model/AutenticazioneVO';
import TransazioneVO from '../model/TransazioneVO';
import UtenteVO from '../model/UtenteVO';

const contentType = 'application/json'

/**
* Utente service.
* @module api/UtenteApi
* @version 2.0.0
*/
export default class UtenteApi {

    /**
    * Constructs a new UtenteApi. 
    * @alias module:api/UtenteApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the aggiornaUtente operation.
     * @callback module:api/UtenteApi~aggiornaUtenteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette la modifica delle informazioni dell&#x27;utente.
     * Vengono modificati i campi email_comunicazioni e flag privacy passati nella richiesta. Se l&#x27;accesso viene fatto con il token_api del back-office, viene aggiornato anche il livello. In questo caso, la condizione necessaria è che sia valorizzato il campo id_utente_concorsando nella query.
     * @param {Object} opts Optional parameters
     * @param {module:api/UtenteApi~aggiornaUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    aggiornaUtente(body, opts, callback) {
      opts = opts || {};
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/aggiorna_utente', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cambiaPassword operation.
     * @callback module:api/UtenteApi~cambiaPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Permette ad un utente di cambiare la propria password
     * Questo metodo permette ad un utente in sessione di cambiare la propria password, previa verifica della vecchia.
     * @param {module:api/UtenteApi~cambiaPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cambiaPassword(vecchiaPassword, nuovaPassword, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'vecchia_password': vecchiaPassword,
        'nuova_password': nuovaPassword
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/cambia_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cambiaPasswordDaRichiesta operation.
     * @callback module:api/UtenteApi~cambiaPasswordDaRichiestaCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Cambio password per utente non in sessione
     * Una volta inviata la richiesta di recupero, con questo servizio è possibile cambiare la password. Una volta che una richiesta viene esaudita, viene marcata come evasa e non può essere riutilizzata.
     * @param {module:api/UtenteApi~cambiaPasswordDaRichiestaCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cambiaPasswordDaRichiesta(token, password, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'token': token,
        'password': password
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/recupera_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cancellaReportistica operation.
     * @callback module:api/UtenteApi~cancellaReportisticaCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che cancella la reportistica associata all&#x27;utente loggato.
     * Questo metodo svuota i campi legati alle risposte_concorso e risposte percorso  senza cancellare il record dal database mentre cancella gli esiti delle simulazioni e le risposte date. Se viene effettuato l&#x27;accesso con il token_api del back-office, è necessario settare il campo id_utente_concorsando.
     * @param {Object} opts Optional parameters
     * @param {module:api/UtenteApi~cancellaReportisticaCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaReportistica(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando'],
        'id_concorso': opts['idConcorso'],
        'id_materia': opts['idMateria'],
        'id_argomento': opts['idArgomento'],
        'id_sotto_percorso': opts['idSottoPercorso']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/reportistica_utente', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cancellaUtente operation.
     * @callback module:api/UtenteApi~cancellaUtenteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che effettua una cancellazione di primo livello di  un utente.
     * Questo metodo effettua la pseudonimizzazione di un utente. I dati che vengono modificati sono privacy, id_social ed email. Inoltre vengono invalidati tutti i token. Un task effettuerà la cancellazione di secondo livello che prevede l&#x27;eliminazione di tutta la reportistica, dei token e viene modificato il record in verifica_email in modo da non rendere più disponibile la verifica. Quando viene passato il token dell utente, sappiamo che è lui a chiederne la cancellazione. Nel caso in cui ci viene passato l&#x27;id_utente_concorsando, sappiamo che l&#x27;operazione è stata richiesta dal backoffice.
     * @param {Object} opts Optional parameters
     * @param {module:api/UtenteApi~cancellaUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaUtente(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/utente', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getLivello operation.
     * @callback module:api/UtenteApi~getLivelloCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Ritorna il livello di conoscenza dell&#x27;utente
     * Questo metodo restituisce il livello di conoscenza dell&#x27;utente.
     * @param {module:api/UtenteApi~getLivelloCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    getLivello(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/ms_utente/livello', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSocialByEmail operation.
     * @callback module:api/UtenteApi~getSocialByEmailCallback
     * @param {String} error Error message, if any.
     * @param {Array.<'String'>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna il social a partire dall&#x27;email
     * Ritorna il tipo di social o i tipi di social in base all&#x27;email passata. La ricerca viene effettuata in OR sia in email comunicazione che in email social
     * @param {module:api/UtenteApi~getSocialByEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<'String'>}
     */
    getSocialByEmail(email, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'email': email
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];

      return this.apiClient.callApi(
        '/ms_utente/social', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getUtente operation.
     * @callback module:api/UtenteApi~getUtenteCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UtenteVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette di ricevere l&#x27;oggetto UtenteVO.
     * Viene ritornato l&#x27;utente relativo al token passato. Se viene passato il token_api del back-office, è necessario passare l&#x27;id_utente_concorsando in query.
     * @param {Object} opts Optional parameters
     * @param {module:api/UtenteApi~getUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UtenteVO>}
     */
    getUtente(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_utente_concorsando': opts['idUtenteConcorsando']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey', 'Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtenteVO;

      return this.apiClient.callApi(
        '/ms_utente/utente', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the incrementaLivello operation.
     * @callback module:api/UtenteApi~incrementaLivelloCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Incrementa di 1 il livello di conoscenza dell&#x27;utente
     * Ad ogni chiamata, incrementa il livello di conoscenza dell&#x27;utente di 1.
     * @param {Object} opts Optional parameters
     * @param {module:api/UtenteApi~incrementaLivelloCallback} callback The callback function, accepting three arguments: error, data, response
     */
    incrementaLivello(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'incremento': opts['incremento']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/incrementa_livello', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the inviaRichiestaRecuperoPassword operation.
     * @callback module:api/UtenteApi~inviaRichiestaRecuperoPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Richiesta di recupero password non in sessione
     * Questo metodo invia una richiesta di cambio password per un utente non loggato. Una mail contenente un link con in query il parametro &#x27;token&#x27; verrà inviata all&#x27;indirizzo indicato (che corrisponde alla username). Il token verrà utilizzato per la richiesta successiva come identificativo.
     * @param {module:api/UtenteApi~inviaRichiestaRecuperoPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    inviaRichiestaRecuperoPassword(username, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'username': username
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/recupera_password', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the inviaVerificaEmail operation.
     * @callback module:api/UtenteApi~inviaVerificaEmailCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette di inviare l&#x27;email di verfica dell&#x27;utente.
     * Viene mandata l&#x27;email di verifica all&#x27;utente.
     * @param {module:api/UtenteApi~inviaVerificaEmailCallback} callback The callback function, accepting three arguments: error, data, response
     */
    inviaVerificaEmail(email, idUtenteConcorsando, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'email': email,
        'id_utente_concorsando': idUtenteConcorsando
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/invia_verifica_email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the login operation.
     * @callback module:api/UtenteApi~loginCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UtenteVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette l&#x27;autenticazione tramite social o username e password.
     * Questo servizio ritorna un oggetto UtenteVO con un token_api al verificarsi di alcune condizioni. Il tipo social è un parametro obbligatorio: nel caso in cui questo è pari a &#x27;google&#x27;, &#x27;facebook&#x27; o &#x27;apple&#x27; il token_social è obbligatorio; nel caso di &#x27;concorsando&#x27; username e password diventano obbligatori. Una volta verificato l&#x27;utente attraverso i social, viene verificata la presenza dell&#x27;utente sul database con email (anche non verificata come richiesto) e privacy di primo o secondo livello. Solo a questo punto viene staccato il token e ritornato codice 200. Nel caso in cui l&#x27;utente risulta presente sul database ma con email e/o privacy non corrette, viene ritornato lo stesso oggetto UtenteVO con codice di stato 200 ma senza il token_api. Nel caso in cui l&#x27;utente risulta verificato sui social ma non presente sul database, viene ritornato il codice di stato 204 e body null. Tutti gli altri casi sono casi di errore: ognuno di questi ha un suo codice di stato e, nel body, una descrizione dell&#x27;errore stesso.
     * @param {module:api/UtenteApi~loginCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UtenteVO}
     */
    login(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [contentType];
      let accepts = ['application/json'];
      let returnType = UtenteVO;

      return this.apiClient.callApi(
        '/ms_utente/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the logout operation.
     * @callback module:api/UtenteApi~logoutCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che permette il logout dell&#x27;utente.
     * L&#x27;utente relativo al token passato viene disattivato.
     * @param {module:api/UtenteApi~logoutCallback} callback The callback function, accepting three arguments: error, data, response
     */
    logout(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the registrazione operation.
     * @callback module:api/UtenteApi~registrazioneCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UtenteVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Si occupa della registrazione di un nuovo utente.
     * Metodo che permette di registrare un nuovo utente sul database. Viene inizialmente fatto un controllo sui dati obbligatori: questi sono il tipo di social, un email ed il flag sulla privacy. A seconda del tipo di social diventano obbligatori i seguenti campi: username e password nel caso di &#x27;concorsando&#x27;, token social per gli altri casi. Una volta verificato l&#x27;utente sui social, controlliamo che non esista sul database un utente con quel id_social e/o con quella mail verificata: controllate queste condizioni, viene fatto l&#x27;inserimento sul database, staccato il token e viene inviata una mail per la verifica. Se viene trovato un utente con quel id_social ma con email vuota e/o privacy non di primo o secondo livello, procediamo all&#x27;aggiornamento di queste informazioni: pertanto verrà salvata l&#x27;email e la privacy sul database per l&#x27;utente trovato e verrà inviata una comunicazione per verificare l&#x27;email appena inserita. Se l&#x27;utente trovato con quel id_social ha privacy di primo o secondo livello ed email inserita (anche non verificata come richiesto), viene ritornato codice di stato 409 ad indicare che l&#x27;utente è già presente.
     * @param {module:api/UtenteApi~registrazioneCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UtenteVO}
     */
    registrazione(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [contentType];
      let accepts = ['application/json'];
      let returnType = UtenteVO;

      return this.apiClient.callApi(
        '/ms_utente/registrazione', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateLivello operation.
     * @callback module:api/UtenteApi~updateLivelloCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Aggiorna il livello di conoscenza dell&#x27;utente
     * Questo metodo permette, con utenza di backoffice, di aggiornare il livello di conoscenza dell&#x27;utente.
     * @param {module:api/UtenteApi~updateLivelloCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateLivello(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['APIKey'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/aggiorna_livello', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the verificaTransazione operation.
     * @callback module:api/UtenteApi~verificaTransazioneCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che verifica un pagamento di un cliente.
     * Questo metodo verifica che il pagamento del cliente sia corretto e, nel caso, cambia lo stato dell&#x27;utente settandolo a premium
     * @param {module:api/UtenteApi~verificaTransazioneCallback} callback The callback function, accepting three arguments: error, data, response
     */
    verificaTransazione(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [contentType];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_utente/verifica_transazione', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
