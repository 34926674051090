/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class MetodoPagamento.
* @enum {}
* @readonly
*/
export default class MetodoPagamento {
        /**
         * value: "Google"
         * @const
         */
        Google = "Google";

        /**
         * value: "Apple"
         * @const
         */
        Apple = "Apple";

        /**
         * value: "Huawei"
         * @const
         */
        Huawei = "Huawei";


    /**
    * Returns a <code>MetodoPagamento</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/MetodoPagamento} The enum <code>MetodoPagamento</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}
