import _ from 'lodash'

export const AUTH_ACTIONS = Object.freeze({
    LOGIN: "login",
    SIGNUP: "signup",
    LOGOUT: "logout",
    CLEAR_AUTH: "clearAuth",
    CLEAR_USER_DATA: "clearUserData",
    VERIFY_ERROR_TYPE: "verifyErrorType",
    GET_RESTORE_PASSWORD: "getRestorePassword",
    SEND_RESTORE_PASSWORD: "sendRestorePassword",
    UPDATE_AUTHENTICATION_STATUS: "updateAuthenticationStatus",
});

export const AUTH_ACTIONS_DISPATCH = _.mapValues(AUTH_ACTIONS, v => `auth/${v}`);
