/**
 * Concorsando API
 * API per Concorsando
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ConcorsoVO from '../model/ConcorsoVO';
import EnteVO from '../model/EnteVO';
import RisorseAggiuntiveConcorsoVO from '../model/RisorseAggiuntiveConcorsoVO';

/**
* Concorso service.
* @module api/ConcorsoApi
* @version 2.0.0
*/
export default class ConcorsoApi {

    /**
    * Constructs a new ConcorsoApi. 
    * @alias module:api/ConcorsoApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the cancellaConcorsoById operation.
     * @callback module:api/ConcorsoApi~cancellaConcorsoByIdCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che cancella un concorso associato all&#x27;utente loggato.
     * Cancella il concorso associato all&#x27;utente loggato con quel id_concorso.
     * @param {module:api/ConcorsoApi~cancellaConcorsoByIdCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cancellaConcorsoById(idConcorso, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_concorso': idConcorso
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_concorso/concorso', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getConcorsi operation.
     * @callback module:api/ConcorsoApi~getConcorsiCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ConcorsoVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la lista dei concorsi.
     * Ritorna la lista dei concorsi senza le domande. Se non ci sono parametri in ingresso ritorna tutti i concorsi.
     * @param {Object} opts Optional parameters
     * @param {module:api/ConcorsoApi~getConcorsiCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ConcorsoVO>}
     */
    getConcorsi(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_ente': opts['idEnte'],
        'anno': opts['anno'],
        'start': opts['start'],
        'size': opts['size'],
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ConcorsoVO];

      return this.apiClient.callApi(
        '/ms_concorso/concorsi', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getConcorsiByUtente operation.
     * @callback module:api/ConcorsoApi~getConcorsiByUtenteCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ConcorsoVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la lista dei concorsi scaricati per l&#x27;utente loggato.
     * Ritorna la lista dei concorsi scaricati per l&#x27;utente loggato senza le domande.
     * @param {module:api/ConcorsoApi~getConcorsiByUtenteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ConcorsoVO>}
     */
    getConcorsiByUtente(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ConcorsoVO];

      return this.apiClient.callApi(
        '/ms_concorso/concorsi_utente', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getConcorso operation.
     * @callback module:api/ConcorsoApi~getConcorsoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ConcorsoVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna il concorso.
     * Ritorna il concorso in base all&#x27;id_concorso.
     * @param {Object} opts Optional parameters
     * @param {module:api/ConcorsoApi~getConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ConcorsoVO}
     */
    getConcorso(idConcorso, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_concorso': idConcorso,
        'sorgente': opts['sorgente']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ConcorsoVO;

      return this.apiClient.callApi(
        '/ms_concorso/concorso', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEnti operation.
     * @callback module:api/ConcorsoApi~getEntiCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EnteVO>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna tutti gli enti.
     * Ritorna tutti gli enti.
     * @param {Object} opts Optional parameters
     * @param {module:api/ConcorsoApi~getEntiCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EnteVO>}
     */
    getEnti(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'start': opts['start'],
        'size': opts['size'],
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [EnteVO];

      return this.apiClient.callApi(
        '/ms_concorso/enti', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRisorseAggiuntiveConcorso operation.
     * @callback module:api/ConcorsoApi~getRisorseAggiuntiveConcorsoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RisorseAggiuntiveConcorsoVO} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna le informazioni aggiuntive di un concorso.
     * Ritorna tutti i link di riferimento alle news, corsi e forum relativi al concorso. Li recupera dall&#x27;oggetto Simulazione anche se questo è scaduto o non attivo.
     * @param {module:api/ConcorsoApi~getRisorseAggiuntiveConcorsoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RisorseAggiuntiveConcorsoVO}
     */
    getRisorseAggiuntiveConcorso(idConcorso, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_concorso': idConcorso
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RisorseAggiuntiveConcorsoVO;

      return this.apiClient.callApi(
        '/ms_concorso/risorse_aggiuntive', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSpiegazione operation.
     * @callback module:api/ConcorsoApi~getSpiegazioneCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Metodo che ritorna la spiegazione per una determinata domanda di un concorso
     * Ritorna la spiegazione per l&#x27;id della domanda passata chiamando wordpress e restituendo l&#x27;intero html ricevuto da esso.
     * @param {module:api/ConcorsoApi~getSpiegazioneCallback} callback The callback function, accepting three arguments: error, data, response
     */
    getSpiegazione(idDomanda, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'id_domanda': idDomanda
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Baerer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/ms_concorso/spiegazione', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
