export function SET_COMPETITIONS (state, payload) {
    state.competitions = payload
}

export function UNSET_COMPETITIONS (state) {
    state.competitions = []
}

export function SET_USER_COMPETITIONS (state, payload) {
    state.userCompetitions = payload
}

export function UNSET_USER_COMPETITIONS (state) {
    state.userCompetitions = []
}

export function SET_ENTITIES (state, payload) {
    state.entities = payload
}

export function UNSET_ENTITIES (state) {
    state.entities = []
}

export function SET_SELECTED_COMPETITIONS (state, payload) {
    state.selectedCompetitions = payload
}

export function UNSET_SELECTED_COMPETITIONS (state) {
    state.selectedCompetitions = []
}
