export const PROFILE_ACTIONS = Object.freeze({
    UPDATE_USER: "updateUser",
    SEND_VERIFY_EMAIL: "sendVerifyEmail",
    DELETE_USER: "deleteUser",
    GET_USER: "getUser",
    UPDATE_LEVEL: "updateLevel",
    GET_LEVEL: "getLevel",
    UPDATE_PASSWORD: "updatePassword",
    REMOVE_DEVICE: "removeDevice"
});
